import React, { useContext, useMemo, useEffect } from "react";
import {
  MDBRow,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import HowToUseLeft from "../../../Alerts/HowToUseLeft";

import { StateContext } from "../../../../State";
// import HowToUseSteps from "../../../Alerts/HowToUseSteps";

export default function NewLaunch() {
  const {
    accessTokenCookie,
    userDataCookie,
    handleLogin,
    setStaticModal,
    showUpdate,
    setShowUpdate,
    toggleAdvancedSearchUpdate,
  } = useContext(StateContext);

  const closeModalAndOpenBuyCredits = () => {
    setShowUpdate(false);
    setTimeout(() => {
      setStaticModal(true);
    }, 500);
  };

  return (
    <>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={showUpdate}
        setShow={setShowUpdate}
      >
        <MDBModalDialog style={{ marginTop: "105px" }}>
          <MDBModalContent className="new_launch_padding">
            <MDBModalHeader>
              <MDBModalTitle>
                <h1>WHAT IS ADVANCED SEARCH?</h1>
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleAdvancedSearchUpdate}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-left">
              <MDBRow className="pt-0 mb-2">
                <div>
                  <p className="mt-4 mb-4">
                    Get details of the Kenya Gazette Volume, Notice No, and a
                    PDF of the page in which your property was published by the
                    Ministry of Lands.
                    <br />
                    <br />
                  </p>
                </div>
              </MDBRow>
              <MDBRow className="d-flex justify-content-center align-items-center">
                <HowToUseLeft />
              </MDBRow>
              <MDBRow className="mt-4">
                <h1 className="bold_text" style={{ fontSize: "23px" }}>
                  PURCHASING CREDITS
                </h1>
                <p className="mt-4 mb-1">
                  <span style={{ fontWeight: "900" }}>1 Credit</span> enables{" "}
                  <strong>one Advanced Search</strong>. There are multiple
                  payment options powered by Paystack (Regulated by The Central
                  Bank of Kenya).
                  <div style={{ height: "15px" }}></div>
                  Simply click{" "}
                  <strong>Buy Credit(s)</strong>
                </p>
                <div>
                  {accessTokenCookie !== null && userDataCookie !== null ? (
                    <MDBBtn
                      className="buy_credit_btn mt-3"
                      color="success"
                      style={{
                        display: "block",
                        width: "auto",
                        fontSize: "16px",
                        height: "max-content",
                      }}
                      onClick={closeModalAndOpenBuyCredits}
                      // disabled="true"
                    >
                      BUY CREDIT(S)
                    </MDBBtn>
                  ) : (
                    <MDBBtn
                      className="mt-3 view_pdf_button"
                      color="success"
                      style={{
                        fontSize: "16px",
                      }}
                      type="button"
                      onClick={handleLogin}
                    >
                      SIGN IN TO START
                    </MDBBtn>
                  )}
                </div>
              </MDBRow>
              <MDBRow className="mt-4">
                <p>
                  Clicking <strong>UNDERSTOOD</strong> means that you agree to
                  our{" "}
                  <a
                    href="https://aliumlaw.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </MDBRow>
              <MDBRow className="mt-3">
                <h6><strong>ANY ISSUE? CONTACT SUPPORT</strong></h6>
                <div style={{ height: "5px" }}></div>
                <h6>Email: <a href={`mailto:titlechecker@aliumlaw.com`}>titlechecker@aliumlaw.com</a></h6>
                <div style={{ height: "2.5px" }}></div>
                <h6>We are swift and shall get back to you.</h6>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary">
                Close
              </MDBBtn> */}
              <MDBBtn
                className="close_button"
                color="secondary"
                onClick={toggleAdvancedSearchUpdate}
              >
                Understood
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
