import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Update from "../../Toasts/Update";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Intro from "../../Alerts/Intro";
import Hero from "./Sections/Hero";
import SearchInstructions from "./Sections/SearchInstructions";
import LrNoInputField from "./Sections/LrNoInputField";
import SubmitButton from "./Sections/SubmitButton";

import MobileSnack from "../../Toasts/MobileSnack";

import TokenExpiry from "../AdvancedSearch/Alerts/TokenExpiry";

import { StateContext } from "../../../State";

import '../../../analytics';
import ReactGA from "react-ga";

import { useAuth0 } from "@auth0/auth0-react";
function SimpleSearch() {
  // Google Analytics Page View Tracker
  // useEffect(() => {
  //   // Tracks Page Views using react-ga library
  //   // that connects to Google Analytics with a
  //   // Measurement ID
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  const {
    handleSimpleSearchSubmit,
    tempInputValue,
    handleTempInputChange,
    isSimpleSearchSuccessful,
    simpleSearchData,
    isError,
    tokenExpiryAlert,
    accessTokenCookie,
    email,
    dataOperations,
  } = useContext(StateContext);

  // const clickOnUnderstood = localStorage.getItem(
  //   "disableInstructionsNotification"
  // );

  const handleSimpleSearchClick = (event) => {
    // Tracks the Simple Search button clicks
    ReactGA.event({
      category: 'events',
      action: 'click',
      label: 'Simple Search Button'
    });
    // Call the handleFormSubmit function for Simple Search
    handleFormSubmit(handleSimpleSearchSubmit)(event);
  };

  // const { isAuthenticated, loginWithRedirect } = useAuth0();

  // const handleAdvancedSearchClick = () => {
  //   // Redirect the user to the "/extensive/active" pathway
  //   navigate(`/advanced-search/active`);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  const handleFormSubmit = (submitFunction) => (event) => {
    event.preventDefault();
    submitFunction(event);
  };

  useEffect(() => {
    if (email !== null && accessTokenCookie !== null) {
      dataOperations();
      // handleBasicClick("active");
    }
  }, [email, accessTokenCookie]);

  return (
    <MDBContainer fluid className="no_box_shadow" style={{ boxShadow: "0" }}>
      <MobileSnack />
      <form onSubmit={handleFormSubmit}>
        <Update />
        {tokenExpiryAlert ? <TokenExpiry /> : null}

        <MDBRow className="d-flex justify-content-center align-items-center no_box_shadow">
          <MDBCol lg="12">
            <MDBCard className="m-0 rounded-3 text-center no_box_shadow">
              <MDBCardBody className="px-lg-5 no_box_shadow">
                {/* Hero Section Start */}
                <Hero />
                {/* Hero Section End */}

                {/* Intro Section Start */}
                <Intro />
                {/* Intro Section End */}

                {/* Search Instructions Section Start */}
                <SearchInstructions />
                {/* Search Instructions Section End */}

                {/* LR No. Input Field Section Start */}
                <LrNoInputField
                  tempInputValue={tempInputValue}
                  handleTempInputChange={handleTempInputChange}
                />
                {/* LR No. Input Field Section End */}

                {/* Submit Button Start*/}
                <SubmitButton
                  handleSimpleSearchClick={handleSimpleSearchClick}
                  isSimpleSearchSuccessful={isSimpleSearchSuccessful}
                  simpleSearchData={simpleSearchData}
                  isError={isError}
                />
                {/* Submit Button End*/}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </form>
    </MDBContainer>
  );
}

export default SimpleSearch;
