import React from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import MobileContainer from './MobileContainer';
import Container from './Container';

export default function Home() {
  return (
    <>
      {/* Mobile Container */}
      <MDBContainer fluid className="hide-desktop">
        <div>
          <div className="text-left">
            <MobileContainer />
          </div>
        </div>
      </MDBContainer>
      {/* Desktop Container */}
      <MDBContainer fluid className="hide-mobile hide-tabs">
        <div className="d-flex justify-content-center align-items-center">
          <div className="text-center">
            <Container />
          </div>
        </div>
      </MDBContainer>
    </>
  );
}
