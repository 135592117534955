import React, { useContext, useEffect, useRef } from "react";
import { useNavigate, Outlet, useParams, Link } from "react-router-dom";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBBtn,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import BuyCredits from "../Credits/BuyCredits";
import MobileWarning from "./MobileWarning";

import TabAdvancedSearchHero from "./Tabs/Tablet/TabAdvancedSearchHero";
import TopTabSection from "./Tabs/Tablet/TopTabSection";
import BottomTabSection from "./Tabs/Tablet/BottomTabSection";

import MobileAdvancedSearchHero from "./Tabs/Mobile/MobileAdvancedSearchHero";

import NewLaunch from "./Updates/NewLaunch";

import { DateTime } from "luxon";
import { StateContext } from "../../../State";
import { useAccessToken } from "../../../AccessToken";

import '../../../analytics';
import ReactGA from 'react-ga';

export default function AdvancedSearch({
  accessTokenCookie,
  userDataCookie,
  email,
  storedStateValue,
  storedCodeVerifier,
  storedCodeChallenge,
}) {
  const navigate = useNavigate();
  const advancedSearchTopRef = useRef(null);

  const { fetchData } = useAccessToken();

  // Parse the JSON string into an object
  const fetchedUserDataCookie =
    userDataCookie !== null
      ? JSON.parse(userDataCookie)
      : console.log("Error Fetching User Data");

  // console.log("PASED USER DATA: \n", JSON.parse(userDataCookie));

  const {
    basicActive,
    handleBasicClick,
    handleLogin,
    loggedIn,
    activeSearchData,
    expiredSearchData,
    handleAdvancedSearchSubmit,
    handleSimpleSearchSubmit,
    tempInputValue,
    handleTempInputChange,
    toggleShow,
    isAdvancedLoading,
    isExpiredLoading,
    isReceiptsLoading,
    receipts,
    toggleAdvancedSearchUpdate,
    refreshData,
    dataOperations,
    dashboardDataLoaded,
    setDashboardDataLoaded,
  } = useContext(StateContext);
  // console.log("Initial User Credits: ", initialUserCredit)
  // console.log("Current User Credits: ", userCredit)
  // console.log("New User Credits: ", newCredit)
  // console.log("Receipts: ", receipts);
  // console.log("CREDITS NOW: ", receipts[0]?.available_credits);

  const clickOnUnderstood = localStorage.getItem(
    "disableInstructionsNotification"
  );

  useEffect(() => {
    if (email != null) {
      dataOperations();
      setDashboardDataLoaded(true);
      handleBasicClick("active");
    } else {
      handleBasicClick("how-to-use");
    }
  }, [email, clickOnUnderstood]);

  useEffect(() => {
    const advancedSearchTop = advancedSearchTopRef.current;

    if (advancedSearchTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [advancedSearchTopRef]);

  const handleSimpleSearchClick = (event) => {
    // Redirect to Simple Search Page
    navigate("/");
    // window.scrollTo({ top: 0, behavior: "smooth" });
    handleFormSubmit(handleSimpleSearchSubmit)(event);
  };

  const handleAdvancedSearchClick = (event) => {
    // Tracks the Advanced Search button clicks
    ReactGA.event({
      category: "events",
      action: "click",
      label: "Advanced Search Button",
    });
    // Call the handleAdvancedSearchSubmit function for Advanced Search
    handleFormSubmit(handleAdvancedSearchSubmit)(event);
  };

  const handleFormSubmit = (submitFunction) => (event) => {
    event.preventDefault();
    submitFunction(event);
  };

  const getTodaysDate = () => {
    const currentDate = DateTime.now().setZone("Africa/Nairobi");

    const formattedDate = currentDate.toFormat("LLL dd, yyyy");

    return formattedDate;
  };
  const inputStyles = {
    border: "none",
    borderRadius: "23px",
    boxShadow: "0px 0px 1px #1c1c2b",
    color: "#1c1c2b",
    padding: "20px",
    width: "100%",
    fontSize: "20px",
  };

  // Function to convert UTC time to Nairobi time (GMT+3)
  function convertToNairobiTime(utcTime) {
    const nairobiTime = new Date(utcTime);
    nairobiTime.setHours(nairobiTime.getHours() + 3); // Add 3 hours for Nairobi time

    // Format the date and time with "AT" before the time
    const formattedDate = nairobiTime.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const formattedTime = nairobiTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${formattedDate}`;
  }

  return (
    <div id="advanced-search-top" ref={advancedSearchTopRef}>
      <NewLaunch />
      {/* <MobileWarning /> */}
      <div
        // className="hide-mobile"
        style={{
          margin: "0 0 50px",
          overflowY: "auto",
        }}
      >
        {/* {successNotification ? (
          <SuccessSearch tempInputValue={tempInputValue} />
        ) : null} */}
        <BuyCredits userDataCookie={userDataCookie} />
        {/* ========================== *** HERO SECTION START *** ========================== */}
        <TabAdvancedSearchHero
          inputStyles={inputStyles}
          accessTokenCookie={accessTokenCookie}
          userDataCookie={userDataCookie}
          fetchedUserDataCookie={fetchedUserDataCookie}
          handleLogin={handleLogin}
          getTodaysDate={getTodaysDate}
          convertToNairobiTime={convertToNairobiTime}
          handleSimpleSearchClick={handleSimpleSearchClick}
          handleAdvancedSearchClick={handleAdvancedSearchClick}
        />
        <MobileAdvancedSearchHero
          inputStyles={inputStyles}
          accessTokenCookie={accessTokenCookie}
          userDataCookie={userDataCookie}
          fetchedUserDataCookie={fetchedUserDataCookie}
          handleLogin={handleLogin}
          getTodaysDate={getTodaysDate}
          convertToNairobiTime={convertToNairobiTime}
          handleSimpleSearchClick={handleSimpleSearchClick}
          handleAdvancedSearchClick={handleAdvancedSearchClick}
        />
        <div className="hide-tabs hide-mobile">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#1c1c2b",
              padding: "30px 0",
            }}
            className="advanced_page_hero_background_image"
          >
            {/* ============= Credit Details Section Start ============= */}
            <>
              {accessTokenCookie !== null && userDataCookie !== null ? (
                <div
                  style={{
                    margin: "25px 50px 0",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 3px 9px -3px #1c1c2b",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      padding: "25px 15px",
                      flexGrow: "1",
                    }}
                  >
                    <div className="user_details_initials">
                      <h1>
                        {fetchedUserDataCookie?.given_name
                          ? fetchedUserDataCookie?.given_name.charAt(0)
                          : ""}
                      </h1>
                      <h1>
                        {fetchedUserDataCookie?.family_name
                          ? fetchedUserDataCookie?.family_name.charAt(0)
                          : ""}
                      </h1>
                    </div>
                  </div>
                  <div className="vertical_border"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "25px 15px",
                      flexGrow: "1",
                    }}
                  >
                    <div className="user_details_name_and_email">
                      <div className="user_details_bordered_first_name">
                        <h1 style={{ color: "#1c1c2b" }}>Hi&#44;</h1>
                        <h1 style={{ color: "#1c1c2b" }}>
                          {fetchedUserDataCookie?.given_name
                            ? fetchedUserDataCookie?.given_name
                            : "USER"}
                        </h1>
                      </div>
                      <div
                        className="credits_figures mt-2"
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <h3
                          style={{
                            textTransform: "uppercase",
                            color: "#be1e2d",
                            fontSize: "13px",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          Today's Date:
                        </h3>
                        <h3
                          style={{
                            fontWeight: "600",
                            fontSize: "24px",
                            textDecoration: "underline",
                          }}
                        >
                          {getTodaysDate()}
                        </h3>
                      </div>
                      <div className="user_details_email mt-3">
                        <h3
                          style={{
                            textTransform: "uppercase",
                            color: "#be1e2d",
                            fontSize: "13px",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          Email Address:
                        </h3>{" "}
                        <h3
                          style={{
                            color: "#1c1c2b",
                            fontSize: "13px",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          {fetchedUserDataCookie?.email
                            ? fetchedUserDataCookie?.email
                            : "NO EMAIL"}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="vertical_border"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      padding: "25px 15px",
                      flexGrow: "10",
                      width: "min-content",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {/* <div
                        className="credits_figures"
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: "600",
                            fontSize: "24px",
                            textDecoration: "underline",
                          }}
                        >
                          {getTodaysDate()}
                        </h3>
                      </div> */}
                      <div
                        className="credits_figures"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div className="last_purchase_title">
                          {isReceiptsLoading ? (
                            <span
                              style={{
                                padding: "5px 15px 0",
                              }}
                            >
                              <lord-icon
                                src="https://cdn.lordicon.com/jeycryzx.json"
                                trigger="loop"
                                stroke="bold"
                                colors="primary:#b20000,secondary:#1c1c2d"
                                style={{ width: "25px", height: "25px" }}
                              ></lord-icon>
                            </span>
                          ) : (
                            <span
                              className="credits_icon"
                              style={{
                                padding: "5px 15px",
                              }}
                            >
                              {receipts && receipts.length > 0
                                ? receipts[0]?.purchased_credits
                                : 0}
                            </span>
                          )}

                          <div
                            style={{
                              padding: "5px 15px",
                            }}
                          >
                            <h3>YOUR LAST BOUGHT</h3>
                            <h3>
                              CREDITS : {}
                              <span
                                className="mx-1"
                                style={{ textDecoration: "none" }}
                              >
                                {" "}
                                {isReceiptsLoading ? (
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "900",
                                      letterSpacing: "0.1rem",
                                      textDecoration: "none",
                                    }}
                                  >
                                    ...
                                  </span>
                                ) : receipts && receipts.length > 0 ? (
                                  convertToNairobiTime(
                                    receipts[0]?.paid_at || "NO DATA"
                                  )
                                ) : (
                                  "NO DATA"
                                )}
                              </span>
                            </h3>
                          </div>
                        </div>
                        <div>
                          <MDBBtn
                            className="buy_credit_btn"
                            color="success"
                            style={{
                              fontSize: "15px",
                              width: "100%",
                              height: "max-content",
                              marginTop: "15px",
                            }}
                            onClick={refreshData}
                            // disabled={
                            //   isExpiredLoading ||
                            //   isReceiptsLoading ||
                            //   isAdvancedLoading
                            // }
                          >
                            REFRESH DATA
                          </MDBBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vertical_border"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      padding: "25px 15px",
                      flexGrow: "3",
                      width: "min-content",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div className="credits_title">
                        {isReceiptsLoading ? (
                          <span
                            style={{
                              padding: "5px 15px 0",
                            }}
                          >
                            <lord-icon
                              src="https://cdn.lordicon.com/jeycryzx.json"
                              trigger="loop"
                              stroke="bold"
                              colors="primary:#b20000,secondary:#1c1c2d"
                              style={{ width: "25px", height: "25px" }}
                            ></lord-icon>
                          </span>
                        ) : (
                          <span
                            className="credits_icon"
                            style={{ padding: "5px 15px" }}
                          >
                            {receipts && receipts.length > 0
                              ? receipts[0]?.available_credits
                              : 0}
                          </span>
                        )}
                        <div
                          classname="credits_available_label"
                          style={{ padding: "5px 15px" }}
                        >
                          <h3>Credits</h3>
                          <h3>Available</h3>
                        </div>
                      </div>
                      <div>
                        <MDBBtn
                          className="buy_credit_btn m-0"
                          color="success"
                          style={{
                            fontSize: "15px",
                            width: "100%",
                            height: "max-content",
                          }}
                          onClick={toggleShow}
                          disabled={
                            isExpiredLoading ||
                            isReceiptsLoading ||
                            isAdvancedLoading
                          }
                        >
                          BUY CREDIT(S)
                        </MDBBtn>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    margin: "25px 50px 0",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 0 3px #1c1c2b",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "30px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <h2
                        style={{
                          margin: "0",
                          color: "#1c1c2b",
                          fontSize: "15px",
                          fontWeight: "500",
                          alignSelf: "center",
                        }}
                      >
                        THE MOST EFFICIENT WAY TO CHECK WHETHER YOUR TITLE DEED
                        IS EARMARKED FOR PLACEMENT IN SIMPLE STEPS.
                      </h2>
                    </div>
                    {/* <div>
                      <MDBBtn
                        onClick={handleLogin}
                        className="login_button"
                        style={{
                          fontSize: "16px",
                          margin: "0 30px",
                          width: "max-content",
                        }}
                        disabled="true"
                      >
                        SIGN IN
                      </MDBBtn>
                    </div> */}
                  </div>
                </div>
              )}
            </>
            {/* ============= Credit Details Section End ============= */}

            {/* ============= Advanced Search Input Section Start ============= */}
            <div style={{ margin: "40px 50px 0" }}>
              <div className="hero_h1 mb-2">
                <h1>TITLE DEED CONVERSION CHECKER</h1>
                <h1>ADVANCED SEARCH</h1>
                <div style={{ height: "10px" }}></div>
                <span className="nairobi_badge_advanced_search">
                  NAIROBI COUNTY
                </span>
              </div>
              {/* <span className="nairobi_badge mb-4">NAIROBI COUNTY</span> */}

              {/* Advanced Search L.R. NO. Input Field Desktop Start*/}
              <MDBRow className="hide-mobile d-flex justify-content-start align-items-center">
                <MDBCol lg="10" className="hide-mobile text-left mt-4">
                  <input
                    className="mb-4"
                    name="advanced-search-input"
                    title="ENTER YOUR LR. NO."
                    type="text"
                    style={inputStyles}
                    placeholder="ENTER YOUR TITLE DEED LR. NO."
                    label="ENTER YOUR LR. NO."
                    value={tempInputValue}
                    onChange={handleTempInputChange}
                  />
                </MDBCol>
              </MDBRow>
              {/* Advanced Search L.R. NO. Input Field Desktop End*/}

              {/* Submit Button Desktop Start*/}
              <div
                style={{
                  width: "83%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <MDBBtn
                    color="success"
                    className="hide-mobile mb-4 mt-0 mx-2 custom-button_advanced_search"
                    size="lg"
                    style={{ fontSize: "16px" }}
                    type="button"
                    onClick={handleAdvancedSearchClick}
                    disabled={
                      isExpiredLoading || isReceiptsLoading || isAdvancedLoading
                    }
                  >
                    Advanced Search
                  </MDBBtn>
                  <MDBBtn
                    color="success"
                    className="hide-mobile mb-4 mt-0 custom-button_advanced_search"
                    size="lg"
                    style={{ fontSize: "16px" }}
                    type="button"
                    onClick={handleSimpleSearchClick}
                  >
                    Simple Search
                  </MDBBtn>
                </div>
                {accessTokenCookie !== null && userDataCookie !== null ? (
                  <div>
                    <MDBBtn
                      className="hide-mobile mb-4 mt-0 how_to_use_btn"
                      onClick={toggleAdvancedSearchUpdate}
                    >
                      HOW TO USE
                    </MDBBtn>
                  </div>
                ) : (
                  <div>
                    <MDBBtn
                      className="hide-mobile mb-4 mt-0 view_pdf_button"
                      color="success"
                      style={{
                        fontSize: "16px",
                        padding: "15px 40px",
                      }}
                      type="button"
                      onClick={handleLogin}
                    >
                      SIGN IN TO START
                    </MDBBtn>
                  </div>
                )}
              </div>
              {/* Submit Button Desktop End*/}
            </div>
            {/* ============= Advanced Search Input Section Start ============= */}
          </div>
        </div>
        {/* ========================== *** HERO SECTION END *** ========================== */}

        {/* ========================== *** TABS SECTION START *** ========================== */}
        {/* ============= Tab Title Section Start ============= */}
        {/* Tab Section on Tabs */}

        {accessTokenCookie !== null && userDataCookie !== null ? (
          <TopTabSection
            accessTokenCookie={accessTokenCookie}
            userDataCookie={userDataCookie}
            basicActive={basicActive}
            handleBasicClick={handleBasicClick}
            activeSearchData={activeSearchData}
            expiredSearchData={expiredSearchData}
          />
        ) : null}

        {accessTokenCookie !== null && userDataCookie !== null ? (
          <BottomTabSection
            accessTokenCookie={accessTokenCookie}
            userDataCookie={userDataCookie}
            basicActive={basicActive}
            handleBasicClick={handleBasicClick}
            receipts={receipts}
            isReceiptsLoading={isReceiptsLoading}
          />
        ) : null}

        <MDBTabs className="mt-4 mb-4 hide-tabs center_mobile side_spacing">
          {accessTokenCookie !== null && userDataCookie !== null ? (
            <>
              <MDBTabsItem className="centered_mobile">
                <Link to="/advanced-search/active">
                  <MDBTabsLink
                    className="mobile_tabs flex_on_desktop"
                    onClick={() => handleBasicClick("active")}
                    active={basicActive === "active"}
                  >
                    <div className="max_width">Active Searches</div>
                    <div className="ms-1">
                      {isAdvancedLoading ? (
                        <span>
                          <lord-icon
                            src="https://cdn.lordicon.com/jeycryzx.json"
                            trigger="loop"
                            stroke="bold"
                            colors="primary:#b20000,secondary:#1c1c2d"
                            style={{ width: "25px", height: "25px" }}
                          ></lord-icon>
                        </span>
                      ) : (
                        <span className="total_notification">
                          {activeSearchData === undefined ||
                          activeSearchData === null
                            ? 0
                            : activeSearchData?.length}
                        </span>
                      )}
                    </div>
                  </MDBTabsLink>
                </Link>
              </MDBTabsItem>
              <MDBTabsItem className="centered_mobile">
                <Link to="/advanced-search/expired">
                  <MDBTabsLink
                    className="mobile_tabs flex_on_desktop"
                    onClick={() => handleBasicClick("expired")}
                    active={basicActive === "expired"}
                  >
                    <div className="max_width">Expired Searches</div>
                    <div className="ms-1">
                      {isExpiredLoading ? (
                        <span>
                          <lord-icon
                            src="https://cdn.lordicon.com/jeycryzx.json"
                            trigger="loop"
                            stroke="bold"
                            colors="primary:#b20000,secondary:#1c1c2d"
                            style={{ width: "25px", height: "25px" }}
                          ></lord-icon>
                        </span>
                      ) : (
                        <span className="total_notification">
                          {expiredSearchData === undefined ||
                          expiredSearchData === null
                            ? 0
                            : expiredSearchData?.length}
                        </span>
                      )}
                    </div>
                  </MDBTabsLink>
                </Link>
              </MDBTabsItem>
              <MDBTabsItem className="centered_mobile">
                <Link to="/advanced-search/receipts">
                  <MDBTabsLink
                    className="mobile_tabs flex_on_desktop"
                    onClick={() => handleBasicClick("receipts")}
                    active={basicActive === "receipts"}
                  >
                    <div className="max_width">Receipts</div>
                    <div className="ms-1">
                      {isReceiptsLoading ? (
                        <span>
                          <lord-icon
                            src="https://cdn.lordicon.com/jeycryzx.json"
                            trigger="loop"
                            stroke="bold"
                            colors="primary:#b20000,secondary:#1c1c2d"
                            style={{ width: "25px", height: "25px" }}
                          ></lord-icon>
                        </span>
                      ) : (
                        <span className="total_notification">
                          {receipts === undefined || receipts === null
                            ? 0
                            : receipts?.length}
                        </span>
                      )}
                    </div>
                  </MDBTabsLink>
                </Link>
              </MDBTabsItem>
            </>
          ) : (
            <>
              <MDBTabsItem>
                <Link to="/advanced-search/how-to-use">
                  <MDBTabsLink
                    className="max_width"
                    onClick={() => handleBasicClick("how-to-use")}
                    active={basicActive === "how-to-use"}
                  >
                    How To Use
                  </MDBTabsLink>
                </Link>
              </MDBTabsItem>
            </>
          )}
        </MDBTabs>
        {/* ============= Tab Title Section End ============= */}

        {/* ============= Tabs Information Section Start ============= */}
        <MDBTabsContent className="mt-4 tab_data_section_spacing">
          {/* Use Outlet to render the corresponding tab content based on the URL */}
          <Outlet
            accessTokenCookie={accessTokenCookie}
            userDataCookie={userDataCookie}
          />
        </MDBTabsContent>
        {/* ============= Tabs Information Section End ============= */}
        {/* ========================== *** TABS SECTION END *** ========================== */}
      </div>
    </div>
  );
}
