import React, { useEffect, useContext } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import ErrorIcon from "../../../Assets/Icons/error-mobile.gif";
import NoDataIcon from "../../../Assets/Icons/error-light.gif";
import LoadIcon from "../../../Assets/Icons/simple_loader.gif";
import { StateContext } from "../../../../State";

export default function MobileCard() {
  const { simpleSearchData, isLoading, isError } = useContext(StateContext);

  if (isLoading) {
    return (
      <div className="hide-tabs hide-desktop">
        <div>
          <h3
            className="text-center mb-4"
            style={{
              padding: "0 15px",
              marginTop: "15px",
              fontWeight: "900",
              fontSize: "33px",
            }}
          >
            RESULTS
          </h3>
        </div>
        <div
        style={{
          border: "1px solid #1c1c2d",
          borderRadius: "17px",
          boxShadow: "0 0 7px #1c1c2d",
          backgroundColor: "#1c1c2d",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "25px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={LoadIcon}
              alt="Loading Animated Icon"
              style={{
                width: "75px",
                height: "auto",
                textShadow: "0 0 5px #ffffff",
              }}
            />
          </div>
          <div className="vertical_border_loader"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "25px",
              fontSize: "16px",
              flexWrap: "wrap",
            }}
          >
            <h5
              className="hide-tabs hide-desktop"
              style={{
                flexWrap: "wrap",
                color: "#ffffff",
                fontWeight: "900",
                letterSpacing: "0.01em",
                wordSpacing: "0.27em",
                margin: "0",
                alignSelf: "center",
              }}
            >
              LOADING...<br/>
              PLEASE WAIT
            </h5>
          </div>
        </div>
      </div>
      </div>
    );
  }

  if (isError) {
    return (
      <>
        <div
          className="hide-desktop box_shadow"
          style={{
            textAlign: "left",
            padding: "25px 0",
            borderRadius: "17px",
            backgroundColor: "#1c1c2d",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0 25px",
            }}
          >
            <img
              src={ErrorIcon}
              alt="Loading Animated Icon"
              style={{ width: "75px", height: "auto" }}
            />
            <h5 style={{ color: "#ffffff" }}>NO SIMPLE SEARCH DATA FOUND</h5>
            <br />
          </div>
          <p
            style={{
              textTransform: "uppercase",
              padding: "0 25px 10px",
              color: "#ffffff",
            }}
          >
            EITHER<div style={{ height: "5px" }}></div>
            <strong>
              <em>Your property is not listed in the Kenya Gazette.</em>
            </strong>
            <div style={{ height: "5px" }}></div>
            OR<div style={{ height: "5px" }}></div>
            <strong>
              <em>Your input is wrong.</em>
            </strong>
          </p>
          <div
            className="horizontal_border_simple_search"
            style={{ backgroundColor: "#ffffff" }}
          ></div>
          <p
            style={{
              padding: "0 25px",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
          >
            Follow the <strong>STEPS</strong> above or{" "}
            <a
              href="https://4egs0m6ybvf.typeform.com/to/HKwgvx2A?typeform-source=email-button#product_id=xxxxx"
              target="_blank"
            >
              GET ADVICE
            </a>
          </p>
        </div>
      </>
    );
  }

  return (
    <div className="hide-desktop">
      {/* Mobile Heading */}
      <div className="hide-desktop">
        <h3
          className="text-center mb-4"
          style={{
            padding: "0 15px",
            marginTop: "15px",
            fontWeight: "900",
            fontSize: "33px",
          }}
        >
          RESULTS
        </h3>
      </div>
      {/* Check if data is available */}
      {simpleSearchData.length > 0 ? (
        simpleSearchData.map((title_deed) => (
          <div key={title_deed.id}>
            {/* OLD TITLE NUMBER DISPLAY */}
            <div
              className="card box_shadow border_radius"
            >
              <h5
                className="card-header"
                style={{
                  fontSize: "24px",
                  color: "#ffffff",
                  backgroundColor: "#1c1c2d",
                }}
              >
                OLD LR. NUMBER
              </h5>

              <div className="card-body">
                <p
                  className="card-text"
                  style={{ color: "#f20000", fontSize: "19px" }}
                >
                  <strong>L.R NO. {title_deed.old_title_no}</strong>
                </p>
              </div>
            </div>

            {/* NEW TITLE NUMBER DISPLAY */}
            <div
              className="card mt-4 box_shadow border_radius"
            >
              <h5
                className="card-header"
                style={{
                  fontSize: "24px",
                  color: "#ffffff",
                  backgroundColor: "#1c1c2d",
                }}
              >
                NEW TITLE NUMBER
              </h5>

              <div className="card-body">
                <p
                  className="card-text"
                  style={{ color: "#14a44d", fontSize: "19px" }}
                >
                  <strong>{title_deed.new_title_no}</strong>
                </p>
              </div>
            </div>

            {/* AREA DISPLAY */}
            <div
              className="card mt-4 box_shadow border_radius"
            >
              <h5
                className="card-header"
                style={{
                  fontSize: "24px",
                  color: "#ffffff",
                  backgroundColor: "#1c1c2d",
                }}
              >
                LOCATION & SIZE
              </h5>

              <div className="card-body p-0">
                <div style={{ padding: "24px 24px 0" }}>
                  <p
                    className="card-text m-0 p-0"
                    style={{ color: "#000000", fontSize: "21px" }}
                  >
                    <strong>{title_deed.area} AREA</strong>
                  </p>
                </div>
                <div className="horizontal_border_mobile"></div>
                <div style={{ padding: "0 24px 24px" }}>
                  <p
                    className="card-text m-0 p-0"
                    style={{ color: "#000000", fontSize: "21px" }}
                  >
                    <strong>{title_deed.size_ha} HECTARES</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>
          {/* OLD TITLE NUMBER DISPLAY */}
          <div
            className="card"
            style={{
              boxShadow: "0 0 7px #1c1c2b",
              borderRadius: "17px",
              backgroundColor: "#1c1c2d",
            }}
          >
            {/* <h5 className="card-header">OLD LR. NUMBER</h5> */}

            <div
              className="card-body"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={NoDataIcon}
                alt="Loading Animated Icon"
                style={{ width: "75px", height: "auto", }}
              />{" "}
              <p
                className="card-text"
                style={{
                  color: "#ffffff",
                  textTransform: "uppercase",
                  marginLeft: "15px",
                }}
              >
                No data available
                <br />
                Enter the <strong>L.R. No.</strong> above to search
              </p>
            </div>
          </div>

          {/* NEW TITLE NUMBER DISPLAY */}
          {/* <div className="card mt-4">
            <h5 className="card-header">NEW TITLE NUMBER</h5>

            <div
              className="card-body"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={NoDataIcon}
                alt="Loading Animated Icon"
                style={{ width: "50px", height: "50px", alignSelf: "center" }}
              />{" "}
              <p
                className="card-text"
                style={{
                  color: "#1C1C2B",
                  textTransform: "uppercase",
                  marginLeft: "15px",
                }}
              >
                No data available<br />
                Enter the <strong>L.R. No.</strong> above to search
              </p>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}
