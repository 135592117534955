import React from "react";
import AliumHero from "../Images/simple_search.png";
import { MDBCardImage } from "mdb-react-ui-kit";

import HeroFallbackImage from "../Fallbacks/HeroFallback";

export default function Hero() {
  return (
    <>
      {/* ========== MOBILE DISPLAY START ========== */}

      {/* Mobile Heading Start */}
      <h1
        className="hide-desktop text-center mb-0 pb-md-0 mb-md-5 px-md-2"
        style={{
          fontWeight: "900",
          marginTop: "10px",
          textAlign: "left",
          padding: "15px",
          fontSize: "xx-large",
        }}
      >
        TITLE DEED
        <br />
        CONVERSION CHECKER
      </h1>
      {/* Mobile Heading End */}

      {/* Hero Image Mobile Start */}
      {AliumHero ? (
        <MDBCardImage
          src={AliumHero}
          className="hide-desktop text-center w-100 p-1 rounded-top"
          alt="Alium Title Conversion Checker Ad "
          style={{
            margin: "0 auto",
            maxHeight: "525px",
            height: "auto",
            borderRadius: "17px",
          }}
        />
      ) : (
        <span
          className="placeholder col-12"
          style={{ height: "264px", borderRadius: "17px" }}
        ></span>
      )}
      {/* Hero Image Mobile End */}

      {/* ========== MOBILE DISPLAY END ========== */}

      {/* ========== DESKTOP & TABS DISPLAY START ========== */}

      {/* Desktop Heading Start */}
      <h1
        className="hide-mobile hide-tabs mb-0 pb-md-0 mb-md-5 px-md-2"
        style={{
          fontWeight: "900",
          marginTop: "40px",
          fontSize: "50px",
        }}
      >
        TITLE DEED
        <br />
        CONVERSION CHECKER
      </h1>
      {/* Desktop Heading End */}

      {/* Hero Image Desktop Start */}
      {/* <HeroFallbackImage /> */}
      <MDBCardImage
        src={AliumHero}
        loading="lazy"
        className="hide-mobile hide-tabs text-center w-100 rounded-top"
        alt="Alium Title Conversion Checker Ad "
        style={{
          margin: "0 auto",
          maxHeight: "525px",
          height: "auto",
          borderRadius: "17px",
        }} />
      {/* Hero Image Desktop End */}

      {/* ========== DESKTOP & TABS DISPLAY END ========== */}
    </>
  );
}
