import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function MobileSnack() {
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => {
    console.log("MODAL OPENED!");
    setBasicModal(!basicModal);
  };

  return (
    <div
    className="hide-desktop hide-tabs"
      style={{ zIndex: "1500", display: "flex", justifyContent: "flex-end" }}
    >
      <MDBIcon
        onClick={toggleOpen}
        fas
        icon="info-circle"
        style={{ fontSize: "23px", margin: "15px 15px 0 0" }}
      />
      <MDBModal
        staticBackdrop
        show={basicModal}
        toggle={toggleOpen}
        tabIndex="-1"
      >
        <MDBModalDialog style={{ marginTop: "147px" }}>
          <MDBModalContent style={{ width: "96.33vw" }}>
            <MDBModalHeader>
              <MDBModalTitle style={{ fontWeight: "600" }}>
                How To download the Title Checker on your mobile
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "no-wrap",
                      marginBottom: "5px",
                    }}
                  >
                    <MDBIcon fas icon="info-circle" className="mt-1" />
                    <h5 className="ms-2" style={{ fontWeight: "700" }}>Safari (iOS)</h5>
                  </div>
                  <p
                    style={{
                      marginLeft: "30px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    Tap{" "}
                    <span className="material-symbols-outlined mx-2">ios_share</span>{" "}
                    <div>and then </div>
                    <span class="material-symbols-outlined mx-2">add_box</span>
                    <strong>Add to Home Screen</strong>
                  </p>
                </div>
                <div style={{ height: "5px" }}></div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "no-wrap",
                      marginBottom: "5px",
                    }}
                  >
                    <MDBIcon fas icon="info-circle" className="mt-1" />
                    <h5 className="ms-2" style={{ fontWeight: "700" }}>Google & Google Chrome</h5>
                  </div>
                  <p
                    style={{
                      marginLeft: "30px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                      Tap
                      <span class="material-symbols-outlined mx-1">more_vert</span>
                      <div>and then select</div><strong className="ms-1">Install App</strong>.
                  </p>
                </div>
                <div style={{ height: "5px" }}></div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "no-wrap",
                      marginBottom: "5px",
                    }}
                  >
                    <MDBIcon fas icon="info-circle" className="mt-1" />
                    <h5 className="ms-2" style={{ fontWeight: "700" }}>Samsung</h5>
                  </div>
                  <p
                    style={{
                      marginLeft: "30px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                      Tap <span class="material-symbols-outlined mx-1">menu</span>{" "}
                      <div>and then select</div><strong>+ Add page to</strong>.
                  </p>
                </div>
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen}>
                UNDERSTOOD
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}
