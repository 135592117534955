import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

export default function CTA() {
    const [show, setShow] = useState(false);

  return (
    <div style={{ margin: '35px 15px' }}>
      <Alert variant='secondary' show={show} className='alium-alert' >
        <Alert.Heading 
          style={{ 
            paddingTop: '30px',
            color: '#000000',
            letterSpacing: '0.043em'
          }}
        >
        <strong>NEED HELP GETTING YOUR NEW GENERATION TITLE DEED:</strong>
        </Alert.Heading>
        <div nowrap>
          <span>
          Convert your
          </span>
          <p style={{ color: '#b20000' }}>
            <strong>OLD TITLE DEED</strong>
          </p>
        </div>

        <div style={{ marginTop: '-10px', }} nowrap>
          <span className='mt-0'>
          into a 
          </span>
          <p style={{ color: '#14a44d' }}>
            <strong>NEW GENERATION TITLE</strong>
          </p>
        </div>

        <p>
        Click the link below for more information on 
        how to obtain your new generation Title Deed.<br />
        </p>

        <Button 
            className='cta-button'
          >
            <h2 className='custom-link'>
            <a href="https://4egs0m6ybvf.typeform.com/to/xOOhCrDH#product_id=xxxxx" target='_blank'>
              <strong>GET ADVICE</strong>
            </a>
            </h2>
          </Button>
        
        <hr />
        <div className="d-flex justify-content-end">
          <Button 
            onClick={() => setShow(false)} 
            style={{ 
              backgroundColor: '#1C1C2B',
              boxShadow: '0 4px 9px -4px #1C1C2B !important' 
            }}
          >
            No thank you!
          </Button>
        </div>
      </Alert>

      {!show && 
        <Button 
          className='cta-button' 
          onClick={() => setShow(true)} 
          style={{ 
            fontSize: '16px',
            marginLeft: '5px' 
          }}
        >
          Get Advice Today
        </Button>
      }
    </div>
  )
}
