import { SHA256, enc, lib } from "crypto-js";

// Base 64 URL Encoder
function base64URLEncode(str) {
  const encodedStr = str
    .toString(enc.Base64)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return encodedStr;
}

// Code Verifier Generator
function generateCodeVerifier() {
  const verifier = lib.WordArray.random(32); // Generate 32 bytes of random data
  const codeVerifier = base64URLEncode(verifier);

  // console.log("=== CODE CHALLENGE FROM PKCE UTILS === \n", codeVerifier); 
  return codeVerifier;
}

// Code Challenge Generator
function generateCodeChallenge(verifier) {
  const hashedVerifier = SHA256(verifier);
  const codeChallenge = base64URLEncode(hashedVerifier);

  // console.log("=== CODE CHALLENGE FROM PKCE UTILS === \n", codeChallenge);
  return codeChallenge;
}

// Export Code Verifier & Code Challenge Generator Functions
export {
    generateCodeVerifier,
    generateCodeChallenge,
  };
