import React, { useState, useEffect, useMemo, useContext } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import { StateContext } from "../../State";

export default function Update() {
  const { redirectURI } = useContext(StateContext);
  const toastKey = "updateToastShown";

  // Memoize the toastShown value to avoid unnecessary re-computation
  const toastShown = useMemo(() => localStorage.getItem(toastKey), []);

  // Part that handles the update notification toast
  const [showToast, setShowToast] = useState(false); // State to control toast visibility

  useEffect(() => {
    if (!toastShown) {
      // Opens the toast after 3 seconds upon the page load
      const openUpdateToast = setTimeout(() => {
        setShowToast(true); // Display the toast after 3 seconds
      }, 3000);

      setTimeout(() => {
        localStorage.setItem(toastKey, "true");
        setShowToast(false);
      }, 16000);

      return () => {
        clearTimeout(openUpdateToast); // Clear the timeout on component unmount
      };
    }
  }, [toastShown]);

  const handleCloseToast = () => {
    setShowToast(false); // Close the toast when the close button is clicked
    localStorage.setItem(toastKey, "true"); // Mark the toast as shown in this session
  };

  if (toastShown) {
    return null; // Don't render anything if the toast has already been shown
  }

  return (
    <>
      {/* Desktop Update Toast Start */}
      <ToastContainer
        className="position-fixed top-0 end-0 p-3 hide-mobile desktop_toast"
        style={{ zIndex: 999 }}
      >
        <Toast
          show={showToast}
          onClose={handleCloseToast}
          style={{ margin: "10px" }}
        >
          <Toast.Header style={{ backgroundColor: "#dc131b" }}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto" style={{ color: "#ffffff" }}>
              UPDATES
            </strong>
            <small style={{ color: "#ffffff", textTransform: "uppercase" }}>
              From 1st December 2023
            </small>
          </Toast.Header>
          <Toast.Body
            style={{
              backgroundColor: "#fafafc",
            }}
          >
            <h5 style={{ fontWeight: "900", textTransform: "uppercase" }}>
              Title Deed Conversion Checker 2.0
            </h5>{" "}
            <p style={{ fontSize: "1px" }}>
              A new and improved look is here. The Search is now divided into 2
              types:
            </p>
            <h6
              style={{
                fontWeight: "900",
                textDecoration: "uppercase",
                fontSize: "19px",
              }}
            >
              SIMPLE & ADVANCED
            </h6>
            {/* <h6>
              <em>Currently under construction on mobile.</em>
            </h6> */}
            <a href={redirectURI}>
              LEARN MORE
            </a>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {/* Desktop Update Toast End */}

      {/* Mobile Update Toast Start */}
      <ToastContainer className="position-fixed top-0 end-0 p-3 hide-desktop mobile_toast">
        <Toast show={showToast} onClose={handleCloseToast}>
          <Toast.Header style={{ backgroundColor: "#dc131b" }}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto" style={{ color: "#ffffff" }}>
              UPDATES
            </strong>
            <div style={{ padding: "0 15px" }}></div>
            <small style={{ color: "#ffffff", textTransform: "uppercase" }}>
              From 1st December 2023
            </small>
          </Toast.Header>
          <Toast.Body
            style={{
              backgroundColor: "#fafafc",
            }}
          >
            <h5
              style={{
                fontWeight: "900",
                textTransform: "uppercase",
                margin: "0",
              }}
            >
              Title Deed Conversion Checker 2.0
            </h5>{" "}
            A new and improved look is here. The Search is now divided into 2
            types:<div style={{ height: "10px" }}></div>
            <h5
              style={{
                fontWeight: "900",
                textDecoration: "uppercase",
                fontSize: "19px",
              }}
            >
              SIMPLE & ADVANCED
            </h5>
            {/* <h6>
              <em>Currently under construction on mobile.</em>
            </h6> */}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {/* Mobile Update Toast End */}
    </>
  );
}
