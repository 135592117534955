import React, { useState, useContext, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MDBBtn } from "mdb-react-ui-kit";
import AliumLogo from "./Home/Assets/Logo/Alium-Law-Logo.png";

import SuccessCredits from "./Pages/AdvancedSearch/Alerts/SuccessCredits";
import SuccessSearch from "./Pages/AdvancedSearch/Alerts/SuccessSearch";
import DataRefresh from "./Pages/AdvancedSearch/Alerts/DataRefresh";
import TokenExpiry from "./Pages/AdvancedSearch/Alerts/TokenExpiry";

import { StateContext } from "../State";
import "../analytics";
import ReactGA from "react-ga";

function DrawerAppBar(props) {
  const {
    handleLogin,
    handleLogout,
    navigate,
    handleBasicClick,
    activeSearchData,
    expiredSearchData,
    receipts,
    accessTokenCookie,
    userDataCookie,
    tempInputValue,
    successNotification,
    successResearch,
    payIsVerified,
    dataRefresh,
    tokenExpiryAlert,
    setTokenExpiryAlert,
    isAdvancedLoading,
    isExpiredLoading,
    isReceiptsLoading,
  } = useContext(StateContext);
  // Tracks the Get Advice Button Clicks on Google Analytics
  const handleGetAdviceTracker = () => {
    ReactGA.event({
      category: "events",
      action: "click",
      label: "Get Advice Button",
    });
  };

  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#ffffff",
      },
    },
  });

  const drawerWidth = 240;
  const navItems = [
    { name: "SIMPLE", stat: null, path: "/" },
    {
      name: "ACTIVE",
      stat:
        activeSearchData === null ||
        activeSearchData === undefined ||
        activeSearchData.length === 0
          ? 0
          : activeSearchData?.length,
      path: "/advanced-search/active",
      handleClick: () => handleBasicClick("active"),
    },
    {
      name: "EXPIRED",
      stat:
        expiredSearchData === null ||
        expiredSearchData === undefined ||
        expiredSearchData.length === 0
          ? 0
          : expiredSearchData?.length,
      path: "/advanced-search/expired",
      handleClick: () => handleBasicClick("expired"),
    },
    {
      name: "RECEIPTS",
      stat:
        receipts === null || receipts === undefined || receipts.length === 0
          ? 0
          : receipts?.length,
      path: "/advanced-search/receipts",
      handleClick: () => handleBasicClick("receipts"),
    },
  ];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [isGetAdvice, setIsGetAdvice] = useState(true); // Initial state
  const [count, setCount] = useState(0);

  // Function to toggle between "GET ADVICE" and "MORE INFO" and update count
  const toggleButtonText = () => {
    setIsGetAdvice((prevState) => !prevState);
    setCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    // Retrieve count and button text from local storage on component mount
    const storedCount = localStorage.getItem("buttonToggleCount");
    const storedButtonText = localStorage.getItem("buttonText");
    if (storedCount) {
      setCount(parseInt(storedCount));
    }
    if (storedButtonText) {
      setIsGetAdvice(storedButtonText === "GET ADVICE");
    }
  }, []);

  useEffect(() => {
    // Store count and button text in local storage whenever they change
    localStorage.setItem("buttonToggleCount", count.toString());
    localStorage.setItem(
      "buttonText",
      isGetAdvice ? "GET ADVICE" : "MORE INFO"
    );
  }, [count, isGetAdvice]);

  useEffect(() => {
    // Function to toggle GET INFO & GET ADVICE buttons text and count every 4 hours
    const interval = setInterval(() => {
      toggleButtonText();
    }, 4 * 60 * 60 * 1000); // 4 hours in milliseconds
    return () => clearInterval(interval);
  }, []);

  // MOBILE DRAWER START
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ padding: "25px 0" }}>
        <img
          src={AliumLogo}
          style={{
            height: "50px",
            padding: "5px 0",
            cursor: "pointer",
          }}
          alt="Alium Logo"
        />
      </Typography>
      <Divider />
      <div style={{ height: "15px" }}></div>
      <List
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {accessTokenCookie !== null && userDataCookie !== null
          ? navItems.map((item) => (
              <ListItem
                key={item.name}
                disablePadding
                style={{
                  maxWidth: "80%",
                  width: "80%",
                }}
              >
                <ListItemButton
                  onClick={() => {
                    item.handleClick && item.handleClick();
                    item.path && navigate(item.path);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    textAlign: "center",
                    color: "#1c1c2d",
                    borderRadius: "17px",
                    backgroundColor: "rgba(28, 28, 43, 0.07)",
                    boxShadow: "0 4px 9px -4px #1c1c2b",
                    padding: "5px 15px",
                    margin: "5px 0",
                    minWidth: "155px",
                  }}
                >
                  <div style={{ paddingRight: "15px" }}>
                    <ListItemText primary={item.name} />
                  </div>
                  <div>
                    {(isAdvancedLoading && item.name !== "SIMPLE") ||
                    (isExpiredLoading && item.name !== "SIMPLE") ||
                    (isReceiptsLoading && item.name !== "SIMPLE") ? (
                      <span className="ms-2">
                        <lord-icon
                          src="https://cdn.lordicon.com/jeycryzx.json"
                          trigger="loop"
                          stroke="bold"
                          colors="primary:#b20000,secondary:#1c1c2d"
                          style={{
                            width: "25px",
                            height: "25px",
                            marginTop: "5px",
                          }}
                        ></lord-icon>
                      </span>
                    ) : (
                      item.stat !== null && (
                        <span className="total_notification_light">
                          {item.stat > 0 ? item.stat : 0}
                        </span>
                      )
                    )}
                  </div>
                </ListItemButton>
              </ListItem>
            ))
          : null}

        <div>
          <MDBBtn
            href="https://4egs0m6ybvf.typeform.com/to/xOOhCrDH#product_id=xxxxx"
            target="_blank"
            rel="noreferrer"
            className="get-advice-button mt-4"
            color="success"
            style={{
              fontSize: "16px",
              width: "192px",
              paddingTop: "7px",
              paddingBottom: "7px",
            }}
            onClick={handleGetAdviceTracker}
          >
            {isGetAdvice ? "GET ADVICE" : "MORE INFO"}
          </MDBBtn>
        </div>
        {accessTokenCookie !== null && userDataCookie != null ? (
          <>
            <MDBBtn
              onClick={handleLogout}
              className="login_button mt-2"
              color="success"
              style={{
                fontSize: "16px",
                width: "80%",
                paddingTop: "7px",
                paddingBottom: "7px",
              }}
            >
              SIGN OUT
            </MDBBtn>
          </>
        ) : (
          <>
            <MDBBtn
              onClick={handleLogin}
              className="login_button mt-2"
              color="success"
              style={{
                fontSize: "16px",
                width: "80%",
                paddingTop: "7px",
                paddingBottom: "7px",
              }}
            >
              SIGN IN
            </MDBBtn>
          </>
        )}
      </List>
    </Box>
  );
  // MOBILE DRAWER END

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <Box className="mb-4 dark_bg" sx={{ display: "flex", height: "75px" }}>
        <CssBaseline />
        {/* DESKTOP MENU START */}
        <AppBar component="nav" position="fixed">
          <Toolbar
            sx={{
              justifyContent: "space-between",
              fontFamily: "Poppins, sans-serif",
              height: "100px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: "block", sm: "block" } }}
            >
              <img
                src={AliumLogo}
                style={{
                  height: "50px",
                  padding: "5px 0 5px 30px",
                  cursor: "pointer",
                }}
                alt="Alium Logo"
              />
            </Typography>
            <Box
              className="hide-tabs"
              sx={{
                display: { xs: "none", sm: "flex" },
                height: "100px",
                alignItems: "center",
              }}
            >
              {accessTokenCookie !== null && userDataCookie !== null
                ? navItems.map((item) => (
                    <Button
                      key={item.name}
                      onClick={() => {
                        item.handleClick && item.handleClick();
                        item.path && navigate(item.path);
                      }}
                      className={{ margin: "0 15px" }}
                      sx={{
                        color: "#1c1c2d",
                        margin: "0 10px",
                        border: "1px, solid, #1c1c2d",
                        borderRadius: "17px",
                        backgroundColor: "rgba(28, 28, 43, 0.07)",
                        boxShadow: "0 4px 9px -4px #1c1c2b",
                        padding: "1px 10px",
                        margin: "0 7px",
                        minWidth: "155px",
                        height: "42px",
                      }}
                    >
                      <div>{item.name}</div>{" "}
                      <div>
                        {(isAdvancedLoading && item.name !== "SIMPLE") ||
                        (isExpiredLoading && item.name !== "SIMPLE") ||
                        (isReceiptsLoading && item.name !== "SIMPLE") ? (
                          <span className="ms-2">
                            <lord-icon
                              src="https://cdn.lordicon.com/jeycryzx.json"
                              trigger="loop"
                              stroke="bold"
                              colors="primary:#b20000,secondary:#1c1c2d"
                              style={{
                                width: "25px",
                                height: "25px",
                                marginTop: "5px",
                              }}
                            ></lord-icon>
                          </span>
                        ) : (
                          item.stat !== null && (
                            <span className="total_notification_light ms-2">
                              {item.stat > 0 ? item.stat : 0}
                            </span>
                          )
                        )}
                      </div>
                    </Button>
                  ))
                : null}
              <div className="ms-3" style={{ alignSelf: "center" }}>
                <MDBBtn
                  href="https://4egs0m6ybvf.typeform.com/to/xOOhCrDH#product_id=xxxxx"
                  target="_blank"
                  rel="noreferrer"
                  className="get-advice-button hide-tabs"
                  color="success"
                  style={{
                    fontSize: "14px",
                    margin: "0px 5px",
                    paddingTop: "7px",
                    paddingBottom: "7px",
                  }}
                  onClick={handleGetAdviceTracker}
                >
                  {isGetAdvice ? "GET ADVICE" : "MORE INFO"}
                </MDBBtn>
                {accessTokenCookie !== null && userDataCookie != null ? (
                  <>
                    <MDBBtn
                      onClick={handleLogout}
                      className="login_button hide-tabs"
                      color="success"
                      style={{
                        fontSize: "14px",
                        margin: "0 5px",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                    >
                      SIGN OUT
                    </MDBBtn>
                  </>
                ) : (
                  <>
                    <MDBBtn
                      onClick={handleLogin}
                      className="login_button hide-tabs"
                      color="success"
                      style={{
                        fontSize: "14px",
                        margin: "0 5px",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                    >
                      SIGN IN
                    </MDBBtn>
                  </>
                )}
              </div>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{
                ml: 2,
                display: { sm: "block", md: "none", xs: "block" },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {successNotification || successResearch ? (
          <SuccessSearch tempInputValue={tempInputValue} />
        ) : null}
        {payIsVerified ? <SuccessCredits /> : null}
        {dataRefresh ? <DataRefresh /> : null}
        {tokenExpiryAlert ? <TokenExpiry /> : null}
        {/* DESKTOP MENU END */}
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            anchor="left"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                minWidth: drawerWidth,
                width: "auto",
                ["@media (min-width:600px)"]: {
                  width: "400px", // 500px width on tablets
                },
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </ThemeProvider>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
