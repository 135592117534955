import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { MDBRow, MDBBtn } from "mdb-react-ui-kit";
import { StateContext } from "../../State";

import HowToUseSteps from "./HowToUseSteps";
import ReactGA from "react-ga";

export default function AdvancedSearchIntro() {
  const [show, setShow] = useState(false);

  const { accessTokenCookie, userDataCookie, handleLogin, redirectToCredits } =
    useContext(StateContext);

  //   useEffect(() => {
  //     // Changing the state after 10 sec
  //     // from the time when the component
  //     // is rendered
  //     setTimeout(() => {
  //       setShow(true);
  //     }, 7000);
  //   }, []);

  if (show) {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'What is Advanced Search Button' // Replace with a descriptive label
    });
    return (
      <>
        {/* Mobile Alert Start*/}
        <Alert
          className="hide-desktop hide-tabs p-4"
          variant="light"
          style={{
            textAlign: "center",
            marginBottom: "50px",
            color: "#1c1c2d",
          }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Alert.Heading style={{ fontWeight: "600" }}>
            WHAT IS ADVANCED SEARCH?
          </Alert.Heading>
          <MDBRow className="hide-desktop pb-2">
            <p className="mt-3 mb-4 pb-md-0 mb-md-5 px-md-2">
              Get details of the Kenya Gazette Volume, Notice No, and a PDF of
              the page in which your property was published by the Ministry of
              Lands.
              <br />
              <br />
            </p>
          </MDBRow>
          <HowToUseSteps />
          <Alert.Heading
            className="mt-4"
            style={{ fontWeight: "600", textAlign: "center" }}
          >
            PURCHASING CREDITS
          </Alert.Heading>
          <p className="mt-4 mb-4">
            <span style={{ fontWeight: "900" }}>1 Credit</span> enables{" "}
            <strong>one Advanced Search</strong>. There are multiple payment
            options powered by Paystack (Regulated by The Central Bank of
            Kenya).
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {accessTokenCookie === null && userDataCookie === null ? (
              <MDBBtn
                className="mt-3 view_pdf_button"
                color="success"
                style={{
                  fontSize: "16px",
                }}
                type="button"
                onClick={handleLogin}
              >
                SIGN IN TO START
              </MDBBtn>
            ) : (
              <MDBBtn
                className="mt-3 view_pdf_button"
                color="success"
                style={{
                  fontSize: "16px",
                }}
                type="button"
                onClick={redirectToCredits}
              >
                PURCHASE CREDIT(S)
              </MDBBtn>
            )}
          </div>
        </Alert>
        {/* Mobile Alert End*/}

        {/* Desktop Alert Start*/}
        <Alert
          className="hide-mobile p-4"
          variant="light"
          style={{
            color: "#1c1c2d",
          }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Alert.Heading style={{ fontWeight: "600" }}>
            WHAT IS ADVANCED SEARCH?
          </Alert.Heading>
          <MDBRow className="hide-mobile d-flex justify-content-center align-items-center pt-0 mb-2">
            <div className="hide-mobile">
              <p className="mt-4 mb-4">
                Get details of the Kenya Gazette Volume, Notice No, and a PDF of
                the page in which your property was published by the Ministry of
                Lands.
                <br />
                <br />
                <strong>
                  Find out whether your Title Deed is ready for replacement with
                  the Advanced Search for a better experience.
                </strong>
              </p>
            </div>
          </MDBRow>
          {/* <Alert.Heading style={{ fontWeight: "600" }}>
            HOW TO USE
          </Alert.Heading> */}
          <HowToUseSteps />
          <Alert.Heading
            className="mt-4"
            style={{ fontWeight: "600", textAlign: "center" }}
          >
            PURCHASING CREDITS
          </Alert.Heading>
          <p className="mt-4 mb-4">
            <span style={{ fontWeight: "900" }}>1 Credit</span> enables{" "}
            <strong>one Advanced Search</strong>. There are multiple payment
            options powered by Paystack (Regulated by The Central Bank of
            Kenya). Simply click the <strong>Buy Credit(s)</strong> button on
            the Advanced Search Page.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {accessTokenCookie === null && userDataCookie === null ? (
              <MDBBtn
                className="mt-3 view_pdf_button"
                color="success"
                style={{
                  fontSize: "16px",
                }}
                type="button"
                onClick={handleLogin}
              >
                SIGN IN TO START
              </MDBBtn>
            ) : (
              <MDBBtn
                className="mt-3 view_pdf_button"
                color="success"
                style={{
                  fontSize: "16px",
                }}
                type="button"
                onClick={redirectToCredits}
              >
                PURCHASE CREDIT(S)
              </MDBBtn>
            )}
          </div>
        </Alert>
        {/* Desktop Alert End*/}
      </>
    );
  }
  return (
    <>
      {/* Mobile Button */}
      <div
        className="hide-desktop hide-tabs"
        style={{
          display: "flex",
          margin: "5px 0 50px",
        }}
      >
        <Button
          className="how_to_use_btn"
          onClick={() => setShow(true)}
          style={{
            margin: "0 auto",
            border: "none",
            fontWeight: "700",
            padding: "15px",
          }}
        >
          WHAT IS ADVANCED SEARCH?
        </Button>
      </div>

      {/* Desktop Button */}
      <Button
        className="hide-mobile how_to_use_btn"
        onClick={() => setShow(true)}
        style={{
          border: "none",
          margin: "15px 0 25px",
          fontWeight: "700",
        }}
      >
        WHAT IS ADVANCED SEARCH?
      </Button>
    </>
  );
}
