import React, { useState, useEffect, useContext } from "react";
import { MDBTabsPane, MDBBtn, MDBRow } from "mdb-react-ui-kit";

import HowToUseLeft from "../../../Alerts/HowToUseLeft";

import { StateContext } from "../../../../State";

export default function HowToUse() {
  const { handleBasicClick, accessTokenCookie, userDataCookie, handleLogin, redirectToCredits } = useContext(StateContext);

  const [activeKey, setActiveKey] = useState(0);

  const handleSelect = (key) => {
    // Update the active tab when a new tab is selected
    setActiveKey(key);
  };

  useEffect(() => {
    // Check if the page is being reloaded
    const isReloading = sessionStorage.getItem("isReloading");
    if (isReloading) {
      // If reloading, retrieve the last selected tab from storage
      const lastSelectedTab = localStorage.getItem("lastSelectedTab");
      if (lastSelectedTab !== null) {
        setActiveKey(parseInt(lastSelectedTab, 10));
        handleBasicClick(lastSelectedTab);
      }
      // Clear the reloading flag
      sessionStorage.removeItem("isReloading");
    }
  }, []);

  return (
    <MDBTabsPane activeKey={activeKey} onSelect={handleSelect} show>
      <div style={{ marginTop: "30px" }}>
        <h3 style={{ fontWeight: "900" }}>WHAT IS ADVANCED SEARCH?</h3>
        <MDBRow className="pt-0 mb-2">
          <div>
            <p className="mt-4 mb-4">
              Get details of the Kenya Gazette Volume, Notice No, and a PDF of
              the page in which your property was published by the Ministry of
              Lands.
              <br />
              <br />
              <strong>
                Find out whether your Title Deed is ready for replacement with
                the Advanced Search for a better experience.
              </strong>
            </p>
          </div>
        </MDBRow>
        <MDBRow className="d-flex justify-content-center align-items-center">
          <HowToUseLeft />
        </MDBRow>
        <MDBRow className="mt-4">
          <h1 style={{ fontSize: "28px", fontWeight: "900" }}>
            PURCHASING CREDITS
          </h1>
          <p className="mt-4 mb-4">
            <span style={{ fontWeight: "900" }}>1 Credit</span> enables{" "}
            <strong>one Advanced Search</strong>. There are multiple payment
            options powered by Paystack (Regulated by The Central Bank of
            Kenya). Simply click the <strong>Buy Credit(s)</strong> button on
            the Advanced Search Page.
          </p>
        </MDBRow>
        <div>
        {accessTokenCookie === null && userDataCookie === null ? (
              <MDBBtn
                className="mt-3 view_pdf_button"
                color="success"
                style={{
                  fontSize: "16px",
                }}
                type="button"
                onClick={handleLogin}
              >
                SIGN IN TO START
              </MDBBtn>
            ) : (
              <MDBBtn
                className="mt-3 view_pdf_button"
                color="success"
                style={{
                  fontSize: "16px",
                }}
                type="button"
                onClick={redirectToCredits}
              >
                PURCHASE CREDIT(S)
              </MDBBtn>
            )}
        </div>
        <MDBRow className="mt-4">
          <p>
            Clicking <strong>UNDERSTOOD</strong> means that you agree to our{" "}
            <a
              href="https://aliumlaw.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
        </MDBRow>
      </div>
    </MDBTabsPane>
  );
}
