import React, { useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
}
from 'mdb-react-ui-kit';
import SimpleSearchResultsWrapper from '../Pages/SimpleSearch/Results/SimpleSearchResultsWrapper';
import OldLrForm from '../Pages/SimpleSearch/SimpleSearch';
import Notification from '../Alerts/Notification';
import CTA from '../Alerts/CTA';

function MobileContainer() {

  const [oldLrNo, setOldLrNo] = useState('');

  const handleOldLrInputChange = (oldLrNo) => {
    setOldLrNo(oldLrNo);
  }

  return (
    <MDBContainer fluid style={{ overflowX: 'hidden' }}>
      {/* <div className='my-6' style={{ height: '100px' }}></div> */}
              <MDBCard className='text-black m-0 p-0' style={{borderRadius: '25px'}}>
                <MDBCardBody style={{ padding: '0' }}>
                  <MDBRow>
                    <MDBCol>
                      <OldLrForm 
                      handleOldLrInputChange={handleOldLrInputChange}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ margin: "0" }}>
                    <MDBCol>
                      <SimpleSearchResultsWrapper 
                        oldLrNo={oldLrNo}
                      />
                    </MDBCol>
                  </MDBRow>
                  {/* Notification Section Mobile Start */}
                  {/* <MDBRow style={{ marginTop: '25px' }}>
                    <MDBCol lg='12'>
                    <Notification />
                    </MDBCol>
                  </MDBRow> */}
                  {/* Notification Section Mobile End */}
                  <MDBRow>
                  <MDBCol lg='12'>
                  <CTA />
                  </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
    </MDBContainer>
  );
}


export default MobileContainer;