import React, { useContext } from "react";
import { MDBTabsPane } from "mdb-react-ui-kit";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import VisaLogo from "../../Assets/Icons/visa.png";
import MasterCardLogo from "../../Assets/Icons/mastercard.png";
import MpesaLogo from "../../Assets/Icons/mpesa.png";

import { StateContext } from "../../../../../State";

const WhitePagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: "#ffffff",
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#ffffff",
  },
}));

export default function TabReceipts() {
  const { receiptsPage, setReceiptsPage, receipts } = useContext(StateContext);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: "#ff0000",
      },
    },
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          icon: {
            color: "#ffffff",
          },
          root: {
            "&.Mui-selected": {
              color: "#ff0000",
            },
          },
        },
      },
    },
  });
  const itemsPerPage = 10; // Number of items to display per page

  const totalItems = receipts === undefined ? 0 : receipts?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleChange = (event, value) => {
    // console.log("Changing to page:", value);
    setReceiptsPage(value);
  };

  // Calculate the start and end indexes for the items on the current page
  const startIndex = (receiptsPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Extract the items for the current page
  const itemsOnPage =
    receipts === undefined ? [] : receipts?.slice(startIndex, endIndex);
  // console.log("LOADED RECEIPTS: ", receipts);
  // console.log("RECEIPTS ON PAGE: ", itemsOnPage);

  // Function to convert UTC time to Nairobi time (GMT+3)
  function convertToNairobiTime(utcTime) {
    const nairobiTime = new Date(utcTime);
    nairobiTime.setHours(nairobiTime.getHours() + 3); // Add 3 hours for Nairobi time

    // Format the date and time with "AT" before the time
    const formattedDate = nairobiTime.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const formattedTime = nairobiTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `ON ${formattedDate} AT ${formattedTime}`;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="hide-desktop hide-mobile">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: "17px",
            backgroundColor: "#1c1c2d",
            padding: "15px 15px",
            alignContent: "center",
          }}
        >
          <Typography className="ms-2" style={{ alignSelf: "center" }}>
            <h5 style={{ color: "#ffffff", margin: "0" }}>
              PAGE: {receiptsPage}
            </h5>
          </Typography>
          <Stack spacing={2}>
            <WhitePagination
              defaultPage={1}
              count={totalPages} // Use the total number of pages
              page={receiptsPage}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </Stack>
        </div>
        <div>
          {itemsOnPage.map((receipt, index) => (
            <MDBTabsPane
              key={receipt.id}
              show
              style={{
                boxShadow: "0 0 5px #1c1c2b",
                borderRadius: "17px",
                padding: "20px 30px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "25px 0",
                fontWeight: "600",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    marginBottom: "10px",
                    alignSelf: "flex-start",
                    border: "1px solid #1c1c2d",
                    borderRadius: "17px",
                    padding: "2px 10px 2px 10px",
                    boxShadow: "0 0 3px #1c1c2d",
                  }}
                >
                  <span style={{ fontSize: "13px" }}>PAID WITH</span>{" "}
                  {receipt?.channel === "card" ? (
                    receipt?.channel === "card" &&
                    receipt?.card_type === "visa" ? (
                      <img
                        src={MasterCardLogo}
                        alt="MasterCard Logo"
                        style={{
                          alignSelf: "flex-start",
                          width: "auto",
                          height: "11px",
                        }}
                      />
                    ) : (
                      <img
                        src={VisaLogo}
                        alt="Visa Logo"
                        style={{
                          alignSelf: "flex-start",
                          width: "auto",
                          height: "11px",
                        }}
                      />
                    )
                  ) : (
                    <img
                      src={MpesaLogo}
                      alt="Mpesa Logo"
                      style={{
                        alignSelf: "flex-start",
                        width: "auto",
                        height: "11px",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span>
                    AMOUNT: <strong>KSH. {receipt?.amount / 100}</strong>
                  </span>
                  <span>
                    REFERENCE NO: <strong>{receipt.reference}</strong>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    marginTop: "0px",
                  }}
                >
                  <div>
                    <span style={{ textTransform: "uppercase" }}>
                      PAID WITH{" "}
                      <strong>
                        {receipt.card_type ? receipt.card_type : null}{" "}
                        {receipt?.channel === "mobile_money" ? "MPESA" : null}
                      </strong>
                      <br />
                      <strong>
                        {" "}
                        {receipt.paid_at
                          ? convertToNairobiTime(receipt.paid_at)
                          : "DATE UNAVAILABLE"}
                      </strong>
                    </span>
                  </div>
                </div>
                <div className="mt-2">
                  <span style={{ fontSize: "13px" }}>
                    <a
                      href={`mailto:aliumlawtd@gmail.com?subject=Issue%20with%20reference%20${
                        receipt?.reference || ""
                      }%20paid%20via%20${
                        receipt?.card_type.toUpperCase() || null
                      }on%20${receipt?.paid_at || ""}`}
                    >
                      Report Purchase
                    </a>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  className="last_purchase_title"
                  style={{ boxShadow: "0 0 3px #1c1c2d" }}
                >
                  <span className="credits_icon">
                    {receipt?.purchased_credits
                      ? receipt?.purchased_credits
                      : 0}
                  </span>
                  <div className="purchased_credits_text">
                    {receipt?.purchased_credits === 1 ? (
                      <h3>CREDIT</h3>
                    ) : (
                      <h3>CREDITS</h3>
                    )}
                    <h3>PURCHASED</h3>
                  </div>
                </div>
              </div>
            </MDBTabsPane>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: "17px",
            backgroundColor: "#1c1c2d",
            padding: "15px 15px",
            alignContent: "center",
          }}
        >
          <Typography className="ms-2" style={{ alignSelf: "center" }}>
            <h5 style={{ color: "#ffffff", margin: "0" }}>
              PAGE: {receiptsPage}
            </h5>
          </Typography>
          <Stack spacing={2}>
            <WhitePagination
              defaultPage={1}
              count={totalPages} // Use the total number of pages
              page={receiptsPage}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </Stack>
        </div>
      </div>
    </ThemeProvider>
  );
}
