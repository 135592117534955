import React, { useContext } from "react";
import { StateContext } from "../../../../State";

export default function NoExpiredData() {
  const { toggleShow } = useContext(StateContext);
  return (
    <div
      style={{
        boxShadow: "0 0 13px #1c1c2b",
        borderRadius: "17px",
        padding: "20px 30px",
        display: "flex",
        flexDirection: "column",
        margin: "25px 0",
        backgroundColor: "rgba(178,0,0, 0.87)",
      }}
    >
      <h2
        style={{
          margin: "0",
          color: "#ffffff",
          fontSize: "17px",
          fontWeight: "300",
          alignSelf: "center",
          lineHeight: "27px",
        }}
      >
          Enter your L.R NO. in the input field above to get started. You need
          to{" "}
          <a
            style={{
              color: "#1c1c2d",
              fontWeight: "900",
              textShadow: "0 0 3px #ffffff",
            }}
            onClick={toggleShow}
          >
            buy credits
          </a>{" "}
          to use Advanced Search.
      </h2>
    </div>
  );
}
