import React, { useContext } from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import AdvancedSearchIntro from "../../../Alerts/AdvancedSearchIntro";
import { StateContext } from "../../../../State";

export default function SubmitButton({ handleSimpleSearchClick }) {
  const {
    accessTokenCookie,
    userDataCookie,
    handleLogin,
    redirectToAdvancedSearch,
  } = useContext(StateContext);

  return (
    <>
      {/* Submit Button Mobile Start*/}
      <div
        className="hide-desktop hide-tabs"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MDBBtn
          color="success"
          className="mb-1 mt-3 custom-button-simple-search text-center"
          size="lg"
          style={{
            fontSize: "16px",
            width: "80%",
          }}
          type="button"
          onClick={handleSimpleSearchClick}
        >
          Simple Search
        </MDBBtn>
        <MDBBtn
          color="success"
          className="mb-2 mt-3 custom-button-simple-search text-center"
          size="lg"
          style={{
            fontSize: "16px",
            width: "80%",
          }}
          type="button"
          onClick={
            accessTokenCookie === null && userDataCookie === null
              ? handleLogin
              : redirectToAdvancedSearch
          }
        >
          Advanced Search
        </MDBBtn>
      </div>

      {/* Submit Button Mobile End*/}

      {/* Submit Button Desktop Start*/}
      <div
        className="hide-mobile hide-tabs"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <MDBBtn
            color="success"
            className="hide-mobile hide-tabs mb-4 mt-3 me-3 custom-button-simple-search"
            size="lg"
            style={{ fontSize: "16px" }}
            type="button"
            onClick={handleSimpleSearchClick} // Use the custom handler function
          >
            Simple Search
          </MDBBtn>

          <MDBBtn
            color="success"
            className="hide-mobile hide-tabs mb-4 mt-3 mx-2 custom-button-simple-search"
            size="lg"
            style={{ fontSize: "16px" }}
            type="button"
            onClick={
              accessTokenCookie === null && userDataCookie === null
                ? handleLogin
                : redirectToAdvancedSearch
            }
          >
            Advanced Search
          </MDBBtn>
        </div>
        <div>
          <AdvancedSearchIntro />
        </div>
      </div>
      {/* Submit Button Desktop End*/}

      {/* Submit Button Tablet Start*/}
      <MDBBtn
        color="success"
        className="hide-mobile hide-desktop mb-4 mt-3 custom-button-simple-search"
        size="lg"
        style={{ fontSize: "16px" }}
        type="button"
        onClick={handleSimpleSearchClick} // Use the custom handler function
      >
        Simple Search
      </MDBBtn>

      <MDBBtn
        color="success"
        className="hide-mobile hide-desktop mb-4 mt-3 mx-2 custom-button-simple-search"
        size="lg"
        style={{ fontSize: "16px" }}
        type="button"
        onClick={
          accessTokenCookie === null && userDataCookie === null
            ? handleLogin
            : redirectToAdvancedSearch
        }
      >
        Advanced Search
      </MDBBtn>
      <br />
      <div className="hide-desktop">
        <AdvancedSearchIntro />
      </div>
      {/* Submit Button Tablet End*/}
    </>
  );
}
