import React from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

export default function HowToUseLeft() {
  return (
    <>
      {/* STEPS INSTRUCTIONS SECTION START */}
      {/* Desktop Heading*/}
      <MDBRow className="hide-mobile hide-tabs m-0 p-0">
        <MDBCol>
          <h3
            className="text-left"
            style={{
              fontWeight: "900",
            }}
          >
            HOW TO USE
          </h3>
        </MDBCol>
      </MDBRow>

      {/* Mobile Heading */}
      <MDBRow className="hide-desktop">
        <MDBCol>
          <h3
            className="text-center"
            style={{
              fontWeight: "900",
            }}
          >
            HOW TO USE
          </h3>
        </MDBCol>
      </MDBRow>

      {/* User Instructions Section Start */}
      <MDBRow
        style={{
          padding: "0 10px",
          display: "flex",
        }}
      >
        <MDBCol
          lg="4"
          style={{
            margin: "0 auto 10px",
            padding: "10px",
          }}
        >
          {/* Mobile Start*/}
          <div className="card hide-desktop text-center box_shadow border_radiius">
            <div
              className="card-body"
              style={{ padding: "25px", fontSize: "21px" }}
            >
              <h2 className="card-title">
                <strong>1</strong>
              </h2>
              <h6 className="card-text" style={{ fontSize: "24px", fontWeight: "900" }}>
                Sign In
              </h6>
            </div>
          </div>
          {/* Mobile End*/}
          {/* Desktop Start*/}
          <div
            className="card hide-mobile hide-tabs text-center"
            style={{
              width: "auto",
              height: "270px",
            }}
          >
            <div
              className="card-body box_shadow border_radiius"
              style={{
                padding: "25px",
                fontSize: "21px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2 className="card-title">
                <strong>1</strong>
              </h2>
              <h6 className="card-text" style={{ fontSize: "24px", fontWeight: "900" }}>
                Sign In
              </h6>
            </div>
          </div>
          {/* Desktop End*/}
        </MDBCol>
        <MDBCol
          lg="4"
          style={{
            margin: "0 auto 10px",
            padding: "10px",
          }}
        >
          {/* Mobile Start */}
          <div
            className="card hide-desktop text-center  box_shadow border_radiius"
            style={{ width: "auto" }}
          >
            <div
              className="card-body"
              style={{ padding: "25px", fontSize: "21px" }}
            >
              <h2 className="card-title">
                <strong>2</strong>
              </h2>
              <h6 className="card-text" style={{ fontSize: "24px", fontWeight: "900" }}>
              Purchase Credits
              </h6>
            </div>
          </div>
          {/* Mobile Start */}
          {/* Desktop Start */}
          <div
            className="card hide-mobile hide-tabs text-center box_shadow border_radiius"
            style={{
              width: "auto",
              height: "270px",
            }}
          >
            <div
              className="card-body text-center"
              style={{
                padding: "25px",
                fontSize: "21px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2 className="card-title">
                <strong>2</strong>
              </h2>
              <h6 className="card-text" style={{ fontSize: "24px", fontWeight: "900" }}>
                Purchase Credits
              </h6>
            </div>
          </div>
          {/* Desktop Start */}
        </MDBCol>
        <MDBCol
          lg="4"
          style={{
            margin: "0 auto 10px",
            padding: "10px",
          }}
        >
          {/* Mobile Start*/}
          <div
            className="card hide-desktop text-center box_shadow border_radiius"
            style={{
              width: "auto",
            }}
          >
            <div
              className="card-body"
              style={{ padding: "25px", fontSize: "21px" }}
            >
              <h2 className="card-title">
                <strong>3</strong>
              </h2>
              <h6 className="card-text" style={{ fontSize: "24px", fontWeight: "900" }}>
              Search LR No.
              </h6>
            </div>
          </div>
          {/* Mobile End*/}
          {/* Desktop Start*/}
          <div
            className="card hide-mobile hide-tabs text-center box_shadow border_radiius"
            style={{
              width: "auto",
              height: "270px",
            }}
          >
            <div
              className="card-body"
              style={{
                padding: "25px",
                fontSize: "21px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2 className="card-title">
                <strong>3</strong>
              </h2>
              <h6 className="card-text" style={{ fontSize: "24px", fontWeight: "900" }}>
                Search LR No.
              </h6>
            </div>
          </div>
          {/* Desktop End*/}
        </MDBCol>
      </MDBRow>
      {/* STEPS INSTRUCTIONS SECTION END */}
    </>
  );
}
