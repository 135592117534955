import React from 'react';
import DesktopTable from './DesktopTable';
import MobileCard from './MobileCard';

export default function SimpleSearchResultsWrapper() {
  return (
    <>
      <DesktopTable 
      />
      <MobileCard 
      />
    </>
  );
}
