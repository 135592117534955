import React, { useContext } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { StateContext } from "../../../../State";

export default function TokenExpiry() {
  const { handleLogin, tokenExpiryAlert, setTokenExpiryAlert } =
    useContext(StateContext);
  const handleClose = () => {
    // console.log("CLICKING THE TOKEN EXPIRY ALERT...")
    setTokenExpiryAlert(false);
    // console.log("TOKEN EXPIRY ALERT STATUS: ", tokenExpiryAlert)
  };
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        left: "0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        zIndex: "999",
      }}
    >
      <Alert
      severity="warning"
        variant="filled"
        className="box_shadow_dark border_radius"
        style={{
          zIndex: "999",
          position: "absolute",
          top: "0",
          marginTop: "110px",
          width: "90%",
          fontWeight: "500",
          fontSize: "14px",
          backgroundColor: "rgba(28,28,43,0.97)",
          color: "#ffffff",
          padding: "25px 15px",
        }}
        value={tokenExpiryAlert}
        onClose={handleClose}
      >
        Your Session Token has expired. Attempting to log you back in...{" "}
        <Button
          className="login_button hide-mobile hide-tabs"
          style={{
            padding: "7px 25px",
            marginLeft: "15px",
            fontWeight: "700"
          }}
          color="inherit"
          size="small"
          onClick={handleLogin}
        >
          SIGN IN
        </Button>
        <Button
          className="login_button hide-desktop hide-mobile"
          style={{
            padding: "7px 25px",
            marginLeft: "15px",
            fontWeight: "700"
          }}
          color="inherit"
          size="small"
          onClick={handleLogin}
        >
          SIGN IN
        </Button>
      </Alert>
    </div>
  );
}
