import React, { useContext } from "react";
import { MDBTabsPane, MDBBtn } from "mdb-react-ui-kit";
import { DateTime } from "luxon";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { StateContext } from "../../../../../State";

const WhitePagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: "#ffffff",
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#ffffff",
  },
}));

export default function MobileExpired() {
  const {
    expiredPage,
    setExpiredPage,
    expiredSearchData,
    serverUrl,
    accessTokenCookie,
    userDataCookie,
    setIsAdvancedLoading,
    setIsAdvancedError,
    setSuccessNotification,
    handleBasicClick,
    postDataToCreateValidSearch,
    setFilteredData,
    setAdvancedSearchData,
    getCookie,
    setTempInputValue,
    setSuccessResearch,
    convertToNairobiTime,
    setCreditIsDeducted,
    availableCredits,
    email,
    setAdvancedSearchSubmitted,
    retrivedCredits,
  } = useContext(StateContext);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: "#ff0000",
      },
    },
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          icon: {
            color: "#ffffff",
          },
          root: {
            "&.Mui-selected": {
              color: "#ff0000",
            },
          },
        },
      },
    },
  });

  const advanced_search_cookie = getCookie("advanced_search_data");

  const reperformAdvancedSearch = async (lrNo) => {
    const advancedSearchUrl = `${serverUrl}/advanced/api/advanced-search?old_lr_no=${lrNo.toUpperCase()}`;

    try {
      setTempInputValue(lrNo.toUpperCase());
      handleBasicClick("active");
      if (lrNo != null || lrNo != undefined) {
        setIsAdvancedLoading(true);
        setIsAdvancedError(false);

        // Fetch advanced search data
        const response = await fetch(advancedSearchUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessTokenCookie}`,
          },
          cache: "no-store",
        });

        // console.log("Response Status: \n", response.status);

        if (response.status != 200) {
          setIsAdvancedLoading(false);
          throw new Error(
            `Failed to research data with status ${response.status}`
          );
        }

        const advancedSearchData = await response.json();

        // console.log("advancedSearchData: \n", advancedSearchData);

        if (advancedSearchData.length === 0) {
          // No data found, notify the user
          alert("No data found for the given search criteria.");
          setIsAdvancedLoading(false);
          return;
        }

        if (response.status === 200) {
          try {
            setIsAdvancedLoading(false);
            setIsAdvancedError(false);
            setSuccessNotification(true);
            setSuccessResearch(true);
            setAdvancedSearchSubmitted(true);

            // Parse the advanced_search_cookie JSON
            let parsedCookieData = [];
            try {
              parsedCookieData = JSON.parse(advanced_search_cookie || "[]");
              if (!Array.isArray(parsedCookieData)) {
                parsedCookieData = []; // Ensure parsedCookieData is an array
              }
            } catch (parseError) {
              console.error(
                "Error parsing advanced_search_cookie:",
                parseError
              );
            }

            // console.log("parsedCookieData:", parsedCookieData);

            // Combine the existing data with the new data
            const updatedData = [...parsedCookieData, ...advancedSearchData];
            // console.log("updatedData: \n", updatedData);

            // Filter out duplicates based on a unique property (e.g., old_title_no)
            const newFilteredData = updatedData.filter(
              (item, index, self) =>
                self.findIndex((i) => i.old_title_no === item.old_title_no) ===
                index
            );
            // console.log("newFilteredData:", newFilteredData);

            // Store the filtered data as a Cookie
            // document.cookie = `advanced_search_data=${JSON.stringify(
            //   newFilteredData
            // )}; path=/; expires=${expirationDate.toUTCString()}; secure;`;

            // Set the value of filteredData using the state setter function
            setFilteredData(newFilteredData);
            setAdvancedSearchData(newFilteredData);
            // console.log("ADVANCED SEARCH DATA: \n", advancedSearchData);
          } catch (error) {
            console.error("Error has occured:", error);
            setIsAdvancedError(true);
          } finally {
            setIsAdvancedLoading(false);
            setTimeout(() => {
              setSuccessNotification(false);
            }, 6000);
          }
          const userData = JSON.parse(userDataCookie || "{}"); // Parse the JSON string or provide an empty object as a default
          const userName = userData.name || "";
          const userEmail = userData.email || "";

          const firstItem = advancedSearchData[0];
          // console.log("FIRST ITEM: \n", firstItem);
          // if (firstItem) {
          //   console.log("FIRST ITEM AREA: \n", firstItem.area);
          // } else {
          //   console.log("firstItem is undefined");
          // }

          const currentDate = DateTime.now().setZone("UTC"); // Get the current time in EAT

          const activationDate = currentDate.toISO();
          console.log("NAIROBI TIME IN ISO: ", currentDate);
          const expiryDate = currentDate.plus({ hours: 24 }).toISO();

          const searchData = {
            username: userName,
            useremail: userEmail,
            old_lr_no: lrNo.toUpperCase(),
            activation: activationDate,
            expiry: expiryDate,
            new_title_no: firstItem ? firstItem.new_title_no : "",
            area: firstItem ? firstItem.area : "",
            size_ha: firstItem ? firstItem.size_ha : "",
            gazette_volume: firstItem ? firstItem.gazette_volume : "",
            gazette_notice_no: firstItem ? firstItem.gazette_notice_no : "",
            published_date: firstItem ? firstItem.published_date : "",
            gazette_page: firstItem ? firstItem.gazette_page : "",
            pdf_page: firstItem ? firstItem.pdf_page : "",
            pdf_link: firstItem ? firstItem.pdf_link : "",
          };

          // console.log("SEARCH DATA: ", searchData);

          await postDataToCreateValidSearch(searchData);
        } else if (response.status === 404) {
          alert("Bad Request: Please enter your Old Title Number");
        } else if (response.status === 400) {
          // Handle Bad Request status here
          alert("Bad Request: Please enter your Old Title Number");
        } else {
          // Handle other error cases
          throw new Error(
            `Advanced Search failed with status ${response.status}`
          );
        }
      }

      // Handle the response from create-valid-search as needed
    } catch (error) {
      console.error("Error:", error);
      setIsAdvancedError(true);
    } finally {
      setIsAdvancedLoading(false);
      setCreditIsDeducted(false);
    }
  };

  // Credit Deduction function Start
  const handleDeductCredit = async (lrNo) => {
    const confirmAdvancedSearch = window.confirm(
      `You are about to search for LR NO. ${lrNo?.toUpperCase()} again and 1 CREDIT will be DEDUCTED. Do you wish to continue with the search?`
    );
    if (retrivedCredits > 0 && confirmAdvancedSearch) {
      try {
        const response = await fetch(
          `${serverUrl}/paystack/deduct-credit?email=${email}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ available_credits: retrivedCredits }),
          }
        );

        if (response.status === 200) {
          // Successfully deducted credits
          setCreditIsDeducted(true);
          // console.log("Credits deducted successfully.");
          await reperformAdvancedSearch(lrNo);
        } else {
          setCreditIsDeducted(false);
          const data = await response.json();
          console.error(data.error);
        }
      } catch (error) {
        console.error("Error deducting credits:", error);
      }
    } else {
      alert("To use the Advanced Search, you require CREDIT(S).");
    }
  };
  // Credit Deduction function End

  async function handleSelectedLRNo(lrNo, event) {
    if (!accessTokenCookie || !userDataCookie) {
      alert("You must Sign In to use Advanced Search");
      return;
    }

    if (event != null) {
      event.preventDefault();
    }

    try {
      handleDeductCredit(lrNo);
    } catch (error) {
      console.error(
        "Error reperforming an Advanced Search for youe selected Expired Data."
      );
    }
  }

  const itemsPerPage = 10; // Number of items to display per page

  const totalItems = expiredSearchData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleChange = (event, value) => {
    // console.log("Changing to page:", value);
    setExpiredPage(value);
  };

  // Calculate the start and end indexes for the items on the current page
  const startIndex = (expiredPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Extract the items for the current page
  const itemsOnPage = expiredSearchData.slice(startIndex, endIndex);

  return (
    <ThemeProvider theme={theme}>
      {accessTokenCookie !== null && userDataCookie !== null ? (
        <div className="hide-desktop hide-tabs hide-desktop">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: "17px",
              backgroundColor: "#1c1c2d",
              padding: "15px 15px",
              alignContent: "center",
            }}
          >
            <Typography className="ms-2" style={{ alignSelf: "center" }}>
              <h5 style={{ color: "#ffffff", margin: "0" }}>
                PAGE: {expiredPage}
              </h5>
            </Typography>
            <Stack spacing={2}>
              <WhitePagination
                defaultPage={1}
                count={totalPages} // Use the total number of pages
                page={expiredPage}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
                color="primary"
              />
            </Stack>
          </div>
          {/* Render your data based on the `itemsOnPage` state */}
          <div>
            {itemsOnPage.map((title_deed, index) => (
              <div className="search_results_wrapper">
                <MDBTabsPane
                  show
                  key={index}
                  className="inner_search_results_wrapper"
                  style={{
                    boxShadow: "0 4px 9px -1px #3b71ca",
                    borderRadius: "17px",
                    display: "flex",
                    flexDirection: "column",
                    margin: "25px 0",
                    overflowY: "auto",
                  }}
                >
                  {/* SECTION ONE START */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "25px",
                    }}
                  >
                    <div
                      style={{
                        padding: "2px 10px",
                        backgroundColor: "#1c1c2d",
                        boxShadow: "0px 4px 12px -5px rgb(59, 113, 202)",
                        color: "#ffffff",
                        borderRadius: "17px",
                        fontSize: "26px",
                        fontWeight: "700",
                        alignSelf: "center",
                      }}
                    >
                      {(expiredPage - 1) * itemsPerPage + index + 1}
                    </div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <span
                        className="mb-1"
                        style={{
                          textTransform: "uppercase",
                          textAlign: "end",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "17px",
                            fontWeight: "700",
                          }}
                        >
                          LAST SEARCHED
                        </span>
                        <br />
                        ON{" "}
                        {title_deed.expiry
                          ? DateTime.fromISO(title_deed.expiry, {
                              zone: "UTC",
                            }) // Assuming the database time is in UTC
                              .setZone("Africa/Nairobi") // Set to East African Time Zone (EAT)
                              .toLocaleString(DateTime.DATE_MED)
                              .replace(/\//g, "-") // Replace "/" with "-"
                          : "Date Not Available"}
                        <br />
                        AT{" "}
                        {title_deed.expiry
                          ? DateTime.fromISO(title_deed.expiry, {
                              zone: "UTC",
                            }) // Assuming the database time is in UTC
                              .setZone("Africa/Nairobi") // Set to East African Time Zone (EAT)
                              .toLocaleString(DateTime.TIME_SIMPLE)
                          : "Date Not Available"}
                      </span>
                    </div>
                  </div>
                  {/* SECTION ONE END */}
                  <div className="horizontal_border_mobile"></div>
                  {/* SECTION TWO START */}
                  <div
                    key={title_deed.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "25px",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: "19px",
                          fontWeight: "700",
                        }}
                      >
                        L.R NO:
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#f20000",
                          margin: "0",
                          fontSize: "19px",
                          fontWeight: "700",
                        }}
                      >
                        {title_deed.old_lr_no}
                      </span>
                    </div>
                    <div style={{ height: "10px" }}></div>
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: "19px",
                          fontWeight: "700",
                        }}
                      >
                        NEW TITLE NO:
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#14a44d",
                          margin: "0",
                          fontSize: "19px",
                          fontWeight: "700",
                        }}
                      >
                        {title_deed.new_title_no}
                      </span>
                    </div>
                  </div>
                  {/* SECTION TWO END */}
                  <div className="horizontal_border_mobile"></div>
                  {/* SECTION THREE START */}
                  <div
                    style={{
                      padding: "25px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <MDBBtn
                      className="m-0 repurchase_button"
                      color="success"
                      onClick={() => handleSelectedLRNo(title_deed.old_lr_no)}
                    >
                      SEARCH AGAIN
                    </MDBBtn>
                  </div>
                  {/* SECTION THREE END */}
                </MDBTabsPane>
              </div>
            ))}
          </div>
          {expiredSearchData.length > 3 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderRadius: "17px",
                backgroundColor: "#1c1c2d",
                padding: "15px 15px",
                alignContent: "center",
              }}
            >
              <Typography className="ms-2" style={{ alignSelf: "center" }}>
                <h5 style={{ color: "#ffffff", margin: "0" }}>
                  PAGE: {expiredPage}
                </h5>
              </Typography>
              <Stack spacing={2}>
                <WhitePagination
                  defaultPage={1}
                  count={totalPages} // Use the total number of pages
                  page={expiredPage}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                />
              </Stack>
            </div>
          ) : null}
        </div>
      ) : null}
    </ThemeProvider>
  );
}
