import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import "./css/reset.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth0History";
import App from "./App";
import Maintenance from "./Components/Pages/Maintenance/Maintenance";

import reportWebVitals from "./reportWebVitals";

const root = document.getElementById("root");
const rootElement = (
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
    {/* <Maintenance /> */}
  </React.StrictMode>
);

// Use createRoot instead of ReactDOM.render
if (root?.createRoot) {
  root.createRoot(root).render(rootElement);
} 
else {
  ReactDOM.render(rootElement, root);
}

reportWebVitals();
