import React from "react";
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
  } from "mdb-react-ui-kit";

export default function LrNoInputField({ tempInputValue, handleTempInputChange }) {
  return (
    <>
      {/* L.R No. Input Field Mobile & Tabs Start*/}
      <MDBRow className="hide-desktop">
        <MDBCol lg="7" className="text-center mt-4">
          <h3
            style={{
              margin: "0 0 35px",
              fontWeight: "900",
              fontSize: "33px"
            }}
          >
            SIMPLE SEARCH
            <br />
            <span className="nairobi_badge">NAIROBI COUNTY</span>
          </h3>
          <div style={{ height: "25px" }}></div>
          <MDBValidation>
            <MDBValidationItem>
              <MDBInput
                required
                name="oldLrNo"
                title="oldLrNo"
                placeholder="Example 2023/II/133"
                label="ENTER YOUR LR. NO."
                type="text"
                value={tempInputValue} // Use the temporary state value
                onChange={handleTempInputChange} // Update the temporary state while typing
                style={{ padding: "7px" }}
              />
            </MDBValidationItem>
          </MDBValidation>
        </MDBCol>
      </MDBRow>
      {/* L.R No. Input Field Mobile & Tabs End*/}

      {/* L.R No. Input Field Desktop Start*/}
      <MDBRow className="hide-mobile hide-tabs d-flex justify-content-center align-items-center">
        <MDBCol lg="7" className="hide-mobile hide-tabs text-center mt-4">
          <h3
            style={{
              margin: "0 0 35px",
              fontWeight: "900",
              fontSize: "33px"
            }}
          >
            SIMPLE SEARCH
            <br />
            <span className="nairobi_badge">NAIROBI COUNTY</span>
          </h3>
          <div style={{ height: "30px" }}></div>
          <MDBValidation>
            <MDBValidationItem>
              <MDBInput
                required
                name="oldLrNo"
                label="ENTER YOUR LR. NO."
                type="text"
                value={tempInputValue}
                onChange={handleTempInputChange}
                style={{ padding: "15px", boxShadow: "0 4px 10px -2px #1c1c2b", fontSize: "21px" }}
              />
            </MDBValidationItem>
          </MDBValidation>
          <div style={{ height: "5px" }}></div>
        </MDBCol>
      </MDBRow>
      {/* L.R No. Input Field Desktop End*/}
    </>
  );
}
