import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

export default function ErrorSearch({ tempInputValue }) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      id="error-alert"
      className="mb-4"
      style={{
        display: open ? "flex" : "none",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Alert
        severity="error"
        variant="filled"
        style={{
          zIndex: "999",
          position: "relative",
          top: "0",
          width: "100%",
          borderRadius: "17px",
          fontSize: "19px",
          backgroundColor: "#be1e2d",
          fontFamily: "Poppins"
        }}
        value={open}
        onClose={handleClose}
      >
        <AlertTitle style={{ fontSize: "21px" }}>ERROR</AlertTitle>
        It looks like the LR No. <strong>{tempInputValue}</strong> you're looking has an issue based on the following:<br />
        <ul>
            <li>May not have been published in the Kenyan Gazette meaning it does not exist.</li>
            <li>Is wrongly typed</li>
            <li>Your Session has expired - click on the REFRESH DATA button</li>
        </ul>
        Check and try again
      </Alert>
      {/* <Alert
        variant="filled"
        style={{
          backgroundColor: "#1c1c2d",
          zIndex: "999",
          position: "absolute",
          top: "0",
          marginTop: "200px",
          width: "80%",
        }}
        action={
          <Button color="inherit" size="small">
            CLOSE
          </Button>
        }
      >
        This is a success alert — check it out!
      </Alert> */}
    </div>
  );
}
