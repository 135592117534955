import React from "react";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

function AndroidDownload() {
  return (
    <div className="android-notification" style={{ zIndex: "1000" }}>
        <>
          <h3 className="m-2">How To Download this Web App on your Android Device</h3>
          <div style={{ height: "15px" }}></div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "no-wrap",
                  marginBottom: "5px",
                }}
              >
                <FiberManualRecordIcon />
                <h5 className="ms-2">Google & Google Chrome</h5>
              </div>
              <p style={{ marginLeft: "30px" }}>
                Tap the 3-dotted menu button and then select{" "}
                <strong>Install App</strong>.
              </p>
            </div>
            <div style={{ height: "15px" }}></div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "no-wrap",
                  marginBottom: "5px",
                }}
              >
                <FiberManualRecordIcon />
                <h5 className="ms-2">Samsung</h5>
              </div>
              <p style={{ marginLeft: "30px" }}>
                Tap the hamburger (3 lines) menu button and then select "+ Add
                page to".
              </p>
            </div>
          </div>
        </>
    </div>
  );
}

export default AndroidDownload;
