import React, { useContext } from "react";
import { MDBTabsPane } from "mdb-react-ui-kit";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import VisaLogo from "../../Assets/Icons/visa.png";

import { StateContext } from "../../../../../State";

const WhitePagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: "#ffffff",
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#ffffff",
  },
}));

export default function MobileReceipts() {
  const {
    accessTokenCookie,
    userDataCookie,
    receiptsPage,
    setReceiptsPage,
    receipts,
  } = useContext(StateContext);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: "#ff0000",
      },
    },
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          icon: {
            color: "#ffffff",
          },
          root: {
            "&.Mui-selected": {
              color: "#ff0000",
            },
          },
        },
      },
    },
  });
  const itemsPerPage = 10; // Number of items to display per page

  const totalItems = receipts === undefined ? 0 : receipts?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleChange = (event, value) => {
    // console.log("Changing to page:", value);
    setReceiptsPage(value);
  };

  // Calculate the start and end indexes for the items on the current page
  const startIndex = (receiptsPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Extract the items for the current page
  const itemsOnPage =
    receipts === undefined ? [] : receipts?.slice(startIndex, endIndex);
  // console.log("LOADED RECEIPTS: ", receipts);
  // console.log("RECEIPTS ON PAGE: ", itemsOnPage);

  // Function to convert UTC time to Nairobi time (GMT+3)
  function convertToNairobiTime(utcTime) {
    const nairobiTime = new Date(utcTime);
    nairobiTime.setHours(nairobiTime.getHours() + 3); // Add 3 hours for Nairobi time

    // Format the date and time with "AT" before the time
    const formattedDate = nairobiTime.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const formattedTime = nairobiTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `ON ${formattedDate} AT ${formattedTime}`;
  }

  return (
    <ThemeProvider theme={theme}>
      {accessTokenCookie !== null && userDataCookie !== null ? (
        <div className="hide-desktop hide-tabs">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: "17px",
              backgroundColor: "#1c1c2d",
              padding: "15px 15px",
              alignContent: "center",
            }}
          >
            <Typography className="ms-2" style={{ alignSelf: "center" }}>
              <h5 style={{ color: "#ffffff", margin: "0" }}>
                PAGE: {receiptsPage}
              </h5>
            </Typography>
            <Stack spacing={2}>
              <WhitePagination
                defaultPage={1}
                count={totalPages} // Use the total number of pages
                page={receiptsPage}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
                color="primary"
              />
            </Stack>
          </div>
          <div>
            {itemsOnPage.map((receipt, index) => (
              <MDBTabsPane
                key={receipt.id}
                show
                style={{
                  boxShadow: "0 4px 9px -1px #3b71ca",
                  borderRadius: "17px",
                  display: "flex",
                  flexDirection: "column",
                  margin: "25px 0",
                  overflowY: "auto",
                }}
              >
                {/* SECTION ONE START */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "25px",
                  }}
                >
                  <div
                    className="me-4"
                    style={{
                      padding: "2px 10px",
                      backgroundColor: "#1c1c2d",
                      boxShadow: "0px 4px 12px -5px rgb(59, 113, 202)",
                      color: "#ffffff",
                      borderRadius: "17px",
                      fontSize: "26px",
                      fontWeight: "700",
                      alignSelf: "center",
                    }}
                  >
                    {(receiptsPage - 1) * itemsPerPage + index + 1}
                  </div>
                  <div
                    className="credits_title ms-4"
                    style={{
                      borderRadius: "17px",
                      boxShadow: "0 0 3px #1c1c2d",
                    }}
                  >
                    <div
                      className="credit_icon_wrapper"
                      style={{ padding: "5px" }}
                    >
                      <span
                        className="credits_icon"
                        style={{ padding: "5px 15px" }}
                      >
                        {receipt?.purchased_credits
                          ? receipt?.purchased_credits
                          : 0}
                      </span>
                    </div>
                    <div className="vertical_border_light"></div>
                    <div className="purchased_credits_text">
                      {receipt?.purchased_credits === 1 ? (
                        <h3>CREDIT</h3>
                      ) : (
                        <h3>CREDITS</h3>
                      )}
                      <h3>PURCHASED</h3>
                    </div>
                  </div>
                </div>
                {/* SECTION ONE END */}
                <div className="horizontal_border_mobile"></div>
                {/* SECTION TWO START */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "25px",
                  }}
                >
                  <div>
                    <span>
                      AMOUNT: <strong>KSH. {receipt?.amount / 100}</strong>
                    </span>
                  </div>
                  <div>
                    <span>
                      REFERENCE NO: <strong>{receipt.reference}</strong>
                    </span>
                  </div>
                  <div style={{ height: "10px" }}></div>
                  <div>
                    <span style={{ textTransform: "uppercase" }}>
                      PAID WITH{" "}
                      <strong>
                        {receipt?.card_type ? receipt?.card_type : null}{" "}
                        {receipt?.channel === "mobile_money" ? "MPESA" : null}
                      </strong>
                      <br />
                      <strong>
                        {" "}
                        {receipt.paid_at
                          ? convertToNairobiTime(receipt.paid_at)
                          : "DATE UNAVAILABLE"}
                      </strong>
                    </span>
                  </div>
                </div>
                {/* SECTION TWO END */}
                <div className="horizontal_border_mobile"></div>
                {/* SECTION THREE START */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "25px",
                  }}
                >
                  <span style={{ fontSize: "13px" }}>
                    <a
                      href={`mailto:aliumlawtd@gmail.com?subject=Issue%20with%20reference%20${
                        receipt?.reference || ""
                      }%20paid%20via%20${
                        receipt?.card_type.toUpperCase() || null
                      }on%20${receipt?.paid_at || ""}`}
                    >
                      Report Purchase
                    </a>
                  </span>
                </div>
                {/* SECTION THREE END */}
              </MDBTabsPane>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: "17px",
              backgroundColor: "#1c1c2d",
              padding: "15px 15px",
              alignContent: "center",
            }}
          >
            <Typography className="ms-2" style={{ alignSelf: "center" }}>
              <h5 style={{ color: "#ffffff", margin: "0" }}>
                PAGE: {receiptsPage}
              </h5>
            </Typography>
            <Stack spacing={2}>
              <WhitePagination
                defaultPage={1}
                count={totalPages} // Use the total number of pages
                page={receiptsPage}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
                color="primary"
              />
            </Stack>
          </div>
        </div>
      ) : null}
    </ThemeProvider>
  );
}
