import React, { useState, useEffect, useContext } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import PaystackLogo from "./Assets/Images/paystack logo.webp";

import axios from "axios";

import { StateContext } from "../../../State";

import '../../../analytics';
import ReactGA from 'react-ga';

export default function BuyCredits() {
  const {
    staticModal,
    setStaticModal,
    toggleShow,
    paymentCallback,
    receipts,
    setPaymentError,
    serverUrl,
    availableCredits,
    setAvailableCredits,
    fetchReceipts,
  } = useContext(StateContext);

  // console.log("PAY SERVER URL: ", serverUrl);

  const [isLoading, setIsLoading] = useState(true);

  function getCookie(cookieName) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we're looking for
      if (cookie.startsWith(cookieName + "=")) {
        // Extract and return the cookie value
        return decodeURIComponent(cookie.substring(cookieName.length + 1));
      }
    }
    // If the cookie is not found, return null or an appropriate default value
    return null;
  }

  const userDataCookie = getCookie("user_data"); // User Data Cookie
  const fetchedUserDataCookie = JSON.parse(userDataCookie);
  const email = fetchedUserDataCookie?.email || null;

  const [amount, setAmount] = useState(null);

  const handlePriceChange = (price) => {
    // console.log("PRICE CHANGED! ", price);
    setAmount(price);
  };

  // console.log("CREDITS AVAILABLE NOW: ", availableCredits);

  // Call the function
  // useEffect(() => {
  //   updateCredits();
  // }, []);

  const handleSubmit1Credit = async (event) => {
    event.preventDefault();

    if (userDataCookie != null && amount != null && email != null) {
      try {
        await fetchReceipts();
        const updatedCredits = availableCredits + 1;

        // Make the state update outside the asynchronous block
        setAvailableCredits(updatedCredits);

        const currency = "KES";
        const channels = ["card", "bank", "mobile_money"];
        const callback = `${paymentCallback}`;

        const metadata = {
          metadata: {
            cart_id: 1,
            custom_fields: [
              {
                display_name: "Purchased Credits",
                variable_name: "1 Credit",
                value: 1,
              },
              {
                display_name: "Available Credits",
                variable_name: "Total",
                value: updatedCredits, // Use the updatedCredits here
              },
            ],
          },
        };

        const response = await axios.post(
          `${serverUrl}/paystack/initialize-payment`,
          {
            email,
            amount,
            currency,
            channels,
            callback,
            metadata,
          }
        );
        window.location.href = response.data.data.authorization_url;
      } catch (error) {
        setPaymentError(true);
        console.error("Payment initialization error:", error);
      } finally {
        // Tracks the Simple Search button clicks
        ReactGA.event({
          category: "events",
          action: "click",
          label: "1 Credit Button",
        });
      }
    } else {
      alert("Something must have gone wrong");
    }
  };

  const handleSubmit4Credits = async (event) => {
    event.preventDefault();

    // let amount = setAmount(event.target.value);
    // console.log("SET CREDIT PRICE: \n", amount);

    if (userDataCookie != null && amount != null && email != null) {
      try {
        const updatedCredits = availableCredits + 4;

        // Make the state update outside the asynchronous block
        setAvailableCredits(updatedCredits);

        const currency = "KES";
        const channels = ["card", "bank", "mobile_money"];
        const callback = `${paymentCallback}`;

        const metadata = {
          metadata: {
            cart_id: 4,
            custom_fields: [
              {
                display_name: "Purchased Credits",
                variable_name: "4 Credits",
                value: 4,
              },
              {
                display_name: "Available Credits",
                variable_name: "Total",
                value: updatedCredits, // Use the updatedCredits here
              },
            ],
          },
        };

        const response = await axios.post(
          `${serverUrl}/paystack/initialize-payment`,
          {
            email,
            amount,
            currency,
            channels,
            callback,
            metadata,
          }
        );
        window.location.href = response.data.data.authorization_url;
      } catch (error) {
        setPaymentError(true);
        console.error("Payment initialization error:", error);
      } finally {
        // Tracks the Simple Search button clicks
        ReactGA.event({
          category: "events",
          action: "click",
          label: "4 Credits Button",
        });
      }
    } else {
      alert("Something must have gone wrong");
    }
  };

  const handleSubmit7Credits = async (event) => {
    event.preventDefault();

    // let amount = setAmount(event.target.value);
    // console.log("SET CREDIT PRICE: \n", amount);

    if (userDataCookie != null && amount != null && email != null) {
      try {
        const updatedCredits = availableCredits + 7;

        // Make the state update outside the asynchronous block
        setAvailableCredits(updatedCredits);

        const currency = "KES";
        const channels = ["card", "bank", "mobile_money"];
        const callback = `${paymentCallback}`;

        const metadata = {
          metadata: {
            cart_id: 7,
            custom_fields: [
              {
                display_name: "Purchased Credits",
                variable_name: "7 Credits",
                value: 7,
              },
              {
                display_name: "Available Credits",
                variable_name: "Total",
                value: updatedCredits, // Use the updatedCredits here
              },
            ],
          },
        };

        const response = await axios.post(
          `${serverUrl}/paystack/initialize-payment`,
          {
            email,
            amount,
            currency,
            channels,
            callback,
            metadata,
          }
        );
        window.location.href = response.data.data.authorization_url;
      } catch (error) {
        setPaymentError(true);
        console.error("Payment initialization error:", error);
      } finally {
        // Tracks the Simple Search button clicks
        ReactGA.event({
          category: "events",
          action: "click",
          label: "7 Credits Button",
        });
      }
    } else {
      alert("Something must have gone wrong");
    }
  };

  const handleSubmit10Credits = async (event) => {
    event.preventDefault();

    // let amount = setAmount(event.target.value);
    // console.log("SET CREDIT PRICE: \n", amount);

    if (userDataCookie != null && amount != null && email != null) {
      try {
        const updatedCredits = availableCredits + 10;

        // Make the state update outside the asynchronous block
        setAvailableCredits(updatedCredits);

        const currency = "KES";
        const channels = ["card", "bank", "mobile_money"];
        const callback = `${paymentCallback}`;

        const metadata = {
          metadata: {
            cart_id: 10,
            custom_fields: [
              {
                display_name: "Purchased Credits",
                variable_name: "10 Credits",
                value: 10,
              },
              {
                display_name: "Available Credits",
                variable_name: "Total",
                value: updatedCredits, // Use the updatedCredits here
              },
            ],
          },
        };

        const response = await axios.post(
          `${serverUrl}/paystack/initialize-payment`,
          {
            email,
            amount,
            currency,
            channels,
            callback,
            metadata,
          }
        );
        window.location.href = response.data.data.authorization_url;
      } catch (error) {
        setPaymentError(true);
        console.error("Payment initialization error:", error);
      } finally {
        // Tracks the Simple Search button clicks
        ReactGA.event({
          category: "events",
          action: "click",
          label: "10 Credits Button",
        });
      }
    } else {
      alert("Something must have gone wrong");
    }
  };

  return (
    <>
      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={staticModal}
        setShow={setStaticModal}
      >
        <MDBModalDialog style={{ marginTop: "105px" }}>
          <MDBModalContent>
            <MDBModalHeader className="px-4 pt-4">
              <MDBModalTitle>
                <h1 className="buy_credits_title">BUY CREDIT(S)</h1>
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="px-4 py-4">
              <p className="mb-4">
                Advanced Search is here to help you make the best of the Title
                Deed Conversion Process.
                <br className="hide-tabs hide-mobile" />
                <div className="hide-desktop" style={{ height: "15px" }}></div>
                Select the number of credit(s) needed and checkout with your
                preferred method.
                <br />
                <div style={{ height: "15px" }}></div>
                <strong>
                  <em>
                    Purchasing credits means that you understand our{" "}
                    <a
                      href="https://aliumlaw.com/privacy-policy/"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    .
                  </em>
                </strong>
              </p>
              <div>
                <h6><strong>ANY ISSUE? CONTACT SUPPORT</strong></h6>
                <div style={{ height: "5px" }}></div>
                <h6>Email: <a href={`mailto:titlechecker@aliumlaw.com`}>titlechecker@aliumlaw.com</a></h6>
                <div style={{ height: "2.5px" }}></div>
                <h6>We are swift and shall get back to you.</h6>
              </div>
              <div style={{ height: "25px" }}></div>
              <h5
                className="mb-2"
                style={{ color: "#b20000", fontWeight: "900" }}
              >
                CHECKOUT METHODS
              </h5>
              <ul>
                <li>Mobile Money - MPESA (enter pin prompt)</li>
                <li>Card - Visa, Mastercard & AMEX</li>
              </ul>
              <div
                className="mt-1 mb-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div className="mx-2" style={{ alignSelf: "center" }}>
                  <h6 className="m-0 mt-1" style={{ fontSize: "17px" }}>
                    SECURED BY
                  </h6>
                </div>
                <div>
                  <img
                    style={{ height: "20px", width: "auto" }}
                    src={PaystackLogo}
                    alt="Paystack logo"
                  />
                </div>
              </div>
              <div className="horizontal_border"></div>
              <h5
                className="mb-4 mt-0"
                style={{ color: "#b20000", fontWeight: "900" }}
              >
                CHOOSE THE NUMBER OF CREDIT(S)
              </h5>
              <div className="tablet_credits_cards_wrapper">
                <div
                  className="hide-desktop hide-tabs"
                  style={{ height: "15px" }}
                ></div>
                {/* Buy +1 Credit Start */}
                <MDBCard className="side_margin side_margin w-100 box_shadow tablet_wrap">
                  <MDBCardBody
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <MDBCardTitle>
                        <div className="credits_title">
                          <span
                            className="credits_icon credits_icon_mobile"
                            style={{
                              padding: "5px 15px",
                            }}
                          >
                            +1
                          </span>
                          <div className="vertical_border_light hide-desktop hide-tabs"></div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              padding: "0px 15px",
                            }}
                          >
                            <h3 className="credits_title_buy_credits">
                              Credit
                            </h3>
                          </div>
                        </div>
                        <div className="mt-3">
                          <h6 style={{ fontSize: "21px", fontWeight: "900" }}>
                            PRICE: KSH.100
                          </h6>
                        </div>
                      </MDBCardTitle>
                      <MDBCardText>
                        Want to perform only <strong>1 Advanced Search</strong>{" "}
                        or simply test the new feature?
                      </MDBCardText>
                    </div>
                    <div>
                      <form onSubmit={handleSubmit1Credit}>
                        <MDBBtn
                          onClick={() => handlePriceChange("10000")}
                          className="buy_credits_button mt-3"
                        >
                          BUY 1 CREDIT
                        </MDBBtn>
                      </form>
                    </div>
                  </MDBCardBody>
                </MDBCard>
                {/* Buy +1 Credit End */}
                <div
                  className="hide-desktop hide-tabs"
                  style={{ height: "25px" }}
                ></div>
                {/* Buy +4 Credit Start */}
                <MDBCard className="side_margin w-100 box_shadow tablet_wrap">
                  <MDBCardBody
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <MDBCardTitle>
                      <div className="credits_title">
                        <div style={{ display: "flex" }}>
                          <span
                            className="credits_icon credits_icon_mobile"
                            style={{
                              padding: "5px 15px",
                            }}
                          >
                            +4
                          </span>
                          <div className="vertical_border_light hide-desktop hide-tabs"></div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              padding: "0px 15px",
                            }}
                          >
                            <h3 className="credits_title_buy_credits">
                              Credits
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <h6 style={{ fontSize: "21px", fontWeight: "900" }}>
                          PRICE: KSH.90 EACH
                        </h6>
                      </div>
                    </MDBCardTitle>
                    <MDBCardText>
                      <strong>4 Advanced Searches</strong> are better than 1 with this pack.
                    </MDBCardText>
                    <form onSubmit={handleSubmit4Credits}>
                      <MDBBtn
                        className="buy_credits_button"
                        onClick={() => handlePriceChange("36000")}
                        // disabled="true"
                      >
                        BUY 4 CREDITS
                      </MDBBtn>
                    </form>
                  </MDBCardBody>
                </MDBCard>
                {/* Buy +4 Credit End */}
                <div
                  className="hide-desktop hide-tabs"
                  style={{ height: "25px" }}
                ></div>
                {/* Buy +7 Credit Start */}
                <MDBCard className="side_margin w-100 box_shadow tablet_wrap">
                  <MDBCardBody
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <MDBCardTitle>
                      <div className="credits_title">
                        <div style={{ display: "flex" }}>
                          <span
                            className="credits_icon credits_icon_mobile"
                            style={{
                              padding: "5px 15px",
                            }}
                          >
                            +7
                          </span>
                          <div className="vertical_border_light hide-desktop hide-tabs"></div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              padding: "0px 15px",
                            }}
                          >
                            <h3 className="credits_title_buy_credits">
                              Credits
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <h6 style={{ fontSize: "21px", fontWeight: "900" }}>
                          PRICE: KSH.80 EACH
                        </h6>
                      </div>
                    </MDBCardTitle>
                    <MDBCardText>
                      Expand Title Search efficiency with upto <strong>7 Advanced Searches</strong>.
                    </MDBCardText>
                    <form onSubmit={handleSubmit7Credits}>
                      <MDBBtn
                        className="buy_credits_button"
                        onClick={() => handlePriceChange("56000")}
                        // disabled="true"
                      >
                        BUY 7 CREDITS
                      </MDBBtn>
                    </form>
                  </MDBCardBody>
                </MDBCard>
                {/* Buy +7 Credit End */}
                <div
                  className="hide-desktop hide-tabs"
                  style={{ height: "25px" }}
                ></div>
                {/* Buy +10 Credit Start */}
                <MDBCard className="side_margin w-100 box_shadow tablet_wrap">
                  <MDBCardBody
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <MDBCardTitle>
                      <div className="credits_title">
                        <div style={{ display: "flex" }}>
                          <span
                            className="credits_icon credits_icon_mobile"
                            style={{
                              padding: "5px 15px",
                            }}
                          >
                            +10
                          </span>
                          <div className="vertical_border_light hide-desktop hide-tabs"></div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              padding: "0px 15px",
                            }}
                          >
                            <h3 className="credits_title_buy_credits">
                              Credits
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <h6 style={{ fontSize: "21px", fontWeight: "900" }}>
                          PRICE: KSH.60 EACH
                        </h6>
                      </div>
                    </MDBCardTitle>
                    <MDBCardText>
                      Go economical with <strong>10 Advanced Searches</strong>.
                    </MDBCardText>
                    <form onSubmit={handleSubmit10Credits}>
                      <MDBBtn
                        className="buy_credits_button"
                        onClick={() => handlePriceChange("60000")}
                        // disabled="true"
                      >
                        BUY 10 CREDITS
                      </MDBBtn>
                    </form>
                  </MDBCardBody>
                </MDBCard>
                {/* Buy +10 Credit End */}
              </div>
              {/* Grouped Credits End */}
              <div className="horizontal_border mb-1"></div>
              <div
                className="mt-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div className="mx-2" style={{ alignSelf: "center" }}>
                  <h6 className="m-0" style={{ fontSize: "19px" }}>
                    SECURED BY
                  </h6>
                </div>
                <div>
                  <img
                    style={{ height: "25px", width: "auto" }}
                    src={PaystackLogo}
                    alt="Paystack logo"
                  />
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="close_button"
                color="secondary"
                onClick={toggleShow}
              >
                Close
              </MDBBtn>
              <MDBBtn
                className="cta-button"
                href="https://4egs0m6ybvf.typeform.com/to/HKwgvx2A?typeform-source=email-button#product_id=xxxxx"
                target="_blank"
                rel="noreferrer"
              >
                GET HELP
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
