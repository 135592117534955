import React, { useContext, useEffect } from "react";
import { MDBBtn, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { DateTime } from "luxon";
import { StateContext } from "../../../../../State";
import { useAccessToken } from "../../../../../AccessToken";

export default function TabAdvancedSearchHero({
  inputStyles,
  accessTokenCookie,
  userDataCookie,
  fetchedUserDataCookie,
  getTodaysDate,
  convertToNairobiTime,
  handleSimpleSearchClick,
  handleAdvancedSearchClick,
}) {
  // const { fetchData } = useAccessToken();

  const {
    toggleAdvancedSearchUpdate,
    tempInputValue,
    handleTempInputChange,
    toggleShow,
    receipts,
    isReceiptsLoading,
    handleLogin,
    refreshData,
  } = useContext(StateContext);

  return (
    <>
      {/* ========================== *** HERO SECTION START *** ========================== */}
      <div className="hide-desktop hide-mobile">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#1c1c2b",
            padding: "30px 0",
          }}
          className="advanced_page_hero_background_image"
        >
          {/* ============= Credit Details Section Start ============= */}
          <>
            {accessTokenCookie !== null && userDataCookie !== null ? (
              // DASHBOARD SECTION START
              <div className="dashboard_wrapper">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      padding: "25px",
                      boxShadow: "0 3px 9px -3px #1c1c2b",
                      flexGrow: "1",
                    }}
                  >
                    <div className="user_details_initials">
                      <h1>
                        {fetchedUserDataCookie?.given_name
                          ? fetchedUserDataCookie?.given_name.charAt(0)
                          : ""}
                      </h1>
                      <h1>
                        {fetchedUserDataCookie?.family_name
                          ? fetchedUserDataCookie?.family_name.charAt(0)
                          : ""}
                      </h1>
                    </div>
                  </div>
                  <div className="vertical_border"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "25px",
                      flexGrow: "3",
                    }}
                  >
                    <div className="user_details_name_and_email">
                      <div className="user_details_bordered_first_name">
                        <h1 style={{ color: "#1c1c2b" }}>Hi&#44;</h1>
                        <h1 style={{ color: "#1c1c2b" }}>
                          {fetchedUserDataCookie?.given_name
                            ? fetchedUserDataCookie?.given_name
                            : "USER"}
                        </h1>
                      </div>
                      <div
                        className="credits_figures mt-2"
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <h3
                          style={{
                            textTransform: "uppercase",
                            color: "#be1e2d",
                            fontSize: "13px",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          Today's Date:
                        </h3>
                        <h3
                          style={{
                            fontWeight: "600",
                            fontSize: "24px",
                            textDecoration: "underline",
                          }}
                        >
                          {getTodaysDate()}
                        </h3>
                      </div>
                      <div className="user_details_email mt-3">
                        <h3
                          style={{
                            textTransform: "uppercase",
                            color: "#be1e2d",
                            fontSize: "13px",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          Email Address
                        </h3>
                        <h3
                          style={{
                            color: "#1c1c2b",
                            fontSize: "13px",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          {fetchedUserDataCookie?.email}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab_hero_horizontal_border"></div>
                <div className="mobile_hero_horizontal_border"></div>
                <div className="bottom_dashboard">
                  {/* Tablet Section Start */}
                  {/* Tablet Last Credits Bought Section Start */}
                  <div
                    className="hide-mobile"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: "25px",
                    }}
                  >
                    <div className="credits_figures">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="last_purchase_title">
                          <div className="credit_icon_wrapper">
                            {isReceiptsLoading ? (
                              <span
                                style={{
                                  padding: "5px 15px 0"
                                }}
                              >
                                <lord-icon
                                  src="https://cdn.lordicon.com/jeycryzx.json"
                                  trigger="loop"
                                  stroke="bold"
                                  colors="primary:#b20000,secondary:#1c1c2d"
                                  style={{ width: "25px", height: "25px" }}
                                ></lord-icon>
                              </span>
                            ) : (
                              <span
                                className="credits_icon"
                                style={{
                                  padding: "5px 15px",
                                }}
                              >
                                {receipts && receipts.length > 0
                                  ? receipts[0]?.purchased_credits
                                  : 0}
                              </span>
                            )}
                          </div>
                          <div className="vertical_border"></div>
                          <div
                            style={{
                              padding: "5px 15px",
                            }}
                          >
                            <div>
                              <h3>LAST CREDITS BOUGHT:</h3>
                              <h3>
                                {isReceiptsLoading ? (
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "900",
                                      letterSpacing: "0.1rem",
                                      textDecoration: "none",
                                    }}
                                  >
                                    ...
                                  </span>
                                ) : (
                                  <span
                                    className="mx-1"
                                    style={{
                                      textDecoration: "none",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {receipts && receipts.length > 0
                                      ? convertToNairobiTime(
                                          receipts[0]?.paid_at || "NO DATA"
                                        )
                                      : "NO DATA"}
                                  </span>
                                )}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div>
                          <MDBBtn
                            className="login_button mt-3"
                            color="success"
                            style={{
                              fontSize: "16px",
                              width: "100%",
                              height: "max-content",
                            }}
                            onClick={refreshData}
                          >
                            REFRESH DATA
                          </MDBBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Tablet Last Credits Bought Section End */}

                  <div className="vertical_border hide-mobile"></div>

                  {/* Tablet Credits Available Section Start */}
                  <div
                    className="hide-mobile"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: "25px",
                    }}
                  >
                    <div className="credits_title">
                      {isReceiptsLoading ? (
                        <span
                          style={{
                            padding: "5px 15px"
                          }}
                        >
                          <lord-icon
                            src="https://cdn.lordicon.com/jeycryzx.json"
                            trigger="loop"
                            stroke="bold"
                            colors="primary:#b20000,secondary:#1c1c2d"
                            style={{ width: "25px", height: "25px" }}
                          ></lord-icon>
                        </span>
                      ) : (
                        <span
                          className="credits_icon"
                          style={{ padding: "5px 15px" }}
                        >
                          {receipts && receipts.length > 0
                            ? receipts[0]?.available_credits
                            : 0}
                        </span>
                      )}
                      <div style={{ padding: "5px 15px" }}>
                        <h3>Credits</h3>
                        <h3>Available</h3>
                      </div>
                    </div>
                    <div>
                      <MDBBtn
                        className="login_button mt-3"
                        color="success"
                        style={{
                          fontSize: "16px",
                          width: "100%",
                          height: "max-content",
                        }}
                        onClick={toggleShow}
                      >
                        BUY CREDIT(S)
                      </MDBBtn>
                    </div>
                  </div>
                  {/* Tablet Credits Available Section Start */}
                  {/* Tablet Section End */}
                </div>
              </div>
            ) : (
              // DASHBOARD SECTION END
              <div
                style={{
                  margin: "25px 50px 0",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 0 3px #1c1c2b",
                  borderRadius: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "15px",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "30px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h2
                      style={{
                        margin: "0",
                        color: "#1c1c2b",
                        fontSize: "15px",
                        fontWeight: "900",
                        alignSelf: "center",
                      }}
                    >
                      <strong>
                        THE MOST EFFICIENT WAY TO CHECK WHETHER YOUR TITLE DEED
                        IS EARMARKED FOR PLACEMENT IN SIMPLE STEPS.
                      </strong>
                    </h2>
                  </div>
                  {/* <div>
                    <MDBBtn
                      className="login_button"
                      style={{
                        fontSize: "16px",
                        margin: "0 30px",
                        width: "max-content",
                      }}
                      disabled="true"
                    >
                      SIGN IN
                    </MDBBtn>
                  </div> */}
                </div>
              </div>
            )}
          </>
          {/* ============= Credit Details Section End ============= */}

          {/* ============= Advanced Search Input Section Start ============= */}
          <div className="hero_wrapper">
            <div className="hero_h1 mb-2">
              <h1>TITLE DEED</h1>
              <h1>CONVERSION CHECKER</h1>
              <h1>ADVANCED SEARCH</h1>
              <div style={{ height: "10px" }}></div>
              <span className="nairobi_badge_advanced_search">
                NAIROBI COUNTY
              </span>
            </div>
            {/* <span className="nairobi_badge mb-4">NAIROBI COUNTY</span> */}

            {/* Advanced Search L.R. NO. Input Field Tablet Start*/}
            <MDBRow className="hide-mobile d-flex justify-content-start align-items-center">
              <MDBCol lg="10" className="hide-mobile text-left mt-4">
                <input
                  className="mb-4"
                  name="advanced-search-input"
                  title="LR No.Input"
                  type="text"
                  style={inputStyles}
                  placeholder="ENTER YOUR TITLE DEED LR. NO."
                  label="ENTER YOUR LR. NO."
                  value={tempInputValue}
                  onChange={handleTempInputChange}
                />
              </MDBCol>
            </MDBRow>
            {/* Advanced Search L.R. NO. Input Field Tablet End*/}

            {/* Submit Button Tablet Start*/}
            <MDBBtn
              color="success"
              className="mb-4 mt-0 custom-button_advanced_search"
              size="lg"
              style={{ fontSize: "16px" }}
              type="button"
              onClick={handleAdvancedSearchClick} // Use the new click handler
            >
              Advanced Search
            </MDBBtn>
            <MDBBtn
              color="success"
              className="hide-mobile mx-2 mb-4 mt-0 custom-button_advanced_search"
              size="lg"
              style={{ fontSize: "16px" }}
              type="button"
              onClick={handleSimpleSearchClick} // Use the new click handler
            >
              Simple Search
            </MDBBtn>
            <br />
            {accessTokenCookie !== null && userDataCookie !== null ? (
              <div>
                <MDBBtn
                  className="mb-4 mt-0 how_to_use_btn"
                  onClick={toggleAdvancedSearchUpdate}
                >
                  HOW TO USE
                </MDBBtn>
              </div>
            ) : (
              <div>
                <MDBBtn
                  className="mb-4 mt-0 view_pdf_button"
                  color="success"
                  style={{
                    fontSize: "16px",
                    padding: "15px 40px",
                  }}
                  type="button"
                  onClick={handleLogin}
                >
                  SIGN IN TO START
                </MDBBtn>
              </div>
            )}

            {/* Submit Button Tablet End*/}
          </div>
          {/* ============= Advanced Search Input Section Start ============= */}
        </div>
      </div>
      {/* ========================== *** HERO SECTION END *** ========================== */}
    </>
  );
}
