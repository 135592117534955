import React, { useContext } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import ErrorIcon from "../../../Assets/Icons/error.webp";
import NoDataIcon from "../../../Assets/Icons/error-light.gif";
import LoadIcon from "../../../Assets/Icons/simple_loader.gif";
import { StateContext } from "../../../../State";

export default function DesktopTable() {
  const { simpleSearchData, tempInputValue, isLoading, setIsLoading, isError } =
    useContext(StateContext);

  if (isLoading) {
    // Simulate a delay of 10 seconds before showing the content
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return (
      <>
        <div
          className="hide-tabs hide-mobile"
          style={{
            border: "1px solid #1c1c2d",
            borderRadius: "17px",
            boxShadow: "0 0 13px #1c1c2d",
            backgroundColor: "#1c1c2d",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "15px 25px",
              }}
            >
              <img
                src={LoadIcon}
                alt="Loading Animated Icon"
                style={{ width: "100px", height: "100px", textShadow: "0 0 5px #ffffff" }}
              />
            </div>
            <div className="vertical_border_loader"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignContent: "flex-start",
                padding: "0 25px",
                fontSize: "23px",
              }}
            >
              <h5
                className="hide-mobile"
                style={{
                  color: "#ffffff",
                  fontWeight: "900",
                  letterSpacing: "0.01em",
                  wordSpacing: "0.27em",
                  margin: "0"
                }}
              >
                ADVANCED SEARCH GIVES A BETTER EXPERIENCE
              </h5>
              <h5
                className="hide-mobile"
                style={{
                  color: "#ffffff",
                  fontWeight: "900",
                  letterSpacing: "0.01em",
                  wordSpacing: "0.27em",
                  margin: "0",
                  alignSelf: "flex-start"
                }}
              >
                GET STARTED TODAY
              </h5>
            </div>
          </div>
        </div>
        <div
          className="hide-desktop hide-mobile"
          style={{
            border: "1px solid #1c1c2d",
            borderRadius: "17px",
            boxShadow: "0 0 13px #1c1c2d",
            backgroundColor: "#1c1c2d",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "15px 25px",
              }}
            >
              <img
                src={LoadIcon}
                alt="Loading Animated Icon"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div className="vertical_border_loader"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                padding: "0 25px",
                fontSize: "23px",
              }}
            >
              <h5
                className="hide-mobile"
                style={{
                  color: "#ffffff",
                  fontWeight: "900",
                  letterSpacing: "0.01em",
                  wordSpacing: "0.27em",
                  margin: "0"
                }}
              >
                ADVANCED SEARCH GIVES A BETTER EXPERIENCE
              </h5>
              <h5
                className="hide-mobile"
                style={{
                  color: "#ffffff",
                  fontWeight: "900",
                  letterSpacing: "0.01em",
                  wordSpacing: "0.27em",
                  margin: "0"
                }}
              >
                GET STARTED TODAY
              </h5>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (isError) {
    return (
      <div
        className="hide-mobile hide-tabs"
        style={{
          textAlign: "center",
          padding: "25px 0",
          boxShadow: "0 0 3px #1c1c2b",
          borderRadius: "17px",
        }}
      >
        <div style={{ textTransform: "uppercase" }}>
          <img
            src={ErrorIcon}
            alt="Loading Animated Icon"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>No Simple Search Data found</h5>
        </div>
        <p style={{ textTransform: "uppercase" }}>
          EITHER<div style={{ height: "5px" }}></div>
          <strong>
            <em>Your property is not listed in the Kenya Gazette.</em>
          </strong>
          <div style={{ height: "5px" }}></div>
          OR<div style={{ height: "5px" }}></div>
          <strong>
            <em>Your input is wrong.</em>
          </strong>
          <br />
          <br />
          <div className="horizontal_border_simple_search"></div>
          Follow the <strong>STEPS</strong> above or{" "}
          <a
            href="https://4egs0m6ybvf.typeform.com/to/HKwgvx2A?typeform-source=email-button#product_id=xxxxx"
            target="_blank"
          >
            GET ADVICE
          </a>
        </p>
      </div>
    );
  }

  // console.log(simpleSearchData);
  return (
    <div
      className="hide-mobile hide-tabs box_shadow_light"
      style={{
        padding: "25px 0 0",
        borderRadius: "17px",
        marginTop: "0"
      }}
    >
      {/* Desktop Heading */}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ marginBottom: "25px" }}
      >
        <h3 className="text-center hide-mobile hide-tabs" style={{ fontWeight: "600" }}>
          RESULTS
        </h3>
      </div>

      {/* Desktop Table */}
      <MDBTable
        style={{ display: "inline-table", width: "100%" }}
        className="hide-mobile hide-tabs"
      >
        <MDBTableHead>
          {simpleSearchData.length > 0 ? (
            <tr style={{ backgroundColor: "#1c1c2d" }}>
              <th
                scope="col"
                style={{
                  fontWeight: "600",
                  fontSize: "23px",
                  color: "#ffffff",
                }}
              >
                OLD L.R. NUMBER
              </th>
              <th
                scope="col"
                style={{
                  fontWeight: "600",
                  fontSize: "23px",
                  color: "#ffffff",
                }}
              >
                NEW TITLE NUMBER
              </th>
            </tr>
          ) : null}
        </MDBTableHead>
        <MDBTableBody style={{ marginBottom: "25px" }}>
          {simpleSearchData.length > 0 && tempInputValue.length > 0 ? (
            simpleSearchData.map((title_deed) => (
              <>
                <tr key={title_deed.id} style={{ boxShadow: "0 3px 9px -3px #1c1c2b" }}>
                  <td style={{ width: "50%", }}>
                    <p
                      className="fw-normal mb-1"
                      style={{ color: "#f20000", fontSize: "19px" }}
                    >
                      <strong>L.R NO. {title_deed.old_title_no}</strong>
                    </p>
                  </td>
                  <td style={{ width: "50%", }}>
                    <p
                      className="fw-normal mb-1"
                      style={{ color: "#14a44d", fontSize: "19px" }}
                    >
                      <strong>{title_deed.new_title_no}</strong>
                    </p>
                  </td>
                </tr>
                <tr key={title_deed.id} style={{ boxShadow: "0 3px 9px -3px #1c1c2b" }}>
                  <td style={{ width: "50%" }}>
                    <p
                      className="fw-normal mb-1"
                      style={{ color: "#000000", fontSize: "21px" }}
                    >
                      <strong>{title_deed.area} AREA</strong>
                    </p>
                  </td>
                  <td style={{ width: "50%" }}>
                    <p
                      className="fw-normal mb-1"
                      style={{ color: "#000000", fontSize: "21px" }}
                    >
                      <strong>{title_deed.size_ha} HECTARES</strong>
                    </p>
                  </td>
                </tr>
                <div style={{ height: "50px" }}></div>
              </>
            ))
          ) : (
            <>
              <tr style={{ backgroundColor: "#1c1c2d" }}>
                <td
                  colSpan={2}
                  style={{ textAlign: "center", textTransform: "uppercase" }}
                >
                  <img
                    src={NoDataIcon}
                    alt="Loading Animated Icon"
                    style={{ width: "100px", height: "100px" }}
                  />{" "}
                  <h5 style={{ color: "#ffffff" }}>
                    No Data available
                    <br />
                    Enter the <strong>L.R. No.</strong> above to search
                  </h5>
                </td>
              </tr>
              <div style={{ height: "75px" }}></div>
            </>
          )}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
}
