import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";

import { DateTime } from "luxon";

export default function TabActiveExpanded({
  old_lr_no,
  new_title_no,
  gazette_volume,
  published_date,
  gazette_notice_no,
  area,
  size,
  gazette_page,
  pdf_page,
  pdf_link,
  title_deed,
}) {
  return (
    <>
      <div
        style={{
          height: "1px",
          backgroundColor: "#1C1C2B",
        }}
      ></div>
      <div style={{ fontWeight: "600", padding: "0 30px", margin: "20px 0" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <h5
              className="mb-4"
              style={{
                width: "auto",
                backgroundColor: "#1c1c2d",
                color: "#ffffff",
                border: "1px solid #1c1c2d",
                borderRadius: "17px",
                padding: "5px 25px",
                fontWeight: "600",
              }}
            >
              MORE DETAILS
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid #F6F5F0",
                borderRadius: "17px",
                padding: "20px 0px",
                alignItems: "flex-start",
                backgroundColor: "rgba(246, 245, 240, 0.5",
                boxShadow: "0 0 5px #7B7A78",
                maxWidth: "492px",
                width: "392px",
              }}
            >
              <div
                style={{
                  padding: "0 25px",
                }}
              >
                <h4
                  style={{
                    color: "#1c1c2b",
                    fontWeight: "700",
                    margin: "0",
                  }}
                >
                  OLD LR. NO:
                  <br />
                  <span style={{ color: "#f20000" }}>{old_lr_no}</span>
                </h4>
              </div>
              <div className="horizontal_border_active_expanded_tabs"></div>
              <div
                style={{
                  padding: "0 25px",
                }}
              >
                <h4
                  style={{
                    color: "#1c1c2b",
                    fontWeight: "700",
                    margin: "0",
                  }}
                >
                  NEW GEN TITLE:
                  <br />
                  <span style={{ color: "#14a44d" }}>{new_title_no}</span>
                </h4>
              </div>{" "}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                >
                  <div style={{ textAlign: "end" }}>
                    <span>
                      EXPIRES AT{" "}
                      {title_deed.expiry
                        ? DateTime.fromISO(title_deed.expiry, {
                            zone: "UTC",
                          }) // Assuming the database time is in UTC
                            .setZone("Africa/Nairobi") // Set to East African Time Zone (EAT)
                            .toLocaleString(DateTime.TIME_SIMPLE)
                        : "Date Not Available"}
                    </span>
                    <br />
                    <span style={{ textTransform: "uppercase" }}>
                      ON{" "}
                      {title_deed.expiry
                        ? DateTime.fromISO(title_deed.expiry, {
                            zone: "UTC",
                          }) // Assuming the database time is in UTC
                            .setZone("Africa/Nairobi") // Set to East African Time Zone (EAT)
                            .toLocaleString(DateTime.DATE_MED)
                            .replace(/\//g, "-") // Replace "/" with "-"
                        : "Date Not Available"}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <MDBBtn
                      href={title_deed.pdf_link}
                      target="_blank"
                      className="view_pdf_button mt-2"
                      color="success"
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      GAZETTE
                    </MDBBtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #1C1C2B",
                  borderRadius: "17px",
                  padding: "20px 0",
                  alignItems: "flex-start",
                  backgroundColor: "#1F192B",
                  boxShadow: "0 0 7px #1C1C2B",
                  maxWidth: "420px",
                  width: "392px",
                }}
              >
                <div
                  style={{
                    padding: "0 25px",
                  }}
                >
                  <h4
                    style={{
                      color: "#ffffff",
                      textTransform: "uppercase",
                      fontWeight: "600",
                    }}
                  >
                    GAZETTE
                    <br />
                    {gazette_volume}
                  </h4>
                </div>
                <div className="horizontal_border_active_expanded_tabs_white"></div>
                <div
                  style={{
                    padding: "0 25px",
                  }}
                >
                  <h4
                    style={{
                      color: "#ffffff",
                      textTransform: "uppercase",
                      fontWeight: "600",
                    }}
                  >
                    PUBLISHED
                    <br />
                    ON {published_date}
                  </h4>
                </div>
                <div className="horizontal_border_active_expanded_tabs_white"></div>
                <div
                  style={{
                    padding: "0 25px",
                  }}
                >
                  <h4 style={{ color: "#ffffff", fontWeight: "600" }}>
                    GAZETTE NOTICE
                    <br />
                    NO. {gazette_notice_no}
                  </h4>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <h5
                  style={{
                    color: "1c1c2b",
                    fontWeight: "600",
                    textAlign: "end",
                  }}
                >
                  GAZETTE PAGE {gazette_page}
                </h5>
                <h5
                  style={{
                    color: "1c1c2b",
                    fontWeight: "600",
                    textAlign: "end",
                  }}
                >
                  PDF PAGE {pdf_page}
                </h5>
              </div>
            </div>
          </div>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "25px",
              border: "1px solid #1C1C2B",
              borderRadius: "17px",
              padding: "20px 0",
              alignItems: "flex-start",
              backgroundColor: "#1F192B",
              boxShadow: "0 0 7px #1C1C2B",
              maxWidth: "420px",
              width: "392px",
            }}
          >
            <div
              style={{
                padding: "0 25px",
              }}
            >
              <h4 style={{ color: "#ffffff", fontWeight: "600" }}>
                LOCATION: {area}
              </h4>
            </div>
            <div className="horizontal_border_active_expanded_tabs_white"></div>
            <div
              style={{
                padding: "0 25px",
              }}
            >
              <h4 style={{ color: "#ffffff", fontWeight: "600" }}>
                SIZE: {size} HECTARES
              </h4>
            </div>
          </div>
        </div>
        {/* GAZETTE NOTICE & PAGE NUMBER START */}
        {/* GAZETTE NOTICE & PAGE NUMBER END */}
      </div>
    </>
  );
}
