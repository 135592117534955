import React, { useContext } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { StateContext } from "../../../../State";

export default function SuccessCredits() {
  const {
    payIsVerified,
    setPayIsVerified,
    payementError,
    setPaymentError,
    payVerificationError,
    setPayVerificationError,
    receipts,
  } = useContext(StateContext);

  const handleClose = () => {
    setPayIsVerified(false);
    setPaymentError(false);
    setPayVerificationError(false);
  };

  if (payementError) {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          left: "0",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Alert
          variant="filled"
          style={{
            boxShadow: "0 0 7px #1c1c2d",
            zIndex: "999",
            marginTop: "128px",
            width: "80%",
            fontWeight: "700",
            fontSize: "19px",
            backgroundColor: "#b20000",
            color: "#ffffff",
            padding: "25px 50px",
          }}
          value={payementError}
          onClose={handleClose}
        >
          Sorry, your payment has failed. Please try again or contact support{" "}
          <Button href="#payment-support" color="inherit" size="small">
            CLOSE
          </Button>
        </Alert>
      </div>
    );
  }

  if (payVerificationError) {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          left: "0",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Alert
          variant="filled"
          style={{
            boxShadow: "0 0 7px #1c1c2d",
            zIndex: "999",
            position: "absolute",
            top: "0",
            marginTop: "128px",
            width: "80%",
            fontWeight: "700",
            fontSize: "19px",
            backgroundColor: "#b20000",
            color: "#ffffff",
            padding: "25px 50px",
          }}
          value={payVerificationError}
          onClose={handleClose}
        >
          Oops! Something went wrong and your payment has not been verified.
          Please contact support{" "}
          <Button href="#payment-support" color="inherit" size="small">
            CLOSE
          </Button>
        </Alert>
      </div>
    );
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        left: "0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        zIndex: "999",
      }}
    >
      <Alert
        className="box_shadow_dark border_radius"
        variant="filled"
        style={{
          zIndex: "999",
          position: "absolute",
          top: "0",
          marginTop: "110px",
          width: "80%",
          fontWeight: "500",
          fontSize: "14px",
          backgroundColor: "rgba(20,164,77,0.97)",
          color: "#ffffff",
          textShadow: "0 0 2px #1c1c2d",
          padding: "25px 15px",
        }}
        value={payIsVerified}
        onClose={handleClose}
      >
        <>
          Successfully purchased{" "}
          <strong>
            {receipts && receipts.length > 0
              ? receipts[0]?.purchased_credits
              : 0}{" "}
            Credit
          </strong>{" "}
          at{" "}
          <strong>
            Ksh.{receipts && receipts.length > 0 ? (receipts[0]?.amount / 100) : 0}
          </strong>
          . You are eligible to use Advanced Search.
        </>
      </Alert>
    </div>
  );
}
