import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { MDBRow } from "mdb-react-ui-kit";

export default function Intro() {
  const [show, setShow] = useState(false);

  //   useEffect(() => {
  //     // Changing the state after 10 sec
  //     // from the time when the component
  //     // is rendered
  //     setTimeout(() => {
  //       setShow(true);
  //     }, 7000);
  //   }, []);

  if (show) {
    return (
      <>
        {/* Mobile Alert */}
        <Alert
          className="hide-desktop p-4 mt-2"
          variant="light"
          style={{
            textAlign: "center",
            marginBottom: "50px",
            color: "#1c1c2d",
          }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Alert.Heading style={{ fontWeight: "600" }}>
            WHY REPLACE?
          </Alert.Heading>
          <MDBRow className="hide-desktop pb-2">
            <p className="mt-3 mb-4 pb-md-0 mb-md-5 px-md-2">
              Title Deeds issued under the old land laws are being
              systematically cancelled and replaced with Title Deeds under the
              new law.
              <br />
              <br />
              Take the necessary steps to replace your old Title Deed with the
              new generation Title Deed and avoid barriers to future land
              transactions.
              <br />
              <br />
              <strong>
                Find out whether your Title Deed is ready for replacement with
                our FREE app.
              </strong>
            </p>
          </MDBRow>
          <Alert.Heading style={{ fontWeight: "600", textAlign: "center" }}>
            PLEASE NOTE
          </Alert.Heading>
          <p>
            Although every effort has been made to ensure that the information
            provided in this checker is accurate and up to date, this checker
            should be used as a guide – your property may need additional
            searches or require more extensive advice.
          </p>
        </Alert>

        {/* Desktop Alert */}
        <Alert
          className="hide-mobile p-4 mt-3"
          variant="light"
          style={{
            color: "#1c1c2d",
          }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Alert.Heading style={{ fontWeight: "600" }}>
            WHY REPLACE?
          </Alert.Heading>
          <MDBRow className="hide-mobile d-flex justify-content-center align-items-center pt-0 mb-2">
            <div className="hide-mobile">
              <p className="mt-4 mb-4">
                Title Deeds issued under the old land laws are being
                systematically cancelled and replaced with Title Deeds under the
                new law. Take the necessary steps to replace your old Title Deed
                with the new generation Title Deed and avoid barriers to future
                land transactions.
                <br />
                <br />
                <strong>
                  Find out whether your Title Deed is ready for replacement with
                  our FREE app.
                </strong>
              </p>
            </div>
          </MDBRow>
          <Alert.Heading style={{ fontWeight: "600" }}>
            PLEASE NOTE
          </Alert.Heading>
          <p>
            Although every effort has been made to ensure that the information
            provided in this checker is accurate and up to date, this checker
            should be used as a guide – your property may need additional
            searches or require more extensive advice.
          </p>
        </Alert>
      </>
    );
  }
  return (
    <>
      {/* Mobile Button */}
      <div
        className="hide-desktop hide-tabs"
        style={{
          display: "flex",
          margin: "10px 0 50px",
        }}
      >
        <Button
          className="how_to_use_btn"
          onClick={() => setShow(true)}
          style={{
            margin: "0 auto",
            border: "none",
            fontWeight: "700",
          }}
        >
          WHY REPLACE?
        </Button>
      </div>

      {/* Desktop Button */}
      <Button
        className="hide-mobile how_to_use_btn"
        onClick={() => setShow(true)}
        style={{
          border: "none",
          backgroundColor: "#ffffff",
          color: "#b20000",
          margin: "15px 0 25px",
          fontWeight: "700",
          fontSize: "16px",
        }}
      >
        WHY REPLACE?
      </Button>
    </>
  );
}
