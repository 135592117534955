import React, { useContext } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import SimpleSearchResultsWrapper from "../Pages/SimpleSearch/Results/SimpleSearchResultsWrapper";
import SimpleSearch from "../Pages/SimpleSearch/SimpleSearch";
import CTA from "../Alerts/CTA";

function Container() {
  return (
    <MDBContainer>
      <MDBCard className="text-black m-4" style={{ borderRadius: "25px" }}>
        <MDBCardBody className="p-4 border_radius box_shadow_light">
          {/* Instructions and Input Section Start */}
          <MDBRow>
            <MDBCol>
              <SimpleSearch />
            </MDBCol>
          </MDBRow>
          {/* Instructions and Input Section End */}

          {/* Results Display Section Start */}
          <MDBRow style={{ margin: "3px 0 0" }}>
            <MDBCol>
              <SimpleSearchResultsWrapper />
            </MDBCol>
          </MDBRow>
          {/* Results Display Section End */}

          {/* Call To Action Section - Get Advice Start */}
          <MDBRow>
            <MDBCol lg="12">
              <CTA />
            </MDBCol>
          </MDBRow>
          {/* Call To Action Section - Get Advice End */}
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default Container;
