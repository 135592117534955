import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";

import { DateTime } from "luxon";

export default function MobileActiveExpanded({
  old_lr_no,
  new_title_no,
  gazette_volume,
  published_date,
  gazette_notice_no,
  area,
  size,
  gazette_page,
  pdf_page,
  pdf_link,
  title_deed,
}) {
  return (
    <div>
      <div
        style={{
          height: "1px",
          backgroundColor: "#1C1C2B",
        }}
      ></div>
      <div
        style={{
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* MORE DETAILS START */}
          <h5
            className="my-4"
            style={{
              width: "100%",
              backgroundColor: "#1c1c2d",
              color: "#ffffff",
              border: "1px solid #1c1c2d",
              borderRadius: "17px",
              padding: "5px 25px",
              fontWeight: "600",
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            MORE DETAILS
          </h5>
          {/* MORE DETAILS END */}

          {/* LR NO & NEW TITLE SECTION START */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              boxShadow: "0 4px 7px -1px #3b71ca",
              borderRadius: "17px",
            }}
          >
            <div
              style={{
                padding: "25px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <h4
                className="me-2"
                style={{
                  color: "#000000",
                  fontWeight: "700",
                }}
              >
                L.R NO:
              </h4>{" "}
              <h4
                style={{
                  color: "#f20000",
                  margin: "0",
                  fontWeight: "700",
                }}
              >
                {title_deed.old_lr_no}
              </h4>
            </div>
            <div className="horizontal_border_mobile"></div>
            <div
              style={{
                padding: "25px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <h4
                className="me-2"
                style={{
                  color: "#000000",
                  fontWeight: "700",
                }}
              >
                NEW TITLE NO:
              </h4>{" "}
              <h4
                style={{
                  color: "#14a44d",
                  margin: "0",
                  fontWeight: "700",
                }}
              >
                {title_deed.new_title_no}
              </h4>
            </div>
          </div>
          {/* LR NO & NEW TITLE SECTION END */}
          <div style={{ height: "25px" }}></div>
          {/* GAZETTE DETAILS SECTION START */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              boxShadow: "0 2px 2px -1px #3b71ca",
              borderRadius: "17px",
              backgroundColor: "#1c1c2d",
            }}
          >
            <div
              style={{
                padding: "25px",
              }}
            >
              <h4
                style={{
                  color: "#ffffff",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                GAZETTE {gazette_volume}
              </h4>
            </div>
            <div className="horizontal_border_active_expanded_white"></div>
            <div
              style={{
                padding: "25px",
              }}
            >
              <h4
                style={{
                  color: "#ffffff",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                PUBLISHED ON {published_date}
              </h4>
            </div>
            <div className="horizontal_border_active_expanded_white"></div>
            <div
              style={{
                padding: "25px",
              }}
            >
              <h4 style={{ color: "#ffffff", fontWeight: "600" }}>
                GAZETTE NOTICE NO. {gazette_notice_no}
              </h4>
            </div>
          </div>
          {/* GAZETTE DETAILS SECTION END */}
          <div style={{ height: "25px" }}></div>
          {/* LOCATION & SIZE SECTION START */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              boxShadow: "0 2px 2px -1px #3b71ca",
              borderRadius: "17px",
              backgroundColor: "#1c1c2d",
            }}
          >
            <div
              style={{
                padding: "25px",
              }}
            >
              <h4
                style={{
                  color: "#ffffff",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                LOCATION: {area}
              </h4>
            </div>
            <div className="horizontal_border_active_expanded_white"></div>
            <div
              style={{
                padding: "25px",
              }}
            >
              <h4
                style={{
                  color: "#ffffff",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                SIZE: {size} HECTARES
              </h4>
            </div>
          </div>
          {/* LOCATION & SIZE SECTION END */}
          <div style={{ height: "30px" }}></div>
        </div>
      </div>
    </div>
  );
}
