import React from "react";
import { Link } from "react-router-dom";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";

export default function BottomTabSection({
  accessTokenCookie,
  userDataCookie,
  basicActive,
  handleBasicClick,
  receipts,
  isReceiptsLoading
}) {
  return (
    <MDBTabs
      className={
        accessTokenCookie != null && userDataCookie != null
          ? "mt-3 mb-4 mx-4 hide-mobile hide-desktop menu_tab"
          : "mt-4 mb-4 mx-4 hide-mobile hide-desktop menu_tab"
      }
    >
      {accessTokenCookie != null && userDataCookie != null ? (
        <>
          <MDBTabsItem>
            <Link to="/advanced-search/receipts">
              <MDBTabsLink
                onClick={() => handleBasicClick("receipts")}
                active={basicActive === "receipts"}
              >
                Credit Receipts{" "}
                <span className="total_notification">{receipts?.length}</span>
              </MDBTabsLink>
            </Link>
          </MDBTabsItem>
        </>
      ) : (
        <>
          <MDBTabsItem>
            <Link to="/advanced-search/how-to-use">
              <MDBTabsLink
                onClick={() => handleBasicClick("how-to-use")}
                active={basicActive === "how-to-use"}
              >
                How To Use
              </MDBTabsLink>
            </Link>
          </MDBTabsItem>
        </>
      )}
    </MDBTabs>
  );
}
