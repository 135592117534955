import React, { useState, useEffect, useContext } from "react";
// import { MDBTabsPane, MDBBtn } from "mdb-react-ui-kit";
import LoadIcon from "../../../Assets/Icons/loading.gif";
import ExpiredSearchPagination from "../Pagination/ExpiredSearchPagination";
import NoExpiredData from "../Alerts/NoExpiredData";

import TabExpired from "./Tablet/TabExpired";
import MobileExpired from "./Mobile/MobileExpired";

import { StateContext } from "../../../../State";

export default function Expired() {
  const {
    isExpiredLoading,
    handleBasicClick,
    expiredSearchData,
    accessTokenCookie,
    userDataCookie,
  } = useContext(StateContext);

  const [activeKey, setActiveKey] = useState(0);

  const handleSelect = (key) => {
    // Update the active tab when a new tab is selected
    setActiveKey(key);
  };

  useEffect(() => {
    // Check if the page is being reloaded
    const isReloading = sessionStorage.getItem("isReloading");
    if (isReloading) {
      // If reloading, retrieve the last selected tab from storage
      const lastSelectedTab = localStorage.getItem("lastSelectedTab");
      if (lastSelectedTab !== null) {
        setActiveKey(parseInt(lastSelectedTab, 10));
        handleBasicClick(lastSelectedTab);
      }
      // Clear the reloading flag
      sessionStorage.removeItem("isReloading");
    }
  }, []);

  return (
    <>
      {/* MOBILE SECTION START */}
      <div className="hide-desktop hide-tabs">
        {isExpiredLoading ? (
          <div
            style={{
              border: "1px solid #1c1c2d",
              borderRadius: "17px",
              boxShadow: "0 4px 10px -1px #1c1c2b!important",
              padding: "15px 25px",
            }}
          >
            <h5
              className="m-0"
              style={{
                fontWeight: "900",
                letterSpacing: "0.01em",
                wordSpacing: "0.27em",
              }}
            >
              <img
                src={LoadIcon}
                alt="Loading Animated Icon"
                style={{ width: "100px", height: "100px" }}
              />{" "}
              LOADING...
              <br />
              PLEASE WAIT
            </h5>
          </div>
        ) : accessTokenCookie !== null &&
          userDataCookie !== null &&
          expiredSearchData.length > 0 ? (
          <>
            <MobileExpired />
          </>
        ) : (
          <NoExpiredData />
        )}
      </div>
      {/* MOBILE SECTION END */}

      {/* DESKTOP & TABLET SECTION START */}
      <div className="hide-mobile">
        {isExpiredLoading ? (
          <div
            style={{
              border: "1px solid #1c1c2d",
              borderRadius: "17px",
              boxShadow: "0 4px 10px -1px #1c1c2b",
              padding: "15px 25px",
            }}
          >
            <h5
              className="m-0"
              style={{
                fontWeight: "900",
                letterSpacing: "0.01em",
                wordSpacing: "0.27em",
              }}
            >
              <img
                src={LoadIcon}
                alt="Loading Animated Icon"
                style={{ width: "100px", height: "100px" }}
              />{" "}
              LOADING...AVOID LAND TRANSACTIONAL BARRIERS
            </h5>
          </div>
        ) : accessTokenCookie !== null &&
          userDataCookie !== null &&
          expiredSearchData.length > 0 ? (
          <>
            <ExpiredSearchPagination
              activeKey={activeKey}
              onSelect={handleSelect}
            />
            <TabExpired />
          </>
        ) : (
          <NoExpiredData />
        )}
      </div>
      {/* DESKTOP & TABLET SECTION END */}
    </>
  );
}
