import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import footerBadge from './Assets/Images/KD Logo Badge.png'

export default function Footer() {
  return (
    <MDBFooter style={{ backgroundColor: '#1C1C2B' }} className='text-center text-lg-left'>
        <div style={{ height: '5px', minWidth: '100%', backgroundColor: '#be1e2d' }}></div>
        <div 
            className='text-center pt-4 p-1 text-white' 
            style={{ fontSize: '12px', letterSpacing: '0.17em' }}
        >
            <p>&copy; {new Date().getFullYear()} COPYRIGHT{' '}
                <a href='https://aliumlaw.com/' target='_blank' style={{ color: '#e24857' }}>
                ALIUM LAW
                </a>
            </p>
            <span 
                style={{ 
                    marginTop: '-15px', 
                    display: 'flex', 
                    justifyContent: 'center' 
                }}
            >
                <p style={{ paddingTop: '1.5px', paddingRight: '5.5px' }}>
                    WEB APP BY{' '}   
                </p> 
                <a href='https://www.linkedin.com/in/karl-full-stack-dev/' target='_blank'>
                {/* <span style={{ color: '#0095f9' }}>K</span>D */}
                <p style={{ color: '#0095F9' }}>KDEVELOPER</p>
                </a>
            </span>
        </div>
    </MDBFooter>
  );
}