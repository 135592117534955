import React, { useContext } from "react";
import Alert from "@mui/material/Alert";
import { StateContext } from "../../../../State";

export default function SuccessSearch({ tempInputValue }) {
  const {
    successNotification,
    setSuccessNotification,
    successResearch,
    setSuccessResearch,
  } = useContext(StateContext);
  const handleClose = () => {
    setSuccessNotification(false);
    setSuccessResearch(false);
  };
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        left: "0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        zIndex: "999",
      }}
    >
      <Alert
        variant="filled"
        className="box_shadow_dark border_radius"
        style={{
          zIndex: "999",
          position: "absolute",
          top: "0",
          marginTop: "110px",
          width: "90%",
          fontWeight: "500",
          fontSize: "14px",
          backgroundColor: "#ffffff",
          color: "#1c1c2d",
          padding: "25px 15px",
        }}
        value={successNotification}
        onClose={handleClose}
      >
        {successResearch ? (
          <>
            Successfully searched for <strong>LR NO</strong>{" "}
            <span style={{ color: "rgb(242, 0, 0)" }}>{tempInputValue}</span>{" "}
            again. Please wait and check the Active Tab.
          </>
        ) : (
          <>
            Successfully searched for <strong>LR NO</strong>{" "}
            <span style={{ color: "rgb(242, 0, 0)" }}>{tempInputValue}</span>.
            Please wait for data to load in the Active Tab.
          </>
        )}
      </Alert>
      {/* <Alert
        variant="filled"
        style={{
          backgroundColor: "#1c1c2d",
          zIndex: "999",
          position: "absolute",
          top: "0",
          marginTop: "200px",
          width: "80%",
        }}
        action={
          <Button color="inherit" size="small">
            CLOSE
          </Button>
        }
      >
        This is a success alert — check it out!
      </Alert> */}
    </div>
  );
}
