import React, { useState, useEffect, useContext } from "react";
import ReceiptsPagination from "../Pagination/ReceiptsPagination";
import LoadIcon from "../../../Assets/Icons/loading.gif";

import TabReceipts from "./Tablet/TabReceipts";
import MobileReceipts from "./Mobile/MobileReceipts";

import { StateContext } from "../../../../State";

export default function Receipts() {
  const {
    receipts,
    isReceiptsLoading,
    handleBasicClick,
    verifyPayment,
    accessTokenCookie,
    userDataCookie,
  } = useContext(StateContext);

  const [activeKey, setActiveKey] = useState(0);

  const handleSelect = (key) => {
    // Update the active tab when a new tab is selected
    setActiveKey(key);
  };

  useEffect(() => {
    // Check if the page is being reloaded
    const isReloading = sessionStorage.getItem("isReloading");
    if (isReloading) {
      // If reloading, retrieve the last selected tab from storage
      const lastSelectedTab = localStorage.getItem("lastSelectedTab");
      if (lastSelectedTab !== null) {
        setActiveKey(parseInt(lastSelectedTab, 10));
        handleBasicClick(lastSelectedTab);
      }
      // Clear the reloading flag
      sessionStorage.removeItem("isReloading");
    }
  }, []);

  return (
    <div>
      {isReceiptsLoading ? (
        <div
          style={{
            border: "1px solid #1c1c2d",
            borderRadius: "17px",
            boxShadow: "0 0 13px #1c1c2d",
            padding: "15px 25px",
          }}
        >
          <h5
            className="hide-mobile m-0"
            style={{
              fontWeight: "900",
              letterSpacing: "0.01em",
              wordSpacing: "0.27em",
            }}
          >
            <img
              src={LoadIcon}
              alt="Loading Animated Icon"
              style={{ width: "100px", height: "100px" }}
            />{" "}
            SORTING YOUR RECEIPT(S)...
          </h5>
        </div>
      ) : (
        <>
          {(receipts && receipts.length === 0) || receipts === undefined ? (
            <div
              style={{
                boxShadow: "0 0 13px #1c1c2b",
                borderRadius: "17px",
                padding: "20px 30px",
                display: "flex",
                flexDirection: "column",
                margin: "25px 0",
                backgroundColor: "rgba(178,0,0, 0.87)",
              }}
            >
              <h2
                style={{
                  margin: "0",
                  color: "#ffffff",
                  fontSize: "17px",
                  fontWeight: "300",
                  alignSelf: "center",
                }}
              >
                  There are no receipts to display.{" "}
                  <a
                    style={{
                      color: "#1c1c2d",
                      fontWeight: "900",
                      textShadow: "0 0 3px #ffffff",
                    }}
                    href="#purchase-credits"
                  >
                    Purchase Credits
                  </a>{" "}
                  to use Advanced Search.
              </h2>
            </div>
          ) : accessTokenCookie !== null && userDataCookie !== null ? (
            <>
              <ReceiptsPagination
                activeKey={activeKey}
                onSelect={handleSelect}
              />
              <TabReceipts />
              <MobileReceipts />
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
