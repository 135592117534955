import React, { Fragment, useRef, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import AppBar from "./Components/AppBar";
import Footer from "./Components/Footer";
import Home from "./Components/Home/Home";
import AdvancedSearch from "./Components/Pages/AdvancedSearch/AdvancedSearch";
import Receipts from "./Components/Pages/AdvancedSearch/Tabs/Receipts";
import Active from "./Components/Pages/AdvancedSearch/Tabs/Active";
import Expired from "./Components/Pages/AdvancedSearch/Tabs/Expired";
import HowToUse from "./Components/Pages/AdvancedSearch/Tabs/HowToUse";

import MobileSnack from "./Components/Toasts/MobileSnack";
import AndroidDownload from "./Components/Toasts/AndroidDownload";

import { v4 as uuidv4 } from "uuid";
import { generateCodeVerifier, generateCodeChallenge } from "./Utils/pkceUtils";

import { StateProvider } from "./State";
import { AccessTokenProvider } from "./AccessToken";

import './analytics';
import ReactGA from 'react-ga';

function App() {

  // Google Analytics Page View Tracker Start
  useEffect(() => {
    if (window.location.pathname === '/') {
      ReactGA.event({
        category: 'Page View',
        action: 'Simple Page Viewed',
      });
    } else {
      ReactGA.event({
        category: 'Page View',
        action: 'Advanced Page Viewed',
      });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  // Google Analytics Page View Tracker End

  // Page reloader tracker start
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("isReloading", "true");
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        sessionStorage.removeItem("isReloading");
      });
    };
  }, []);
  // Page reloader tracker end

  // Create a temporary state to store the input value as you type
  const [tempInputValue, setTempInputValue] = useState(() => {
    const storedValue = localStorage.getItem('lr_no_input');
    return storedValue || ''; // Use an empty string if no value is found in local storage
  });

  let [advancedSearchData, setAdvancedSearchData] = useState([]);
  const [isAdvancedError, setIsAdvancedError] = useState(false);
  const [isAdvancedLoading, setIsAdvancedLoading] = useState(true);

  // Global Access Token from Express Code Exchange
  let [globalAccessToken, setGlobalAccessToken] = useState({});
  // console.log("GLOBAL ACCESS TOKEN: ", globalAccessToken);

  // ADVANCED SEARCH LOCAL STORAGE KEY
  const ADVANCED_SEARCH_STORAGE_KEY = "advancedSearchDataArray";

  // Global Parsed User Data not stored in cookie
  const [authUserData, setAuthUserData] = useState({});
  const [parsedUserData, setParsedUserData] = useState(null);

  // Function to get a specific cookie by name
  function getCookie(cookieName) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]?.trim(); // Use optional chaining to handle null/undefined
      // Check if this cookie is the one we're looking for
      if (cookie && cookie.startsWith(cookieName + "=")) {
        // Extract and return the cookie value
        return decodeURIComponent(cookie.substring(cookieName.length + 1));
      }
    }
    // If the cookie is not found, return null or an appropriate default value
    return null;
  }

  // Global variable to store the access token
  const accessTokenCookie = getCookie("access_token"); // Access Token Cookie
  const refreshTokenCookie = getCookie("refresh_token"); // Refresh Token Cookie
  const userDataCookie = getCookie("user_data"); // User Data Cookie
  const fetchedUserDataCookie = JSON.parse(userDataCookie);
  const email = fetchedUserDataCookie?.email || null;

  // useEffect(() => {
  //   // Parse JSON data from User Data Cookie
  //   if (accessTokenCookie != null && userDataCookie != null) {
  //     // Parse the JSON data from the cookie
  //     const userData = JSON.parse(userDataCookie);
  //     setParsedUserData(userData);
  //     console.log("PARSED USER DATA: ", userData);
  //   } else {
  //     // Handle the case where the cookie doesn't exist or is empty
  //     console.log("No user data found in the cookie.");
  //   }
  // }, [accessTokenCookie, userDataCookie]);

  // ==================== APP STATE GENERATION START ==================== //

  // Check if stateValue and codeVerifier exist in localStorage
  const storedStateValue = localStorage.getItem("stateValue");
  const storedCodeVerifier = localStorage.getItem("codeVerifier");
  const storedCodeChallenge = localStorage.getItem("codeChallenge");

  const generatedStateValue = useRef(uuidv4()); // Generate the state value once
  // console.log(
  //   "=== APP STATE ON FIRST RENDER ONLY === \n",
  //   generatedStateValue.current
  // );

  const generatedCodeVerifier = useRef(generateCodeVerifier());
  // console.log(
  //   "=== CODE VERIFIER ON FIRST RENDER ONLY === \n",
  //   generatedCodeVerifier.current
  // );

  useEffect(() => {
    // Generates a random & unique App State if not found to be in local storage
    if (storedStateValue === null) {
      // Generate a new stateValue
      const newStateValue = generatedStateValue.current;
      localStorage.setItem("stateValue", newStateValue);
    }

    // Generates a random & unique Code Verifier if not found to be in local storage
    if (storedCodeVerifier === null) {
      // Store the codeVerifier in localStorage
      const newCodeVerifier = generatedCodeVerifier.current;
      localStorage.setItem("codeVerifier", newCodeVerifier);
    }

    // Generates a random & unique Code Verifier if not found to be in local storage
    if (storedCodeChallenge === null) {
      const challenge = generateCodeChallenge(generatedCodeVerifier.current);
      localStorage.setItem("codeChallenge", challenge);
      // console.log("=== CODE CHALLENGE ON FIRST RENDER ONLY === \n", challenge);
    }

    // async function generateAndStoreCodeChallenge() {
    //   if (storedCodeChallenge === null) {
    //     try {
    //       const challenge = await generateCodeChallenge(generatedCodeVerifier.current);
    //       localStorage.setItem("codeChallenge", challenge);
    //       console.log(
    //         "=== CODE CHALLENGE ON FIRST RENDER ONLY === \n",
    //         challenge
    //       );
    //     } catch (error) {
    //       console.error("Error generating code challenge:", error);
    //     }
    //   }
    // }

    // if (storedCodeVerifier) {
    //   generateAndStoreCodeChallenge();
    // }
  }, [storedStateValue, storedCodeChallenge, storedCodeVerifier]);

  // ===================== APP STATE GENERATION END ===================== //

  // STORE ADVANCED SEARCH DATA IN LOCAL STORAGE START
  useEffect(() => {
    // Retrieve data from localStorage with lazy initialization for advancedSearchDataArray
    const savedAdvancedSearchDataArray =
      JSON.parse(localStorage.getItem(ADVANCED_SEARCH_STORAGE_KEY)) || [];

    // Ensure that retrieved data is an array
    if (Array.isArray(savedAdvancedSearchDataArray)) {
      setAdvancedSearchData(savedAdvancedSearchDataArray);
    }
  }, []);

  // Sets a user's LR NO input into local storage
  useEffect(() => {
    if (tempInputValue.length > 0) {
      // Set the value in localStorage whenever tempInputValue changes
      localStorage.setItem("lr_no_input", tempInputValue.toUpperCase());
    } else {
      localStorage.setItem("lr_no_input", "");
    }
  }, [tempInputValue]);

  return (
    <AccessTokenProvider
      storedStateValue={storedStateValue}
      setGlobalAccessToken={setGlobalAccessToken}
      accessTokenCookie={accessTokenCookie}
      authUserData={authUserData}
      setAuthUserData={setAuthUserData}
      userDataCookie={userDataCookie}
      parsedUserData={parsedUserData}
      setParsedUserData={setParsedUserData}
      globalAccessToken={globalAccessToken}
      tempInputValue={tempInputValue}
      setAdvancedSearchData={setAdvancedSearchData}
      setIsAdvancedError={setIsAdvancedError}
      setIsAdvancedLoading={setIsAdvancedLoading}
      storedCodeVerifier={storedCodeVerifier}
    >
      <StateProvider
        email={email}
        accessTokenCookie={accessTokenCookie}
        refreshTokenCookie={refreshTokenCookie}
        userDataCookie={userDataCookie}
        authUserData={authUserData}
        fetchedUserDataCookie={fetchedUserDataCookie}
        tempInputValue={tempInputValue}
        setTempInputValue={setTempInputValue}
        advancedSearchData={advancedSearchData}
        setAdvancedSearchData={setAdvancedSearchData}
        isAdvancedError={isAdvancedError}
        isAdvancedLoading={isAdvancedLoading}
        setIsAdvancedError={setIsAdvancedError}
        setIsAdvancedLoading={setIsAdvancedLoading}
        generatedStateValue={generatedStateValue.current}
        storedStateValue={storedStateValue}
        storedCodeVerifier={storedCodeVerifier}
        storedCodeChallenge={storedCodeChallenge}
      >
        <Fragment>
          {/* <NavBar
            storedStateValue={storedStateValue}
            accessTokenCookie={accessTokenCookie}
            userDataCookie={userDataCookie}
          /> */}
          <AppBar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            {/* Pass the Routes information to the AdvancedSearch component */}
            <Route
              path="/advanced-search"
              element={
                <AdvancedSearch
                  accessTokenCookie={accessTokenCookie}
                  userDataCookie={userDataCookie}
                  email={email}
                  storedStateValue={storedStateValue}
                  storedCodeVerifier={storedCodeVerifier}
                  storedCodeChallenge={storedCodeChallenge}
                />
              }
            >
              {/* Nested routes for tabs */}
              <Route
                path="active"
                element={
                  <Active
                    tempInputValue={tempInputValue}
                    advancedSearchData={advancedSearchData}
                    accessTokenCookie={accessTokenCookie}
                    userDataCookie={userDataCookie}
                    setAdvancedSearchData={setAdvancedSearchData}
                    isAdvancedLoading={isAdvancedLoading}
                    setIsAdvancedLoading={setIsAdvancedLoading}
                    isAdvancedError={isAdvancedError}
                    setIsAdvancedError={setIsAdvancedError}
                  />
                }
              />
              <Route
                path="expired"
                element={
                  <Expired
                    accessTokenCookie={accessTokenCookie}
                    userDataCookie={userDataCookie}
                    advancedSearchData={advancedSearchData}
                  />
                }
              />
              <Route
                path="receipts"
                element={
                  <Receipts
                    accessTokenCookie={accessTokenCookie}
                    email={email}
                  />
                }
              />
              <Route path="how-to-use" element={<HowToUse />} />
            </Route>
          </Routes>
          <Footer />
        </Fragment>
      </StateProvider>
    </AccessTokenProvider>
  );
}

export default App;
