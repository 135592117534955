import React from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

export default function SearchInstructions() {
  return (
    <>
      {/* STEPS INSTRUCTIONS SECTION START */}
      {/* Desktop Heading*/}
      <MDBRow className="hide-mobile hide-tabs">
        <MDBCol>
          <h3
            className="m-3 text-center"
            style={{
              padding: "0 15px",
              marginTop: "-30px",
              fontWeight: "900",
            }}
          >
            STEPS
          </h3>
        </MDBCol>
      </MDBRow>

      {/* Mobile Heading */}
      <MDBRow className="hide-desktop">
        <MDBCol>
          <h3
            className="text-center"
            style={{
              fontWeight: "900",
            }}
          >
            STEPS
          </h3>
        </MDBCol>
      </MDBRow>

      {/* User Instructions Section Start */}
      <MDBRow
        style={{
          padding: "0 10px",
          display: "flex",
        }}
      >
        <MDBCol
          lg="4"
          style={{
            margin: "0 auto 10px",
            padding: "10px",
          }}
        >
          {/* Mobile Start*/}
          <div className="card hide-desktop text-center box_shadow_light border_radius">
            <div
              className="card-body"
              style={{ padding: "25px", fontSize: "21px" }}
            >
              <h2 className="card-title">
                <strong>1</strong>
              </h2>
              <p className="card-text">
                Check your existing LR Number from the Title Deed.
              </p>
            </div>
          </div>
          {/* Mobile End*/}
          {/* Desktop Start*/}
          <div
            className="card hide-mobile hide-tabs box_shadow_light border_radius"
            style={{
              width: "auto",
              height: "270px",
            }}
          >
            <div
              className="card-body"
              style={{
                padding: "25px",
                fontSize: "21px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2 className="card-title">
                <strong>1</strong>
              </h2>
              <p className="card-text">
                Check your existing LR Number from the Title Deed.
              </p>
            </div>
          </div>
          {/* Desktop End*/}
        </MDBCol>
        <MDBCol
          lg="4"
          style={{
            margin: "0 auto 10px",
            padding: "10px",
          }}
        >
          {/* Mobile Start */}
          <div
            className="card hide-desktop text-center box_shadow_light border_radius"
            style={{ width: "auto" }}
          >
            <div
              className="card-body"
              style={{ padding: "25px", fontSize: "21px" }}
            >
              <h2 className="card-title">
                <strong>2</strong>
              </h2>
              <p className="card-text">Enter the LR Number below to search.</p>
            </div>
          </div>
          {/* Mobile Start */}
          {/* Desktop Start */}
          <div
            className="card hide-mobile hide-tabs text-center box_shadow_light border_radius"
            style={{
              width: "auto",
              height: "270px",
            }}
          >
            <div
              className="card-body"
              style={{
                padding: "25px",
                fontSize: "21px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2 className="card-title">
                <strong>2</strong>
              </h2>
              <p className="card-text">Enter the LR Number below to search.</p>
            </div>
          </div>
          {/* Desktop Start */}
        </MDBCol>
        <MDBCol
          lg="4"
          style={{
            margin: "0 auto 10px",
            padding: "10px",
          }}
        >
          {/* Mobile Start*/}
          <div
            className="card hide-desktop text-center box_shadow_light border_radius"
            style={{
              width: "auto",
            }}
          >
            <div
              className="card-body"
              style={{ padding: "25px", fontSize: "21px" }}
            >
              <h2 className="card-title">
                <strong>3</strong>
              </h2>
              <p className="card-text">
                See details of your new generation Title Deed under Results.
              </p>
            </div>
          </div>
          {/* Mobile End*/}
          {/* Desktop Start*/}
          <div
            className="card hide-mobile hide-tabs box_shadow_light border_radius"
            style={{
              width: "auto",
              height: "270px",
            }}
          >
            <div
              className="card-body"
              style={{
                padding: "25px",
                fontSize: "21px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2 className="card-title">
                <strong>3</strong>
              </h2>
              <p className="card-text">
                See details of your new generation Title Deed under Results.
              </p>
            </div>
          </div>
          {/* Desktop End*/}
        </MDBCol>
      </MDBRow>
      {/* STEPS INSTRUCTIONS SECTION END */}
    </>
  );
}
