import React from "react";
import { Link } from "react-router-dom";
import { MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";

export default function TopTabSection({
  accessTokenCookie,
  userDataCookie,
  basicActive,
  handleBasicClick,
  activeSearchData,
  expiredSearchData,
}) {
  return (
    <MDBTabs className="mt-4 mx-2 hide-mobile hide-desktop menu_tab">
      {accessTokenCookie != null && userDataCookie != null ? (
        <>
          <MDBTabsItem>
            <Link to="/advanced-search/active">
              <MDBTabsLink
                onClick={() => handleBasicClick("active")}
                active={basicActive === "active"}
              >
                Active Searches{" "}
                <span className="total_notification">
                  {activeSearchData?.length}
                </span>
              </MDBTabsLink>
            </Link>
          </MDBTabsItem>
          <MDBTabsItem>
            <Link to="/advanced-search/expired">
              <MDBTabsLink
                onClick={() => handleBasicClick("expired")}
                active={basicActive === "expired"}
              >
                Expired Searches{" "}
                <span className="total_notification">
                  {expiredSearchData?.length}
                </span>
              </MDBTabsLink>
            </Link>
          </MDBTabsItem>
        </>
      ) : null}
    </MDBTabs>
  );
}
