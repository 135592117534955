import React, { useEffect, useState, useContext } from "react";
import { MDBTabsPane, MDBBtn } from "mdb-react-ui-kit";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import ActiveExpanded from "../../Tabs/ActiveExpanded";
import TabActiveExpanded from "./TabActiveExpanded";

import OpenIcon from "../../../../Assets/Icons/open.webp";
import CloseIcon from "../../../../Assets/Icons/close.svg";

import { DateTime } from "luxon";

import { StateContext } from "../../../../../State";

const WhitePagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: "#ffffff",
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#ffffff",
  },
}));

export default function TabActive() {
  const { activePage, setActivePage, activeSearchData, } =
    useContext(StateContext);

  // useEffect(() => {
  //   getSetData();
  // }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
      secondary: {
        main: "#ff0000",
      },
    },
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          icon: {
            color: "#ffffff",
          },
          root: {
            "&.Mui-selected": {
              color: "#ff0000",
            },
          },
        },
      },
    },
  });

  const itemsPerPage = 10; // Number of items to display per page

  const totalItems = activeSearchData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleChange = (event, value) => {
    // console.log("Changing to page:", value);
    setActivePage(value);
  };

  // Calculate the start and end indexes for the items on the current page
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Extract the items for the current page
  const itemsOnPage = activeSearchData.slice(startIndex, endIndex);
  // console.log("ITEMS ON PAGE: ", itemsOnPage);

  // Parse the JSON string into an object
  // const fetchedUserDataCookie = JSON.parse(userDataCookie);
  // const email = fetchedUserDataCookie?.email || null;

  const [isExpanded, setIsExpanded] = useState(false);

  const [expandedItems, setExpandedItems] = useState([]);

  const toggleExpanded = (itemLrNo) => {
    if (expandedItems.includes(itemLrNo)) {
      setExpandedItems(
        expandedItems.filter((old_lr_no) => old_lr_no !== itemLrNo)
      );
    } else {
      setExpandedItems([...expandedItems, itemLrNo]);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="hide-desktop hide-mobile">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: "17px",
            backgroundColor: "#1c1c2d",
            padding: "15px 15px",
            alignContent: "center",
          }}
        >
          <Typography className="ms-2" style={{ alignSelf: "center" }}>
            <h5 style={{ color: "#ffffff", margin: "0" }}>PAGE: {activePage}</h5>
          </Typography>
          <Stack spacing={2}>
            <WhitePagination
              defaultPage={1}
              count={totalPages}
              page={activePage}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          </Stack>
        </div>
        <div>
          {/* Render your data based on the `data` state */}
          {itemsOnPage.map((title_deed, index) => (
            <div className="search_results_wrapper">
              <MDBTabsPane
                show
                style={{
                  boxShadow: "0 0 5px #1c1c2b",
                  borderRadius: "17px",
                  display: "flex",
                  flexDirection: "column",
                  margin: "25px 0",
                  overflowY: "auto",
                }}
                className="inner_search_results_wrapper"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    padding: "0 30px",
                  }}
                >
                  <div className="number_results_icon" style={{ flexGrow: 0 }}>
                    <h6
                      style={{
                        textAlign: "center",
                        color: "#ffffff",
                        fontWeight: "700",
                        fontSize: "35px",
                        color: "#1c1c2d",
                        width: "75px",
                        maxWidth: "150px",
                      }}
                    >
                      {(activePage - 1) * itemsPerPage + index + 1}&#183;
                    </h6>
                  </div>
                  <div className="vertical_border_tabs"></div>
                  <div
                    key={title_deed.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexGrow: "3",
                      justifyContent: "space-between",
                      fontWeight: "600",
                      margin: "25px 0"
                    }}
                    className="ms-4"
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        L.R NO:
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#f20000",
                          margin: "0",
                          fontSize: "21px",
                        }}
                      >
                        {title_deed.old_lr_no}
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#000000",
                          margin: "0",
                          fontSize: "21px",
                          fontWeight: "700",
                        }}
                      >
                        NEW GENERATION TITLE:
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#14a44d",
                          margin: "0 5px 0 0",
                          fontSize: "21px",
                        }}
                      >
                        {title_deed.new_title_no}
                      </span>
                      <div style={{ height: "15px" }}></div>
                      <span
                        style={{ color: "#000000", textTransform: "uppercase" }}
                      >
                        GAZETTE{" "}
                        {title_deed.gazette_volume !== null
                          ? title_deed.gazette_volume
                          : "not found"}
                        <br />
                        {title_deed.published_date !== null
                          ? `PUBLISHED ${title_deed.published_date}`
                          : ""}
                      </span>{" "}
                    </div>

                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          justifyContent: "space-between",
                          fontSize: "16px",
                        }}
                      >
                        <div style={{ textAlign: "end" }}>
                          <span>
                            EXPIRES AT{" "}
                            {title_deed.expiry
                              ? DateTime.fromISO(title_deed.expiry, {
                                  zone: "UTC",
                                }) // Assuming the database time is in UTC
                                  .setZone("Africa/Nairobi") // Set to East African Time Zone (EAT)
                                  .toLocaleString(DateTime.TIME_SIMPLE)
                              : "Date Not Available"}
                          </span>
                          <br />
                          <span style={{ textTransform: "uppercase" }}>
                            ON{" "}
                            {title_deed.expiry
                              ? DateTime.fromISO(title_deed.expiry, {
                                  zone: "UTC",
                                }) // Assuming the database time is in UTC
                                  .setZone("Africa/Nairobi") // Set to East African Time Zone (EAT)
                                  .toLocaleString(DateTime.DATE_MED)
                                  .replace(/\//g, "-") // Replace "/" with "-"
                              : "Date Not Available"}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <MDBBtn
                            href={title_deed.pdf_link}
                            target="_blank"
                            className="mx-2 view_pdf_button"
                            color="success"
                            style={{
                              fontSize: "16px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            GAZETTE
                          </MDBBtn>

                          {!expandedItems.includes(title_deed.old_lr_no) ? (
                            <img
                              src={OpenIcon}
                              alt="Loading Animated Icon"
                              className={`toggle-icon`}
                              style={{ width: "50px", height: "auto" }}
                              type="button"
                              onClick={() =>
                                toggleExpanded(title_deed.old_lr_no)
                              }
                            />
                          ) : (
                            <img
                              src={CloseIcon}
                              alt="Loading Animated Icon"
                              className={`toggle-icon expanded`}
                              style={{ width: "50px", height: "auto" }}
                              type="button"
                              onClick={() =>
                                toggleExpanded(title_deed.old_lr_no)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {expandedItems.includes(title_deed.old_lr_no) && (
                    <TabActiveExpanded
                      className={`ActiveExpanded ${isExpanded ? "show" : ""}`}
                      old_lr_no={title_deed.old_lr_no}
                      new_title_no={title_deed.new_title_no}
                      gazette_volume={title_deed.gazette_volume}
                      published_date={title_deed.published_date}
                      gazette_notice_no={title_deed.gazette_notice_no}
                      area={title_deed.area}
                      size={title_deed.size_ha}
                      gazette_page={title_deed.gazette_page}
                      pdf_page={title_deed.pdf_page}
                      pdf_link={title_deed.pdf_link}
                      page={activePage}
                      index={index}
                      itemsPerPage={itemsPerPage}
                      title_deed={title_deed}
                      expandedItems={expandedItems}
                    />
                  )}
                </div>
              </MDBTabsPane>
            </div>
          ))}
        </div>
        {activeSearchData.length > 3 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: "17px",
              backgroundColor: "#1c1c2d",
              padding: "15px 15px",
              alignContent: "center",
            }}
          >
            <Typography className="ms-2" style={{ alignSelf: "center" }}>
              <h5 style={{ color: "#ffffff", margin: "0" }}>PAGE: {activePage}</h5>
            </Typography>
            <Stack spacing={2}>
              <WhitePagination
                defaultPage={1}
                count={totalPages}
                page={activePage}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
                color="primary"
              />
            </Stack>
          </div>
        ) : null}
      </div>
    </ThemeProvider>
  );
}
