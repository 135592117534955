// State.js
import React, { useState, useEffect, createContext, useMemo } from "react";

import { DateTime } from "luxon";

import { useAuth0 } from "@auth0/auth0-react";

import { useAccessToken } from "./AccessToken";

import axios from "axios";

import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga";

export const StateContext = createContext();

export const StateProvider = ({
  children,
  accessTokenCookie,
  userDataCookie,
  refreshTokenCookie,
  email,
  authUserData,
  tempInputValue,
  setTempInputValue,
  advancedSearchData,
  setAdvancedSearchData,
  isAdvancedError,
  setIsAdvancedError,
  isAdvancedLoading,
  setIsAdvancedLoading,
  generatedStateValue,
  storedStateValue,
  storedCodeVerifier,
  storedCodeChallenge,
}) => {
  const navigate = useNavigate();
  const tokenExpiryCookie = parseInt(getCookie("token_expiry_seconds"), 10);

  // Redirects to Advanced Search Page
  const redirectToAdvancedSearch = () => {
    navigate("/advanced-search/active");

    // Tracks the Advanced Search page redirect clicks
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Advanced Search Redirect Button",
    });
    if (
      accessTokenCookie !== null &&
      userDataCookie !== null &&
      tempInputValue?.length > 0
    ) {
      navigate("/advanced-search/active");
      setTimeout(() => {
        performAdvancedSearch();
      }, 500);
    }
  };

  // Redirects to Advanced Search Page and opens Buy Credit Modal
  const redirectToCredits = () => {
    navigate("/advanced-search/active");
    setStaticModal(true);
  };

  const { logout } = useAuth0();
  const herokuURL = "https://title-checker-api.herokuapp.com";
  const localExpressURL = "http://localhost:8080";
  const serverUrl = `${herokuURL}`;
  const { fetchData } = useAccessToken();

  const netlifyProductionUrl = "https://titlechecker.aliumlaw.com/advanced-search/active"
  const netlifyTestURL = "https://aliumtest.netlify.app/advanced-search/active";
  const localReactURL = "http://localhost:3000/advanced-search/active";
  const redirectURI = `${netlifyProductionUrl}`;

  const clientId = `We18nhwwPjaqWBs8D757TLQncDOSkUKn`;
  const scope =
    "openid%20name%20email%20profile%20phone_number%20phone_number_verified%20updated_at%20offline_access";
  const apiAudience = `https://test-jwt.io`;

  // Construct the authorization URL with the required parameters
  const authURL =
    `https://nairobi-title-deed-conversion-checker.uk.auth0.com/authorize?` +
    `response_type=code` +
    `&code_challenge=${storedCodeChallenge}` +
    `&code_challenge_method=S256` +
    `&client_id=${clientId}` +
    `&redirect_uri=${redirectURI}` +
    `&scope=${scope}` +
    `&audience=${apiAudience}` +
    `&state=${storedStateValue}`;

  // Function to convert UTC time to Nairobi time (GMT+3)
  function convertToNairobiTime(utcTime) {
    const nairobiTime = new Date(utcTime);
    nairobiTime.setHours(nairobiTime.getHours());

    // Format the date and time with "AT" before the time
    const formattedDate = nairobiTime.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const formattedTime = nairobiTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `ON ${formattedDate} AT ${formattedTime}`;
  }

  const [simpleSearchData, setSimpleSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [isSimpleSearchSuccessful, setIsSimpleSearchSuccessful] =
    useState(false);
  const [isAdvancedSearchEnabled, setIsAdvancedSearchEnabled] = useState(false);

  // FETCH CREDIT DATA START
  const [receipts, setReceipts] = useState([]);

  const [availableCredits, setAvailableCredits] = useState(
    receipts && receipts.length > 0 ? receipts[0].available_credits : 0
  );

  const retrivedCredits =
    receipts === undefined ? 0 : parseFloat(receipts[0]?.available_credits);
  // FETCH CREDIT DATA END

  const [isExpiredLoading, setIsExpiredLoading] = useState(false);
  const [isReceiptsLoading, setIsReceiptsLoading] = useState(false);

  const [dashboardDataLoaded, setDashboardDataLoaded] = useState(null);

  const [showUpdate, setShowUpdate] = useState(false);

  const toggleAdvancedSearchUpdate = () => {
    // Continue with the regular logic to toggle the modal
    setShowUpdate(!showUpdate);
    localStorage.setItem("howToUseShown", "true");
  };

  // BUY CREDITS MODAL START
  const [staticModal, setStaticModal] = useState(false);

  const toggleShow = () => setStaticModal(!staticModal);
  useEffect(() => {
    // if (staticModal) {
    //   // Perform modal-related actions here
    //   console.log("Modal opened");
    // }
  }, [staticModal]);
  // BUY CREDITS MODAL END

  const advanced_search_cookie = getCookie("advanced_search_data");

  const [advancedSearchSubmitted, setAdvancedSearchSubmitted] = useState(false);

  // ADVANCED SEARCH PAGINATION START
  const [activePage, setActivePage] = useState(1);
  const [expiredPage, setExpiredPage] = useState(1);
  const [receiptsPage, setReceiptsPage] = useState(1);
  // const [page, setPage] = useState(1);
  // ADVANCED SEARCH PAGINATION END

  // ACTIVE SEARCH PAGINATION START
  const [activeSearchData, setActiveSearchData] = useState([]);
  // ACTIVE SEARCH PAGINATION END

  // EXPIRED SEARCH PAGINATION START
  const [expiredSearchData, setExpiredSearchData] = useState([]);
  // EXPIRED SEARCH PAGINATION END

  // NULLIFY SEARCH STATE START
  const [searchIsNullified, setSearchIsNullified] = useState(false);
  // NULLIFY SEARCH STATE END

  // Checks if data has been refreshed
  const [dataRefresh, setDataRefresh] = useState(false);
  const [dataIsRefreshed, setDataIsRefreshed] = useState(false);

  const [successNotification, setSuccessNotification] = useState(false);
  const [successResearch, setSuccessResearch] = useState(false);

  const [tokenExpiryAlert, setTokenExpiryAlert] = useState(false);

  // COOKIE SESSION UTILITY START
  function getCookie(cookieName) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]?.trim(); // Use optional chaining to handle null/undefined
      // Check if this cookie is the one we're looking for
      if (cookie && cookie.startsWith(cookieName + "=")) {
        // Extract and return the cookie value
        return decodeURIComponent(cookie.substring(cookieName.length + 1));
      }
    }
    // If the cookie is not found, return null or an appropriate default value
    return null;
  }

  // Clear All Cookies Set After User Authentication
  const clearCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };
  // COOKIE SESSION UTILITY END

  // USER LOGIN START
  const [ loggedIn, setLoggedIn ] = useState(false);

  const handleLogin = async () => {
    try {
      // Await App State Generation and Storage as Cookie
      if (
        storedStateValue != null &&
        storedCodeVerifier != null &&
        storedCodeChallenge != null
      ) {
        // Redirect the user to the authorization URL
        window.location.href = authURL;
        localStorage.setItem("tokenExpired", "false");
        await fetchData();
        setLoggedIn(true);
      }
    } catch (error) {
      console.error("Error while loggin in:", error);
    } finally {
      setTokenExpiryAlert(false);
      localStorage.removeItem("tokenExpired");
      localStorage.removeItem("dataRefresh");
    }
  };
  // USER LOGIN END

  // USER LOGOUT START
  const handleLogout = async () => {
    try {
      // console.log("Logging out in 3, 2, 1....");
      // Prepare the data you want to send in the request body
      const requestData = {
        email: email,
      };
      // console.log("POST DATA: ", requestData);

      // Call the server-side logout endpoint with a POST request and the data in the request body
      const logoutResponse = await fetch(
        `${serverUrl}/advanced/api/delete-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData), // Include data in the request body
        }
      );

      // console.log("POST Request: ", logoutResponse);

      if (logoutResponse.ok) {
        const logoutResponseData = await logoutResponse.json();
        // console.log("Server logoutResponse:", logoutResponseData);

        if (
          logoutResponseData &&
          logoutResponseData.message === "User deleted successfully"
        ) {
          // console.log("User logged out of Express Backend successfully");
          // Clear the App State from Local Storage
          localStorage.removeItem("stateValue");
          localStorage.removeItem("codeVerifier");
          localStorage.removeItem("codeChallenge");
          localStorage.removeItem("loglevel");
          localStorage.removeItem("tokenExpired");
          localStorage.removeItem("lr_no_input");

          // Clear user_data, app_state, and access_token cookies
          clearCookie("user_data");
          clearCookie("app_state");
          clearCookie("access_token");
          clearCookie("refresh_token");
          clearCookie("token_expiry_seconds");
          clearCookie("advanced_search_data");
          // Successful logout on the server, proceed with any client-side cleanup
          // console.log("Cookies cleared successfully");
          // console.log("User is logged out!!");

          // Set logged In Status to false
          setLoggedIn(false);
          // Perform logout from Auth0
          logout({ returnTo: window.location.origin });
          localStorage.removeItem("lastSelectedTab");
          // clearCookie("active_search_data");
        } else {
          console.error(
            "Error logging out:",
            logoutResponseData.error || "Unknown error"
          );
        }
      } else {
        console.error("Error logging out:", logoutResponse.statusText);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      // Set logged In Status to false
      setLoggedIn(false);
    }
  };

  const handleTokenExpiryLogout = async () => {
    try {
      // console.log("Token has expired. Logging out in 3, 2, 1....");
      // Prepare the data you want to send in the request body
      const requestData = {
        stateValue: storedStateValue,
      };
      // console.log("POST DATA: ", requestData);

      // Call the server-side logout endpoint with a POST request and the data in the request body
      const logoutResponse = await fetch(
        `${serverUrl}/advanced/api/delete-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData), // Include data in the request body
        }
      );

      // console.log("POST Request: ", logoutResponse);

      if (logoutResponse.ok) {
        const logoutResponseData = await logoutResponse.json();
        // console.log("Server logoutResponse:", logoutResponseData);

        if (
          logoutResponseData &&
          logoutResponseData.message === "User deleted successfully"
        ) {
          // console.log("User logged out of Express Backend successfully");
          // Clear the App State from Local Storage
          localStorage.removeItem("stateValue");
          localStorage.removeItem("codeVerifier");
          localStorage.removeItem("codeChallenge");
          localStorage.removeItem("loglevel");
          localStorage.removeItem("howToUseShown");
          localStorage.removeItem("lr_no_input");

          // Clear user_data, app_state, and access_token cookies
          clearCookie("user_data");
          clearCookie("app_state");
          clearCookie("access_token");
          clearCookie("refresh_token");
          clearCookie("token_expiry_seconds");
          clearCookie("advanced_search_data");
          // Successful logout on the server, proceed with any client-side cleanup
          // console.log("Cookies cleared successfully");
          // console.log("User is logged out!!");

          // Set logged In Status to false
          setLoggedIn(false);
          // Perform logout from Auth0
          logout({ returnTo: window.location.origin });
          localStorage.removeItem("lastSelectedTab");
          // clearCookie("active_search_data");
        } else {
          console.error(
            "Error logging out:",
            logoutResponseData.error || "Unknown error"
          );
        }
      } else {
        console.error("Error logging out:", logoutResponse.statusText);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      localStorage.setItem("tokenExpired", "true");
      // Set logged In Status to false
      setLoggedIn(false);
    }
  };

  const refreshData = async () => {
    try {
      if (email !== null) {
        // Reset backend data by making an API request
        const resetResponse = await fetch(
          `${serverUrl}/advanced/api/reset-data`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );

        if (resetResponse.status !== 200) {
          // Check if the reset request was not successful (status code other than 2xx)
          throw new Error(
            `Reset request failed with status ${resetResponse.status}`
          );
        }
      }

      // Clear localStorage items
      localStorage.removeItem("stateValue");
      localStorage.removeItem("codeVerifier");
      localStorage.removeItem("codeChallenge");
      localStorage.removeItem("loglevel");
      localStorage.removeItem("lastSelectedTab");
      localStorage.removeItem("lr_no_input");

      // Clear cookies
      clearCookie("user_data");
      clearCookie("app_state");
      clearCookie("access_token");
      clearCookie("refresh_token");
      clearCookie("token_expiry_seconds");
      clearCookie("advanced_search_data");

      // Reload the page
      window.location.reload(true); // Pass true to force a reload from the server
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      // Set a flag in localStorage to indicate data refresh
      localStorage.setItem("dataRefresh", "true");
    }
  };

  // USER LOGOUT END

  // HANDLE IDLE USER SESSION START
  // Set the session timeout period (in milliseconds)
  const sessionTimeout = 15 * 60 * 1000; // 15 minutes

  // Perform necessary cleanup when the session expires
  const handleSessionTimeout = () => {
    // Logout the user which will trigger a cleanup process
    // console.log("Session expired. Logging out...");
    handleLogout();
  };

  // Initialize the last activity timestamp
  let lastActivityTimestamp = new Date().getTime();

  // Update the last activity timestamp on user interaction
  const handleUserActivity = () => {
    lastActivityTimestamp = new Date().getTime();
  };

  // Check for session timeout at regular intervals
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeSinceLastActivity = currentTime - lastActivityTimestamp;

      if (timeSinceLastActivity >= sessionTimeout) {
        // Session has expired
        handleSessionTimeout();
      }
    }, 600000); // Check every 10 minutes

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [lastActivityTimestamp]);

  // Attach user activity listener to the document
  useEffect(() => {
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("touchstart", handleUserActivity);
    document.addEventListener("scroll", handleUserActivity);

    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.addEventListener("touchstart", handleUserActivity);
      document.addEventListener("scroll", handleUserActivity);
    };
  }, [accessTokenCookie, userDataCookie, storedStateValue, storedCodeVerifier, storedCodeChallenge]);
  // HANDLE IDLE USER SESSION END

  // Handle the input change for the temporary state
  const handleTempInputChange = (event) => {
    setTempInputValue(event.target.value);
  };

  // SIMPLE SEARCH SUBMIT START
  const handleSimpleSearchSubmit = async (event) => {
    event.preventDefault();

    if (event && tempInputValue?.length > 0) {
      try {
        setIsLoading(true);
        setIsError(false);

        const response = await fetch(
          `${serverUrl}/simple/api/title-deeds?old_lr_no=${tempInputValue.toUpperCase()}`,
          {
            cache: "no-store",
          }
        );

        const simpleSearchData = await response.json();
        setSimpleSearchData(simpleSearchData);

        setIsSimpleSearchSuccessful(true); // Set flag only on successful search
        setIsAdvancedSearchEnabled(true); // Enable advanced search
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };
  // SIMPLE SEARCH SUBMIT END

  // CREDITS SECTION START

  // Fetches a User's Receipts
  const fetchReceipts = async () => {
    setIsReceiptsLoading(true);
    fetch(`${serverUrl}/paystack/get-receipts/${receiptsPage}?email=${email}`)
      .then((response) => response.json())
      .then((data) => {
        setReceipts(data?.data);
        setIsReceiptsLoading(false);
        // console.log("RECEIVED RECEIPTS: ", data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  // NULLIFY ACTIVE SEARCH START
  const [timer, setTimer] = useState(0);

  async function handleExpiry(arrayOfObjects) {
    // console.log("HANDLING EXPIRY...");
    if (
      accessTokenCookie != null &&
      userDataCookie != null &&
      !searchIsNullified &&
      arrayOfObjects?.length > 0 // Check if there are objects to process
    ) {
      const currentDate = new Date().getTime();

      for (const obj of arrayOfObjects) {
        const expiryDate = new Date(obj.expiry).getTime();
        if (currentDate <= expiryDate) {
          // Calculate the delay (time remaining until expiry)
          const delay = expiryDate - currentDate;
          // console.log("DELAY: ", delay);

          // Use setTimeout to call nullifyValidSearch after the delay
          const timeoutId = setTimeout(() => {
            const postData = {
              useremail: obj.useremail,
              old_lr_no: obj.old_lr_no,
            };
            const dataCleanUp = async () => {
              await nullifyValidSearch(postData);
              await getSetData();
              await getExpiredData();
              setIsAdvancedLoading(false);
            };
            dataCleanUp();
          }, delay);

          // Store the timeoutId in state
          setTimer(timeoutId);
          // console.log("TIMEOUT ID: ", timeoutId);
        } else if (
          currentDate > expiryDate &&
          timer === 0 &&
          activeSearchData?.length > 0
        ) {
          setSearchIsNullified(true);
          setIsAdvancedLoading(false);
          const postData = {
            useremail: obj.useremail,
            old_lr_no: obj.old_lr_no,
          };
          const dataCleanUp = async () => {
            await nullifyValidSearch(postData);
            await getSetData();
            await getExpiredData();
            setIsAdvancedLoading(false);
          };
          dataCleanUp();
        }
      }
    }
  }

  async function nullifyValidSearch(postData) {
    try {
      const response = await fetch(
        `${serverUrl}/advanced/nullify-valid-search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setSearchIsNullified(true);
        setIsAdvancedLoading(false);
        // console.log(responseData);
      } else {
        setSearchIsNullified(false);
        setIsAdvancedLoading(false);
        console.error("Failed to send POST request. Status:", response.status);
      }
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  }
  // NULLIFY ACTIVE SEARCH END

  const getSetData = async () => {
    if (email !== null && email !== undefined) {
      // console.log("Inside getSetData. Email is not null or undefined.");
      const checkActiveSearchUrl = `${serverUrl}/advanced/get-valid-search/${activePage}?useremail=${email}`;
      try {
        setIsAdvancedLoading(true);
        const response = await fetch(checkActiveSearchUrl);

        // Check if the response status is 200
        if (response.status === 200) {
          const data = await response.json();

          // document.cookie = `active_search_data=${JSON.stringify(
          //   activeSearchData
          // )}; path=/; expires=${expirationDate.toUTCString()}; secure;`;

          // Update the state variable with the new data
          setActiveSearchData(data);
          setIsAdvancedLoading(false);

          // Retrieve the Expiration for each old_lr_no
          // console.log("ACTIVE SEARCH STATUS", data);
        } else {
          setSearchIsNullified(false);
          setIsAdvancedLoading(false);
          // console.log("Email is null or undefined. Skipping getSetData.");
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set advancedSearchSubmitted back to false
        setIsAdvancedLoading(false);
        setAdvancedSearchSubmitted(false);
        setSearchIsNullified(false);
      }
    }
  };

  const getExpiredData = async () => {
    // Replace with your Express API URL
    const expiredSearchUrl = `${serverUrl}/advanced/get-expired-search/${expiredPage}?useremail=${email}`;

    if (accessTokenCookie != null && userDataCookie != null) {
      setIsExpiredLoading(true);
      fetch(expiredSearchUrl)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Failed to fetch data");
          }
        })
        .then((data) => {
          setExpiredSearchData(data);
          setIsExpiredLoading(false);
          setSearchIsNullified(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsExpiredLoading(false);
        });
    } else {
      setSearchIsNullified(false);

      return;
    }
    setSearchIsNullified(false);
  };

  // console.log("Current Timer Value: ", timer);
  const intervalDuration = 2 * 60 * 1000;

  useEffect(() => {
    handleExpiry(activeSearchData);
    return () => {
      if (timer) {
        clearTimeout(timer);
        // console.log("TIMOUT CLEARED. TIME USED: ", timer);
      }
      // clearInterval(nullifyDataEvery2Mins);
    };
    // Check if there's data before setting the interval
    // if (activeSearchData.length > 0) {
    //   // const nullifyDataEvery2Mins = setInterval(() => {
    //   //   handleExpiry(activeSearchData);
    //   // }, intervalDuration);

    //   // Clear the interval when the component unmounts or when activeSearchData changes

    // }
  }, [activeSearchData]);
  // console.log("SEARCH IS NULLIFIED: ", searchIsNullified);

  const dataOperations = async () => {
    await getSetData();
    await getExpiredData();
    await fetchReceipts();
  };

  useEffect(() => {
    if (advancedSearchSubmitted) {
      dataOperations();
    }
  }, [advancedSearchSubmitted]);

  // COOKIE EXPIRY DATE
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 31); // Expires in 7 days

  // Declare filteredData as a state variable
  const [filteredData, setFilteredData] = useState([]);

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("storedData")) || [];
    setFilteredData(savedData);
  }, []);

  useEffect(() => {
    // Update availableCredits based on the fetched data
    if (receipts && receipts.length > 0) {
      setAvailableCredits(receipts[0].available_credits);
    }
  }, [receipts, availableCredits]);

  // console.log("AVAILABLE CREDITS ON ROW 1:", receipts[0].available_credits)

  // AdvancedSearch Navigation Start
  const [basicActive, setBasicActive] = useState("active");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      // console.log("CICKED: ", basicActive);
      return;
    }

    setBasicActive(value);
    navigate(`/advanced-search/${value}`);

    // Store the selected tab in localStorage
    localStorage.setItem("lastSelectedTab", value);
  };
  // AdvancedSearch Navigation End

  const [creditIsDeducted, setCreditIsDeducted] = useState(false);
  const [creditDeductAllow, setCreditDeductAllow] = useState(false);

  // Credit Deduction function Start
  const handleDeductCredit = async () => {
    try {
      await fetchReceipts();
      if (
        retrivedCredits > 0 &&
        tempInputValue !== activeSearchData[0]?.old_lr_no &&
        tempInputValue?.length > 0
      ) {
        // console.log("DEDUCTING CREDITS...");
        const response = await fetch(
          `${serverUrl}/paystack/deduct-credit?email=${email}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ available_credits: retrivedCredits }),
          }
        );

        if (response.status === 200) {
          // Successfully deducted credits
          setCreditIsDeducted(true);
          setCreditDeductAllow(false);
          // console.log("Credits deducted successfully.");
        } else {
          setCreditIsDeducted(false);
          setCreditDeductAllow(false);
          const data = await response.json();
          console.error(data.error);
        }
      } else {
        alert("You need Credits to use Advanced Search.");
      }
    } catch (error) {
      console.error("Error deducting credits:", error);
    } finally {
      setCreditDeductAllow(false);
    }
  };
  // Credit Deduction function End

  // Create Valid Search Start
  async function postDataToCreateValidSearch(searchData) {
    // console.log("Request Payload:", JSON.stringify(searchData));
    try {
      // await handleAdvancedSearchSubmit();
      // Prepare the data to send in the request
      // Check if advancedSearchData is an array and has at least one item

      // POST the search data to the "create-valid-search" endpoint
      const createSearchUrl = `${serverUrl}/advanced/create-valid-search`;
      const createSearchResponse = await fetch(createSearchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${accessTokenCookie}`,
        },
        body: JSON.stringify(searchData),
      });
      setAdvancedSearchSubmitted(true);

      if (createSearchResponse.status != 200) {
        setAdvancedSearchSubmitted(false);
        throw new Error(
          `Create Valid Search failed with status ${createSearchResponse.status}`
        );
      }

      // Handle the successful response here if needed
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed
    } finally {
      setCreditIsDeducted(false);
    }
    setAdvancedSearchSubmitted(false);
  }
  // Create Valid Search End

  // Perform Advanced Search Start
  const checkActiveData = (activeSearchData) => {
    for (let index = 0; index < activeSearchData.length; index++) {
      const activeSearch = activeSearchData[index]?.old_lr_no;
      if (tempInputValue.toLowerCase() === activeSearch.toLowerCase()) {
        return true;
      }
    }

    // If the loop completes without finding a match, return false
    return false;
  };
  const performAdvancedSearch = async () => {
    const activeSearchCheck = checkActiveData(activeSearchData);

    // Define the URL for the advanced search
    const advancedSearchUrl = `${serverUrl}/advanced/api/advanced-search?old_lr_no=${tempInputValue.toUpperCase()}`;
    try {
      handleBasicClick("active");
      const confirmAdvancedSearch = window.confirm(
        `You are about to search for LR NO. ${tempInputValue?.toUpperCase()} and 1 CREDIT will be DEDUCTED. Do you wish to continue with the search?`
      );
      if (
        activeSearchData.length > 0 &&
        activeSearchCheck &&
        confirmAdvancedSearch
      ) {
        alert(
          `The LR No. ${tempInputValue} you're trying to search for is still active.`
        );
      } else if (retrivedCredits == 0) {
        alert("To use the Advanced Search, you require CREDIT(S).");
      } else if (
        retrivedCredits > 0 &&
        tempInputValue !== activeSearchData[0]?.old_lr_no &&
        tempInputValue?.length > 0 &&
        confirmAdvancedSearch
      ) {
        setIsAdvancedLoading(true);
        setIsAdvancedError(false);

        // Fetch advanced search data
        const response = await fetch(advancedSearchUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessTokenCookie}`,
          },
          cache: "no-store",
        });

        // console.log("Response Status: \n", response.status);
        // if (accessTokenCookie !== null && response.status != 200) {
        //   await handleLogin();
        // }

        if (response.status != 200) {
          setIsAdvancedLoading(false);
          setCreditDeductAllow(false);
          throw new Error(
            `Advanced Search failed with status ${response.status}`
          );
        }

        const advancedSearchData = await response.json();

        // console.log("advancedSearchData: \n", advancedSearchData);

        if (advancedSearchData?.length === 0) {
          // No data found, notify the user
          alert("No data found for the given search criteria.");
          setIsAdvancedLoading(false);
          return;
        }

        if (response.status === 200) {
          try {
            setCreditDeductAllow(true);
            setIsAdvancedLoading(false);
            setIsAdvancedError(false);
            setSuccessNotification(true);

            await handleDeductCredit();
            // Parse the advanced_search_cookie JSON
            let parsedCookieData = [];
            try {
              parsedCookieData = JSON.parse(advanced_search_cookie || "[]");
              if (!Array.isArray(parsedCookieData)) {
                parsedCookieData = []; // Ensure parsedCookieData is an array
              }
            } catch (parseError) {
              console.error(
                "Error parsing advanced_search_cookie:",
                parseError
              );
            }

            // console.log("parsedCookieData:", parsedCookieData);

            // Combine the existing data with the new data
            const updatedData = [...parsedCookieData, ...advancedSearchData];
            // console.log("updatedData: \n", updatedData);

            // Filter out duplicates based on a unique property (e.g., old_title_no)
            const newFilteredData = updatedData.filter(
              (item, index, self) =>
                self.findIndex((i) => i.old_title_no === item.old_title_no) ===
                index
            );
            // console.log("newFilteredData:", newFilteredData);

            // Store the filtered data as a Cookie
            // document.cookie = `advanced_search_data=${JSON.stringify(
            //   newFilteredData
            // )}; path=/; expires=${expirationDate.toUTCString()}; secure;`;

            // Set the value of filteredData using the state setter function
            setFilteredData(newFilteredData);
            setAdvancedSearchData(newFilteredData);
            // console.log("ADVANCED SEARCH DATA: \n", advancedSearchData);
          } catch (error) {
            console.error("Error:", error);
            setIsAdvancedError(true);
          } finally {
            setIsAdvancedLoading(false);
            setTimeout(() => {
              setSuccessNotification(false);
            }, 6000);
          }
          const userData = JSON.parse(userDataCookie || "{}"); // Parse the JSON string or provide an empty object as a default
          const userName = userData.name || "";
          const userEmail = userData.email || "";

          // console.log("ALL ITEMS", advancedSearchData);
          const firstItem = advancedSearchData[0];
          // console.log("FIRST ITEM", firstItem);
          // console.log("FIRST ITEM PDF LINK", firstItem.pdf_link);

          const currentDate = DateTime.now().setZone("UTC"); // PRODUCTION USE
          // const currentDate = DateTime.now(); // LOCALHOST USE

          const activationDate = currentDate.toISO();
          // console.log("ACTIVATION DATE: ", activationDate);
          const expiryDate = currentDate.plus({ hours: 24 }).toISO();

          const searchData = {
            username: userName,
            useremail: userEmail,
            old_lr_no: firstItem.old_title_no,
            activation: activationDate,
            expiry: expiryDate,
            new_title_no: firstItem ? firstItem.new_title_no : "",
            area: firstItem ? firstItem.area : "",
            size_ha: firstItem ? parseFloat(firstItem.size_ha) : null, // Convert to double precision
            gazette_volume: firstItem ? firstItem.gazette_volume : "",
            gazette_notice_no: firstItem
              ? parseInt(firstItem.gazette_notice_no)
              : null, // Convert to INTEGER
            published_date: firstItem ? firstItem.published_date : "",
            gazette_page: firstItem ? parseInt(firstItem.gazette_page) : null, // Convert to INTEGER
            pdf_page: firstItem ? parseInt(firstItem.pdf_page) : null, // Convert to INTEGER
            pdf_link: firstItem ? firstItem.pdf_link : "",
          };

          await postDataToCreateValidSearch(searchData);
        } else if (response.status === 404) {
          alert("Bad Request: Please enter your Old Title Number");
        } else if (response.status === 400) {
          // Handle Bad Request status here
          alert("Bad Request: Please enter your Old Title Number");
        } else {
          // Handle other error cases
          throw new Error(
            `Advanced Search failed with status ${response.status}`
          );
        }
      }

      // Handle the response from create-valid-search as needed
    } catch (error) {
      console.error("Error:", error);
      setIsAdvancedError(true);
    } finally {
      setIsAdvancedLoading(false);
      setCreditDeductAllow(false);
    }
  };
  // Perform Advanced Search End

  // ADVANCED SEARCH SUBMIT ASYNC FUNCTION START
  async function handleAdvancedSearchSubmit(event) {
    // console.log("handleAdvancedSearchSubmit called");
    handleBasicClick("active");
    if (event != null) {
      event.preventDefault();
    }

    // Check if the user is authenticated
    if (accessTokenCookie === null && userDataCookie === null) {
      alert("You must Sign In to use Advanced Search");
      return; // Return early to exit the function
    }

    try {
      // Deduct credits only if event is not null and the input value is not empty
      if (event != null && tempInputValue?.length === 0) {
        alert("Please enter a value for Advanced Search");
        return; // Return early if no input value is provided
      }

      if (tempInputValue?.length > 0 && event != null) {
        await performAdvancedSearch();
      }
    } catch (error) {
      console.error("Error performing Advanced Search:", error);
    }
    // Deduct credits first

    // Proceed with advanced search only if credits were successfully deducted
  }
  // console.log("CREDIT IS DEDUCTED: ", creditIsDeducted);

  // ADVANCED SEARCH SUBMIT ASYNC FUNCTION END

  // async function refreshAccessToken(refreshTokenCookie) {
  //   const refreshToken = { refresh_token: refreshTokenCookie };

  //   try {
  //     console.log(
  //       "Refreshing expired Access Token with RT1: ",
  //       refreshTokenCookie
  //     );

  //     // Send a POST request to the 'advanced/api/refresh-token' endpoint with token_expiry_seconds in the body
  //     const response = await axios.post(
  //       `${serverUrl}/advanced/api/refresh-token`,
  //       refreshToken
  //     );

  //     // Handle the response as needed
  //     if (response.status === 200) {
  //       console.log("Access Token Successfully Refreshed");
  //       // clearCookie("access_token");
  //       // clearCookie("refresh_token");
  //       // clearCookie("token_expiry_seconds");
  //       // After refreshing, you can call fetchAccessTokenandSetCookie again to update the cookies.
  //       await fetchAccessTokenandSetCookie();
  //       // Schedule the next token refresh
  //       scheduleTokenRefresh();
  //     } else {
  //       console.error("Error refreshing access token:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error refreshing access token:", error);
  //   }
  // }

  const scheduleTokenRefresh = async () => {
    const timeRemaining = tokenExpiryCookie;
    const currentTime = Math.floor(Date.now());
    const expiryTime = tokenExpiryCookie + currentTime;

    // console.log("TIME REMAINING: ", timeRemaining);
    // console.log("CURRENT TIME : ", currentTime);
    // console.log("EXPIRY TIME : ", expiryTime);

    // Set a timer to refresh the token when it's about to expire
    if (timeRemaining > 0 && currentTime < expiryTime) {
      setTimeout(() => {
        handleTokenExpiryLogout();
      }, timeRemaining * 1000); // Convert seconds to milliseconds
    } else if (currentTime >= expiryTime) {
      handleTokenExpiryLogout();
    }
    // else {
    //   refreshAccessToken(refreshTokenCookie);
    // }
  };

  const checkTokenExpiry = localStorage.getItem("tokenExpired") === "true";
  useEffect(() => {
    if (checkTokenExpiry) {
      // Fire up an alert (you can replace this with your desired logic)
      setTimeout(() => {
        setTokenExpiryAlert(true);
      }, 3000);

      // Optionally, you can redirect to the login page here using React Router or any other method
      // history.push('/login'); // Replace with your actual route

      // Clear the tokenExpired flag from localStorage
      localStorage.removeItem("tokenExpired");
      setTimeout(() => {
        handleLogin();
      }, 3500);
    }
  }, [checkTokenExpiry]);

  useEffect(() => {
    const refreshPromise = async () => {
      if (
        accessTokenCookie !== null &&
        refreshTokenCookie !== null &&
        tokenExpiryCookie !== null
      ) {
        // Schedule the token refresh, including the first one when the component mounts
        await scheduleTokenRefresh();
      }
    };

    // Call the async function
    refreshPromise();
  }, [accessTokenCookie, refreshTokenCookie, tokenExpiryCookie]);

  // useEffect(() => {
  //   const initialAvailableCredits = parseFloat(receipts[0]?.available_credits);
  //   console.log("INITIAL CREDITS: ", initialAvailableCredits);

  //   if (!isNaN(initialAvailableCredits)) {
  //     setAvailableCredits(initialAvailableCredits + 10);
  //   } else {
  //     setAvailableCredits(0);
  //     console.log("Invalid or missing available credits data.");
  //   }
  // }, [receipts]);

  // console.log("UPDATED AVAILABLE CREDITS JUST B4 PURCHASE: ", availableCredits);

  // VERIFY PAYSTACK PAYMENTS START
  const paymentCallback =
    "https://aliumtest.netlify.app/advanced-search/receipts";
  const queryParams = new URLSearchParams(window.location.search);
  const reference = queryParams.get("reference");
  const [payIsSuccessful, setPayIsSuccessful] = useState(false);
  const [payIsVerified, setPayIsVerified] = useState(false);
  const [payementError, setPaymentError] = useState(false);
  const [payVerificationError, setPayVerificationError] = useState(false);

  // Function to handle payment verification
  const verifyPayment = async () => {
    if (accessTokenCookie != null && reference != null) {
      try {
        // Make a GET request to your Express backend with the payment reference
        const response = await fetch(
          `${serverUrl}/paystack/verify-payment/${reference}`,
          {
            method: "GET",
          }
        );

        if (response.ok) {
          // If the response is successful, handle the success case here
          const result = await response.json();
          // console.log("Payment verification successful:", result);
          // You can update your UI or take further actions as needed
          // await fetchReceipts();
        } else {
          // If the response is not successful, handle the error case here
          setPaymentError(true);
          console.error("Payment verification failed");
          // You can show an error message to the user or take other actions
        }
      } catch (error) {
        // Handle any network or other errors that might occur
        setPayVerificationError(true);
        console.error("Payment verification error:", error);
        // You can show an error message to the user or take other actions
      }
    }
  };

  const handlePayVerificationAndReceipts = async () => {
    await verifyPayment();
    setPayIsVerified(true);
    setTimeout(() => {
      setPayIsVerified(false);
    }, 6000);
    await fetchReceipts();
    handleBasicClick("receipts");
  };

  useEffect(() => {
    // console.log("FIRING UP PAYMENT VERIFICATION");
    // console.log("accessTokenCookie for pay verification:", accessTokenCookie);
    // console.log("reference:", reference);
    if (accessTokenCookie != null && reference) {
      handlePayVerificationAndReceipts();
    }
  }, [accessTokenCookie, reference]);
  useEffect(() => {
    if (accessTokenCookie != null && reference != null) {
      fetchReceipts();
    }
  }, [accessTokenCookie, reference]);

  // VERIFY PAYSTACK PAYMENTS END

  const toastKey = "howToUseShown"; // A unique key for localStorage

  // Memoize the toastShown value to avoid unnecessary re-computation
  const toastShown = useMemo(() => localStorage.getItem(toastKey), []);

  // Part that handles the update notification toast
  useEffect(() => {
    if (accessTokenCookie !== null && !toastShown && showUpdate === false) {
      // Check if "howToUseShown" is "true" in local storage
      if (localStorage.getItem("howToUseShown") === "true") {
        return;
      }

      // Opens the toast after 1 second upon the page load
      const timeout = setTimeout(() => {
        setShowUpdate(true); // Display the toast after 1 second
      }, 3000);

      return () => {
        clearTimeout(timeout); // Clear the timeout on component unmount
      };
    }
  }, [accessTokenCookie]);

  if (toastShown && !toggleAdvancedSearchUpdate) {
    return null;
  }

  // Function to check for dataRefresh flag in localStorage and trigger alert if present
  // Function to check for dataRefresh flag in localStorage
  const checkDataRefresh = () => {
    const dataRefreshFlag = localStorage.getItem("dataRefresh");
    if (accessTokenCookie === null && dataRefreshFlag === "true") {
      setDataRefresh(true);
      // Clear the dataRefresh flag
      localStorage.removeItem("dataRefresh");
    }
  };

  // Call checkDataRefresh on component mount
  useEffect(() => {
    checkDataRefresh();
    if (dataRefresh) {
      setTimeout(() => {
        handleLogin();
      }, 3500);
    }
  }, [accessTokenCookie, dataRefresh]);

  useEffect(() => {
    if (
      accessTokenCookie !== null &&
      userDataCookie !== null &&
      dataIsRefreshed
    ) {
      setTimeout(() => {
        setDataIsRefreshed(false);
      }, 6000);
    }
  }, [accessTokenCookie, userDataCookie, dataIsRefreshed]);

  return (
    <StateContext.Provider
      value={{
        navigate,
        redirectToAdvancedSearch,
        redirectToCredits,
        handleLogin,
        loggedIn,
        handleLogout,
        refreshData,
        dataRefresh,
        setDataRefresh,
        convertToNairobiTime,
        email,
        timer,
        getCookie,
        advancedSearchSubmitted,
        setAdvancedSearchSubmitted,
        userDataCookie,
        authUserData,
        filteredData, // User Input - LR No.
        simpleSearchData, // Data retrieved from Simple Search
        setSimpleSearchData, // Updates Simple Search Data
        isLoading, // Simple Search Data Loading Status
        setIsLoading, // Updates Simple Search Data Loading Status
        isError, // Simple Search Error Status
        setIsError, // Updates Simple Search Error Status
        isExpiredLoading,
        setIsExpiredLoading,
        isReceiptsLoading,
        setIsReceiptsLoading,
        handleSimpleSearchSubmit,
        isSimpleSearchSuccessful,
        successNotification,
        setSuccessNotification,
        successResearch,
        setSuccessResearch,
        basicActive,
        setBasicActive,
        handleBasicClick,
        authURL,
        activeSearchData,
        setActiveSearchData,
        advancedSearchData,
        isAdvancedLoading,
        setIsAdvancedLoading,
        handleAdvancedSearchSubmit,
        isAdvancedSearchEnabled,
        tempInputValue,
        handleTempInputChange,
        getExpiredData,
        expiredSearchData,
        setExpiredSearchData,
        searchIsNullified,
        setSearchIsNullified,
        staticModal,
        setStaticModal,
        toggleShow,
        receipts,
        setReceipts,
        fetchReceipts,
        nullifyValidSearch,
        getSetData,
        activePage,
        setActivePage,
        expiredPage,
        setExpiredPage,
        receiptsPage,
        setReceiptsPage,
        serverUrl,
        redirectURI,
        setTempInputValue,
        postDataToCreateValidSearch,
        setFilteredData,
        setAdvancedSearchData,
        accessTokenCookie,
        userDataCookie,
        setIsAdvancedError,
        verifyPayment,
        payIsSuccessful,
        setPayIsSuccessful,
        payIsVerified,
        setPayIsVerified,
        paymentCallback,
        payementError,
        setPaymentError,
        payVerificationError,
        setPayVerificationError,
        availableCredits,
        setAvailableCredits,
        creditIsDeducted,
        setCreditIsDeducted,
        setAdvancedSearchSubmitted,
        retrivedCredits,
        showUpdate,
        setShowUpdate,
        toggleAdvancedSearchUpdate,
        checkTokenExpiry,
        tokenExpiryAlert,
        setTokenExpiryAlert,
        dashboardDataLoaded,
        setDashboardDataLoaded,
        dataOperations,
        dataIsRefreshed,
        setDataIsRefreshed,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
