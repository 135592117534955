import React, { useContext } from "react";
import LoadIcon from "../../../Assets/Icons/cloud_loader_white.gif";
import NoActiveData from "../Alerts/NoActiveData";
import ErrorSearch from "../Alerts/ErrorSearch";

import TabActive from "./Tablet/TabActive";
import MobileActive from "./Mobile/MobileActive";

import AdvancedSearchPagination from "../Pagination/AdvancedSearchPagination";
import { StateContext } from "../../../../State";

export default function Active({
  tempInputValue,
  accessTokenCookie,
  userDataCookie,
  advancedSearchData,
  isAdvancedLoading,
  setIsAdvancedLoading,
  isAdvancedError,
}) {
  const { activeSearchData } = useContext(StateContext);

  // useEffect(() => {
  //   if (activeSearchData.length === 0) {
  //     setIsAdvancedLoading(true);
  //   }
  // }, [activeSearchData]);

  if (isAdvancedLoading && activeSearchData.length === 0) {
    // Simulate a delay of 7 seconds before showing the content
    // setTimeout(() => {
    //   setIsAdvancedLoading(false);
    // }, 5000);
    return (
      <>
        <div
          className="hide-tabs"
          style={{
            border: "1px solid #1c1c2d",
            borderRadius: "17px",
            boxShadow: "0 4px 10px -1px #1c1c2b",
            backgroundColor: "#1c1c2d",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "15px 25px",
              }}
            >
              <img
                src={LoadIcon}
                alt="Loading Animated Icon"
                style={{
                  width: "100px",
                  height: "100px",
                  textShadow: "0 0 5px #ffffff",
                }}
              />
            </div>
            <div className="vertical_border_loader"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                padding: "0 25px",
                fontSize: "23px",
              }}
            >
              <h5
                className="hide-mobile"
                style={{
                  color: "#ffffff",
                  fontWeight: "900",
                  letterSpacing: "0.01em",
                  wordSpacing: "0.27em",
                  margin: "0",
                }}
              >
                THERE ARE OVER 100,000 TITLE DEED CONVERSIONS DONE IN NAIROBI
              </h5>
              <h5
                className="hide-mobile"
                style={{
                  color: "#ffffff",
                  fontWeight: "900",
                  letterSpacing: "0.01em",
                  wordSpacing: "0.27em",
                  margin: "0",
                }}
              >
                GET STARTED TODAY
              </h5>
            </div>
          </div>
        </div>
        <div
          className="hide-desktop hide-mobile"
          style={{
            border: "1px solid #1c1c2d",
            borderRadius: "17px",
            boxShadow: "0 4px 10px -1px #1c1c2b",
            backgroundColor: "#1c1c2d",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "15px 25px",
              }}
            >
              <img
                src={LoadIcon}
                alt="Loading Animated Icon"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div className="vertical_border_loader"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                padding: "0 25px",
                fontSize: "23px",
              }}
            >
              <h5
                className="hide-mobile"
                style={{
                  color: "#ffffff",
                  fontWeight: "900",
                  letterSpacing: "0.01em",
                  wordSpacing: "0.27em",
                  margin: "0",
                }}
              >
                THERE ARE OVER 100,000 TITLE DEED CONVERSIONS DONE IN NAIROBI
              </h5>
              <h5
                className="hide-mobile"
                style={{
                  color: "#ffffff",
                  fontWeight: "900",
                  letterSpacing: "0.01em",
                  wordSpacing: "0.27em",
                  margin: "0",
                }}
              >
                GET STARTED TODAY
              </h5>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (isAdvancedError) {
    return (
      <>
        <ErrorSearch tempInputValue={tempInputValue} />
        {accessTokenCookie !== null &&
        userDataCookie !== null &&
        activeSearchData.length > 0 &&
        !isAdvancedLoading ? (
          <>
            <AdvancedSearchPagination />
            <TabActive />
            <MobileActive />
          </>
        ) : (
          <NoActiveData />
        )}
      </>
    );
  }

  return (
    <>
      {userDataCookie !== null &&
      userDataCookie !== null &&
      advancedSearchData !== null ? (
        activeSearchData.length > 0 ? (
          <>
            <AdvancedSearchPagination />
            <TabActive />
            <MobileActive />
          </>
        ) : (
          <NoActiveData />
        )
      ) : (
        <>
          <div className="mx-4">
            <h5>
              <strong>
                You must <a href="#signing-in...">Sign In</a> to use Advanced
                Search.
              </strong>
            </h5>
          </div>
        </>
      )}
    </>
  );
}
