import { createContext, useContext, useState, useEffect } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
// import Cookies from "js-cookie";

import axios from "axios";

const AccessTokenContext = createContext();

// Your AccessTokenProvider component
export const AccessTokenProvider = ({
  children,
  storedStateValue,
  globalAccessToken,
  setGlobalAccessToken,
  authUserData,
  setAuthUserData,
  parsedUserData,
  setParsedUserData,
  accessTokenCookie,
  userDataCookie,
  tempInputValue,
  setAdvancedSearchData,
  setIsAdvancedError,
  setIsAdvancedLoading,
  storedCodeVerifier,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  let authorizationCode = queryParams.get("code");
  let receivedStateValue = queryParams.get("state");

  const herokuURL = "https://title-checker-api.herokuapp.com";
  const localExpressURL = "http://localhost:8080";
  const serverUrl = `${herokuURL}`;

  // console.log("AUTH CODE SENT TO EXPRESS: \n", authorizationCode);
  // console.log("APP STATE SENT TO EXPRESS: \n", receivedStateValue);
  // console.log("CODE VERIFIER SENT TO EXPRESS: \n", storedCodeVerifier);

  // console.log(
  //   "=== GLOBAL ACCESS TOKEN SENT TO EXPRESS ===: \n",
  //   globalAccessToken
  // );

  // COOKIE EXPIRY DATE
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 31); // Expires in 7 days

  const exchangeToken = async () => {
    if (accessTokenCookie === null) {
      try {
        const response = await axios.post(
          `${serverUrl}/advanced/api/token-exchange`,
          {
            storedCodeVerifier,
            authorizationCode,
            receivedStateValue,
          }
        );
      } catch (error) {
        console.error("Error sending authorization code to Express:", error);
      }
    }
  };

  const fetchAccessTokenandSetCookie = async () => {
    try {
      // Fetch the access token from your Express backend
      const response = await axios.get(
        `${serverUrl}/advanced/api/set-access-token`
      );
      const { access_token, refresh_token, token_expiry_seconds, app_state } =
        response.data;

      if (access_token && refresh_token && token_expiry_seconds && app_state) {
        // Set Retrieved Access Token to Global Variable
        setGlobalAccessToken(access_token);

        // console.log("STORING VALID ACCESS TOKEN: ", access_token);
        // console.log("STORING REFRESH TOKEN: ", refresh_token);
        // console.log(
        //   "STORING  ACCESS TOKEN EXPIRY TIME IN SECONDS: ",
        //   token_expiry_seconds
        // );
        // console.log("STORING APP STATE: ", app_state);

        // Create secure and HttpOnly cookies
        document.cookie = `access_token=${access_token}; path=/; secure;`;
        document.cookie = `refresh_token=${refresh_token}; path=/; secure;`;
        document.cookie = `token_expiry_seconds=${token_expiry_seconds}; path=/; secure;`;
        document.cookie = `app_state=${app_state}; path=/; secure;`;
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const refetchAccessTokenandSetCookies = async () => {
    try {
      // Fetch the access token from your Express backend
      const response = await axios.get(
        `${serverUrl}/advanced/api/set-access-token`
      );
      const { access_token, refresh_token, token_expiry_seconds, app_state } =
        response.data;

      if (access_token && refresh_token && token_expiry_seconds && app_state) {
        // Set Retrieved Access Token to Global Variable
        setGlobalAccessToken(access_token);

        // console.log("STORING VALID ACCESS TOKEN: ", access_token);
        // console.log("STORING REFRESH TOKEN: ", refresh_token);
        // console.log(
        //   "STORING  ACCESS TOKEN EXPIRY TIME IN SECONDS: ",
        //   token_expiry_seconds
        // );
        // console.log("STORING APP STATE: ", app_state);

        // Create secure and HttpOnly cookies
        document.cookie = `access_token=${access_token}; path=/; expires=${expirationDate.toUTCString()}; secure;`;
        document.cookie = `refresh_token=${refresh_token}; path=/; expires=${expirationDate.toUTCString()}; secure;`;
        document.cookie = `token_expiry_seconds=${token_expiry_seconds}; path=/; expires=${expirationDate.toUTCString()}; secure;`;
        document.cookie = `app_state=${app_state}; path=/; expires=${expirationDate.toUTCString()}; secure;`;
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const fetchUserDataAndSetCookie = async () => {
    if (userDataCookie === null) {
      try {
        // Fetch user data from your Express backend
        const response = await axios.get(
          `${serverUrl}/advanced/api/online-user?state_value=${storedStateValue}`
        );

        if (response.status === 200) {
          // User data was successfully fetched
          const userData = response.data;
          // Store the user data as a cookie (you can adjust the cookie options as needed)
          document.cookie = `user_data=${JSON.stringify(
            userData
          )}; path=/; expires=${expirationDate.toUTCString()}; secure;`;

          setAuthUserData(userData); // Set User data as a global variable
          setParsedUserData(userData);
          // console.log(
          //   "USER DATA AFTER SUCCESSFUL AUTHENTICATION: ",
          //   JSON.parse(userData)
          // );
        } else {
          console.error("Error fetching user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const fetchData = async () => {
    await exchangeToken();
    await fetchAccessTokenandSetCookie();
    await fetchUserDataAndSetCookie();
  };

  useEffect(() => {
    if (authorizationCode && receivedStateValue) {
      fetchData();
    }
  }, [
    accessTokenCookie,
    userDataCookie,
    authorizationCode,
    receivedStateValue,
  ]);

  useEffect(() => {
    const redirectAfterLogin = async () => {
      const localHostRedirectURL =
        "http://localhost:3000/advanced-search/active";
      const netlifyRedirectURL =
        "https://aliumtest.netlify.app/advanced-search/active";
        const netlifyProductionUrl = "https://titlechecker.aliumlaw.com/advanced-search/active"
      try {
        await fetchData();
        window.location.href = `${netlifyProductionUrl}`;
        return;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (
      authorizationCode &&
      receivedStateValue &&
      accessTokenCookie != null &&
      userDataCookie != null
    ) {
      redirectAfterLogin();
    }
  }, [
    authorizationCode,
    receivedStateValue,
    accessTokenCookie,
    userDataCookie,
  ]);

  return (
    <AccessTokenContext.Provider
      value={{
        authorizationCode,
        receivedStateValue,
        fetchData,
        fetchAccessTokenandSetCookie,
        refetchAccessTokenandSetCookies,
      }}
    >
      {children}
    </AccessTokenContext.Provider>
  );
};

export const useAccessToken = () => {
  return useContext(AccessTokenContext);
};
