import React from "react";
import { useLocation } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
    const location = useLocation();
    const domain = 'nairobi-title-deed-conversion-checker.uk.auth0.com';
    const clientId = 'We18nhwwPjaqWBs8D757TLQncDOSkUKn';

    const onRedirectCallback = (appState) => {
        window.location.href = appState?.returnTo || location.pathname;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
}

export default Auth0ProviderWithHistory;
